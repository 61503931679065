<template>
    <heder/>
      
    <div class="body">
      
        <div class="heder"> 
            <div class="up">
                <a href="#alias">
                <p><img src="./ikons/strelkanazad1.png">  Назад</p>
                </a>
                <div class="close">
                    <save/>
                 </div>

            </div>
           <div class="bottom">
                <div class="blockp1">
                    <div class="p1">
                        <strong>
                            Без названия
                        </strong>
                        <button
                        @click="pr_rename"
                         class="btn rename">
                            <img class="imgren" src="./components/ik/rename.png">
                        </button>
                    </div>
                </div> 
                <!-- <div class="blockp2">          
                    <div class="p2">
                        Результат генерации
                    </div>
                    <div class="p2">
                        Основные данные
                    </div>
                    <div class="p2">
                        Визуальные предпочтения
                    </div>
                    <div class="p2">
                        Облако тегов
                    </div>
                </div>   -->
               

            </div> 

        </div>
        <div class="bblock">

        <div class="left">
            <div class="lcont">
            <div class="imgblock">
                                    <img class="img4" src="./photos/1633908674_126-p-zagorodnie-doma-i-kottedzhi-foto-132.jpg">
                                    <button class="btn downloat">
                       <img class="dwnltbtn" src="./ikons/output-onlinepngtools.png">
                        Скачать или поделиться
                    </button><br>
                    <button class="btn togalery">
                         Опубликовать в галерею   
                    </button>
                    
            </div>
            <!-- <div class="imglist">
                <div class="img">
                    qaeg
                </div>

                <div class="img">
                    qaeg
                </div>

                <div class="img">
                    qaeg
                </div>
                <div class="img">
                    qaeg
                </div>
            </div> -->
            </div><br>
            <!-- <div class="footer">
                <div class="lfoot">
                    <p>
                        Попробуйте необыковенную функцию!
                    </p>
                </div>
                <div class="rfoot">
                    <p>
                         3D - осмотр
                    </p>
                   
                </div>
            </div> -->
        </div>
        <div class="right">

            <div class="rhead">

                <button class="btn dwnld">
                    Скачать в JPEG
                </button>

                <button class="btn rpst">
                    Поделиться
                </button>

            </div><br>

            <div class="rbody">
                <div class="rbcont">
                    <div class="str1">
                        <div class="img">
                            img1
                        </div>

                        <div class="img">
                            img2
                        </div> 
                    </div>
                    <div class="str2">
                        <div class="img">
                            img3
                        </div>
                        <div class="img">
                            img4
                        </div>
                     </div>
                </div>
                                
                
                
                <!-- <div class="bhead">
                    <p>
                        Доступно на аккаунте PRO
                    </p>
                </div>
                <div class="bcont">
                    <label>
                        Приобретите проффесиональный аккаунт и получите доступ к подробной документации проекта. 
                    </label>
                    <button class="pro">
                        PRO Открыть документацию
                    </button>
                </div> -->
            </div>

            <div class="bfooter">
                <div class="fk1">
                    <!-- <a  class=" save1" href="#plantpod"> -->

                  
                    <button class="btn save1">
                        Подобрать растения
                    </button>
                      <!-- </a> -->
                    <div class="quesr">
                        <p>?</p>
                    </div>
                </div>
                <br>
                 <div class="fk1">
                    <button class="btn save">
                        Сохранить как авторский шаблон
                    </button>
                    <div class="quesr">
                      <p>?</p>
                    </div>
                    </div>
            </div>

        </div>
        </div>
    </div>
</template>

<script>
 import api from  "../../JS/apiint.js"  
 import heder from "./components/hederTree.vue";
// import hederd from "./components/hederDuo.vue";
import save from "./components/SaveModal.vue"

export default{
    components:{
        // hederd,
        heder,
        save
    },
    name: "proJect",
        data(){
       return{
           
            project1: null,
           
        }
    },

        mounted(){
           
            console.log(this,'1') 
  console.log(this)
    this.projects(this)  //использовать для прорисовки по умолчанию
},
        methods:{
        // api.request(options2).then(function (response)

            projects(this2){
                let result = this.$route.query.project
                console.log('Hello world','3');
                const options = {
                    method: 'GET',
                    url: 'http://185.221.153.253:5700/api/project/' + result,
                                   
                    };
                  
                    api.request(options).then(function (response) {
                    localStorage.setItem('id', response.data.id)      
                            
                    this2.project1 = response.data
                    console.log(this2.project1);
                    let qwerty = response.data
                    console.log("========");
                    console.log(qwerty);
                    }).catch(function (error) {
                    console.error(error);
                 
                    })
                
            },

            pr_rename(){
                let result = this.$route.query.project
                console.log('Hello world','3');
                const options2 = {
                method: 'POST',
                url: 'http://185.221.153.253:5700/api/project/' + result,
                headers: {
                    'Content-Type': 'application/json',
                },
                data: {
                    pr_descr: 'новый проект', 
                    }
                };

                api.request(options2).then(function (response) {
                console.log(response.data);
                }).catch(function (error) {
                console.error(error);
                });
            }
            
        }

}
</script>

<style scoped>


.close{
    width: 90%;
    text-align: right;
}
.imgblock button{
    position: relative;
    margin-left: 60%;
     margin-top: 1%;
    background: #D9D9D9;
}
.dwnltbtn{
    width: 30px;
}
.downloat{
    min-width:350px ;
    min-height: 40px;    
    border-radius: 10px;
  
}

.downloat:hover{
    background: #D9D9D9;
}
.togalery{
    min-width:350px ;
    min-height: 40px; 
    border-radius: 10px;
}
.rhead{
    width: 100%;
    margin-top: 0%;
}
.dwnld{
    width: 47%;
    background-color: #D9D9D9;
    height: 56px;
    border-radius: 5px;
    float: left;
    margin-left: 0%;
}
@media screen and (max-width: 1880px) {
 

}


@media screen and (max-width: 1480px) {
    .imgblock button{
        margin-left: 50%;
    }

    



}
@media screen and (max-width: 1280px) {
    .imgblock button{
        margin-left: 35%;
    }
   
   


}
@media screen and (max-width: 1100px) {
    .imgblock{
    width: 100%;
    height: 100%;
}
    .imgblock button{
        margin-left: 35%;
    }
   

.imgblock button{
        margin-left: 25%;
    }

}
.rpst{
    width: 47%;
    background-color: #D9D9D9;
    height: 56px;
    border-radius: 5px;
    float: left;
    margin-left: 6%;
}

.rbody{
    margin-top: 10%;
    height: 385px;
    background: #D9D9D9;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}


.rbcont{
    /* display: flex; */
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.str1{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    
}
.str2{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.img{
    background-color: rgb(110, 110, 110);
    width: 49.5%;
    height: 150px;
    margin-top: 5%;
    margin-left: 2%;
    margin-right: 2%;
}
.rbox{

}
.bhead{
    width: 100%;
    margin-left: 2%;

}

.bhead p{
    width: 60%;
    font-size: 30px;
}

.bcont{
    margin-top: 15%;
   
}

.bcont label{
    text-align: left;
    font-size: 16px;
    width: 80%;
     margin-left: 2%;

}
.pro{
    width: 95%;
    margin-top: 4%;
    margin-left: 2%;
    height: 54px;
    justify-content: center;
    background-color: #949494;
}

.bfooter{
    margin-top: 2%;
    /* margin-left:35%; */
   
}
.fk1{
    display: flex;
}
.save{
    margin-left: 20%;
    width: 70%;
    height: 54px;
    background-color: #D9D9D9;
    border-radius: 10px;
}
.save1{
    margin-left: 20%;
    width: 70%;
    height: 54px;
    background-color: #f54b4b;
    border-radius: 10px;
}
.quesr{
    margin-left: 10%;
  text-align: center;
    margin-top: 1%;
    background-color: #949494;
    width: 35px;
    height: 35px;
    border-radius: 30px;
}
.quesr p{
    margin-top: 20%;
}
.imgblock{
    margin-top: 1%;
    margin-left: -4%;
    width: 100%;
    height: 570px;
    /* background-color: rgb(163, 163, 163); */
    /* float: left; */
}
.img4{
    width: 60%;
    position: absolute;
}
.lcont{
    margin-top: 2%;
    display: flex;
    /* background-color: #949494; */
}


.bblock{
    margin-top: 4%;
    display: flex;
    justify-content: center;
}
.left{
    width: 60%;
    /* float: left; */
    /* display: flex; */
}
.right{
    width: 30%;

   
    /* float: left; */
    /* margin-left: 2%; */
    margin-top: 2%;
}
.heder{
    margin-left: 2%;
}
.p1{
    float: left;
    margin-left: 0%;
    font-size: 24px;
    text-align: center;
    width: 261px;
    height: 29px;
    font-weight: 400;
    line-height: 29px;
}
.blockp2{
    margin-left: 22%;
}
.p2{
    float: left;
    margin-left: 1%;
    font-size: 16px;
    margin-top: 0.5%;
    text-align: center;
    width: 240px;
    height: 19px;
}
.up a{
    text-decoration: none;
    color: black;
}
.body{
    margin-top: 0px;
    font-family: "Montserrat-Regular";
}

</style>
