<template>
   <div class="link"><a href="#neuroIN">back</a></div> 
    <!-- <div class="panel-button">

        <button @click="neurinetUpdate">Изменить </button>
        <button @click="neurinetDElete">Удалить</button>
       

    </div> -->


    <div class="panel">

        <input
            width="200px"           
            type="text"
            class="form-control"
            placeholder="activation"
            v-model="activation"
          >
          <input
          width="200px"
            type="text"
            class="form-control"
            placeholder="input_num"
            v-model="input_num"
          >
          <input
          width="200px"
            type="text"
            class="form-control"
            placeholder="input_x"
            v-model="input_x"
          >
          <input
          width="200px"
            type="text"
            class="form-control"
            placeholder="input_y"
            v-model="input_y"
          >
          <input
          width="200px"
            type="text"
            class="form-control"
            placeholder="layer_type"
            v-model="layer_type"
          >
          <input
          width="200px"
            type="text"
            class="form-control"
            placeholder="n_count"
            v-model="n_count"
          >
        
        
          <input
          width="200px"
            type="text"
            class="form-control"
            placeholder="order"
            v-model="order"
          >
          
          <input
          width="200px"
            type="text"
            class="form-control"
            placeholder="x"
            v-model="XMLSerializer"
          >
          
          <input
          width="200px"
            type="text"
            class="form-control"
            placeholder="y"
            v-model="y"
          >
          

    </div> 
    <div class="panel-button">

<button @click="neurinetUpdate">Изменить </button>
<button @click="neurinetDElete">Удалить</button>


</div>
        <div class="tableborder">
    <table class="table">
              <thead>
                <tr>
                    <th></th>
                    
                    <th scope="col">activation</th>
                    <th scope="col">input_num</th>
                    <th scope="col">input_x</th>
                    <th scope="col">input_y</th>
                    <th scope="col">layer_type</th>
                    <th scope="col">n_count</th>
                    <th scope="col">order</th>
                    <th scope="col">x</th>
                    <th scope="col">y</th>
                </tr>
              </thead>
              <tbody>
                <tr
               
                 
                >
                  <input
                  class="form-check-input" 
                  type="radio" 
                  name="flexRadioDefault" 
                  id="flexRadioDefault1"
                  
                  />
                   
                    <td scope="row"  >{{(neuronet)? neuronet.activation : ''}}</td>
                    <td>{{(neuronet)? neuronet.input_num : ''}}</td>
                    <td>{{(neuronet)? neuronet.input_x : ''}}</td>
                    <td>{{(neuronet)? neuronet.input_y : ''}}</td>
                    <td>{{(neuronet)? neuronet.layer_type : ''}}</td>
                    <td>{{(neuronet)? neuronet.n_count : ''}}</td>
                    <td>{{(neuronet)? neuronet.order : ''}}</td>
                    <td>{{(neuronet)? neuronet.x : ''}}</td>
                    <td>{{(neuronet)? neuronet.y : ''}}</td>
                </tr>
              </tbody>
           </table>
           </div>
</template>


<script>
import api from "../../JS/api.js"
export default{
    name: "neuronetIN",

  

data(){
   return{
       
        neuronet: null,
        
       
    }
},

mounted(){
  console.log(this)
    this.neuronetIN(this)  //использовать для прорисовки по умолчанию
},

methods: {
  
    
     neuronetIN(this2){
        let result = this.$route.query.layer
            console.log('Hello world');

      const options2 = {
        method: 'GET',
        url: 'http://185.221.152.242:5500/api/neurolayer/' + result,
        
      };
      
      api.request(options2).then(function (response) {
       
     
        localStorage.setItem('id', response.data.id)
       console.log(this2)
        console.log(response.data);
          this2.neuronet = response.data;
         
          
      }) 
              
      .catch(function (error) {
        console.error(error);
        console.log(options2)
      });
      
       },
       neurinetUpdate(){
           
             
           console.log('Hello world');
           let result = this.$route.query.layer
                console.log('Hello world','3');
          
           const options4 = {
           method: 'POST',
           url: 'http://185.221.152.242:5500/api/neurolayer/'+ result,
           headers: {'Content-Type': 'application/json'},
         data: {
          

          activation: this.activation,

           
          input_num: this.input_num,

           input_x: this.input_x,

           input_y: this.input_y,

           layer_type: this.layer_type,

           n_count: this.n_count,

           order: this.order,

           x: this.x,

           y: this.y
          

           }
           
         };
          api.request(options4).then(function (response) {
           console.log(response);
            console.log(options4);
            window.location.reload();
        
             
             
             
          }) 
         
  
         },   
         neurinetDElete(){
           
             
           console.log('Hello world');
           let result = this.$route.query.layer
                console.log('Hello world','3');
          
           const options6 = {
           method: 'DELETE',
           url: 'http://185.221.152.242:5500/api/neurolayer/'+ result,
           headers: {'Content-Type': 'application/json'},
        
         };
          api.request(options6).then(function (response) {
           console.log(response);
            console.log(options6);
            window.location.reload();
        
             
             
             
          }) 
         
  
         },   

}
}
</script>


<style scoped>

.tableborder{
    margin-top: 3%;
    margin-left: 3%;
}
.panel-button{
    margin-top: 3%;
    margin-left: 3%;
}
.panel{
    margin-top: 3%;
    margin-left: 5%;
    display: flex;
}

.link{
      margin-top: 1%;
      margin-left: 3%;
      
    }
</style>