<template>

    <div class="box-content">
      
      <div class="link">
        <a href="#picfolder">Back</a>
      </div>

        <div class="panel">
            <div>
            <button @click="picUpdate">Изменить</button>
            <button @click="picDElete">Удалить</button>
            <button @click="picPost">Создать</button>
            <p>
              Загрузить архив изображений
            </p>
            ------------
            <input
            name="archive"
            type="file" 
            accept="all/*" 
            @change="picsload" 
            id="file-input"
            v-on="file"
            multiple />
        </div>
            <!-- <button @click="picAsd">Get</button> -->
            </div>
            <div class="input">
                <!-- <input
          width="200px"
            type="text"
            class="form-control"
            placeholder="filename"
            v-model="filename"
          >
          <input
          width="200px"
            type="text"
            class="form-control"
            placeholder="picname"
            v-model="picname"
          >
            
            </div> -->
            <div class="input">

                <!-- <input
                 name="image" 
                 type="file" 
                 accept="image/*" 
                 @change="uploadImage($event)" 
                 id="file-input"> -->
                
            </div>
        </div>

        <table class="table">
              <thead>
                <tr>
                    <th>  
                      filename          
                  </th>
                  <!-- <th>  
                      id          
                  </th>
                  <th>  
                    pf_id          
                  </th> -->
                  <th>  
                     picname          
                  </th>
                    
                </tr>
              </thead>
              <tbody>
                <tr
                 
                >
                 
                    <!-- <th scope="row" >{{pic.descr}}</th>
                    <td>{{pic.id}}</td>
                    <td>{{pic.pf_id}}</td>
                    <td>{{pic.name}}</td> -->
                    <td scope="row"  >{{(pic)? pic.descr : ''}}</td>
                    <td>{{(neuronet)? neuronet.id : ''}}</td>
                    <td>{{(neuronet)? neuronet.m_loss : ''}}</td>
                    <td>{{(neuronet)? neuronet.m_metrics : ''}}</td>
                </tr>
              </tbody>
           </table>

        <div class="content">
          <div class="input">
                <input
                name="filename"
          width="200px"
            type="text"
            class="form-control"
            placeholder="filename"
            v-model="filename"
          >
          <input
          name="picname"
          width="200px"
            type="text"
            class="form-control"
            placeholder="picname"
            v-model="picname"
          >
            
            </div>
          
           


            <table class="table">
              <thead>
                <tr>
                    <th>  
                      filename          
                  </th>
                  <th>  
                      id          
                  </th>
                  <th>  
                    pf_id          
                  </th>
                  <th>  
                     picname          
                  </th>
                    
                </tr>
              </thead>
              <tbody>
                <tr
                 v-for:="options2 in neuronet" 
                >
                 
                    <th scope="row" @click="neuroclick(options2.id)" >{{options2.filename}}</th>
                    <td>{{options2.id}}</td>
                    <td>{{options2.pf_id}}</td>
                    <td>{{options2.picname}}</td>
                  
                </tr>
              </tbody>
           </table>

            <!-- <div v-for:="picsee in  image">{{picsee}}</div>
            <table class="table">

              <tbody>
              <tr
              v-for:=" image in imagereturn" 
              >
                              
                  <th scope="row" >{{image.image}}</th>
                  
                
              </tr>
              </tbody>

              </table> -->
           
   
            
        </div>
        </div>
    <!-- </div> -->

</template>

<script>

import api from "../../JS/api.js"
    export default{
    data() {
        return {
            picview: null,
            picsee: null,
            image:null,
            picaso:null,
            neuronet: null,
            options1: null

        };
    },
    mounted(){
      this.picGet(this)
     // this.picAsd(this)
      this.neuronetIN(this)
    },
    methods: {
        picGet() {
            let result = this.$route.query.picfolder
            const options1 = {
                method: "GET",
                url: "http://185.221.152.242:5500/api/picfolders/"+ result,
            };
            api.request(options1).then(function (response) {
                console.log(response.data);
              
                let pic = response.data;
                console.log(pic);
            }).catch(function (error) {
                console.error(error);
            });
        },

        picPost() {
            let result = this.$route.query.picfolder
            const options12 = {
              method: 'POST',
              url: 'http://185.221.152.242:5500/api/pics/' + result,
              data: {filename: this.filename, picname: this.picname}
            };

            api.request(options12).then(function (response) {
              console.log(response.data);
              
              
              window.location.reload();
            }).catch(function (error) {
              console.error(error);
            })
        },
        picsload(){

          let result = this.$route.query.picfolder
          // let id = this.id
          const URL = 'http://185.221.152.242:5500/api/picarcload/' + result;
          let data = new FormData();

          data.append('archive', event.target.files[0]); 

          let config = {
          header : {
              'Content-Type' : 'multipart/form-data'
          }
          }
          //  console.log(event.target.files, 'qwerty')
          api.post(
          URL, 
          data,
          config
          ).then(
          response => {
              console.log('archive upload response > ', response)
            
          }
          )


          },
        // picAsd() {
        //     let result = this.$route.query.picfolder
        //     const picaso = {
        //       method: 'GET',
        //       url: 'http://185.221.152.242:5500/api/pics/' + result,
              
        //     };

        //     api.request(picaso).then(function (response) {
        //       console.log(response.data);
        //       let img = response.data
        //       console.log(img, 'img')
        //     }).catch(function (error) {
        //       console.error(error);
        //     })
        // },



        // uploadImage(event) { //загрузка картинки


        //   let result = this.$route.query.picfolder

        //     const URL = 'http://185.221.152.242:5500/api/picload/'+ result; 

        //   let data = new FormData();
        //   // data.append('image', 'my-picture');
        //   data.append('image', event.target.files[0]); 

        //   let config = {
        //     header : {
        //       'Content-Type' : 'multipart/form-data'
        //     }
        //   }
        //   console.log(event.target.files, 'qwerty')
        //   api.post(
        //     URL, 
        //     data,
        //     config
        //   ).then(
        //     response => {
        //       console.log('image upload response > ', response)
        //     }
        //   )
        //   },

        // picUpdate() {
        //     console.log("Hello world");
        //     let result = this.$route.query.picfolder;
        //     console.log("Hello world", "3");
        //     const options4 = {
        //         method: "POST",
        //         url: "http://185.221.152.242:5500/api/pics/" + result,
        //         data: {
        //             filename: this.filename,
        //             picname: this.picname,
        //         }
        //     };
        //     api.request(options4).then(function (response) {
        //         console.log(response);
        //         console.log(options4);
        //         window.location.reload();
        //     });
        // },
        picDElete() {
            console.log("Hello world");
            let result = this.$route.query.picfolder;
            console.log("Hello world", "3");
            const options6 = {
                method: "DELETE",
                url: "http://185.221.152.242:5500/api/pic/" + result,
            };
            api.request(options6).then(function (response) {
                console.log(response);
                console.log(options6);
                window.location.push("picFolder" + result);
            });
        },
        
        neuronetIN(this2){
          let result = this.$route.query.picfolder
          console.log('Hello world');

          const options2 = {
          method: 'GET',
          url: 'http://185.221.152.242:5500/api/pics/' + result,
          };
          api.request(options2).then(function (response) {
        
          console.log(this2)
          console.log(response.data);
          this2.neuronet = response.data;
          })             
          .catch(function (error) {
          console.error(error);
          console.log(options2)
          });

          },

          neuroclick( id){
          console.log('qwerty')
          console.log(id);
          //this.$router.push({path: this.$route.fullPath + '/'+'neuroNet' + '/' +id })
          this.$router.push( {path: 'picsDescr', query: { 'description': id }})
      
      },

    },
    
}

</script>

<style scoped>
.link{
  margin-bottom: 2%;
}
.input{
    margin-top: 1%;
  
    display: flex;
}
.content{
    margin-top: 2%;
    margin-left: 3%;
}
.box-content{
    margin-top: 2%;
    margin-left: 3%;
}

</style>