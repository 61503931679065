<template>
    <div class="body">
            <section class="ac-container">
                <div>
                    <input id="ac-1" name="accordion-1" type="checkbox"  />
                    
                    <label for="ac-1">

                    <div class="lbt1">
                        Простой интерфейс, доступный каждому
                         <!--  -->
                         <div class="plus1">
                            <input id="ac-5" name="accordion-2" type="checkbox" />

                                <div class="gp">

                                </div>
                                <div class="vp">
                                    
                                </div>
                            </div>
                            <!--  -->
                     </div>

                    </label>
                   

                    <article>
                        <p>
                            Для работы на нашем сервисе не нужно знание специальных программ и графических редакторов. Система сама сделает все чертежи и ведомости, выставит размеры.
                       
 
                        </p>
                    </article>
                </div>
                <div>
                    <input id="ac-2" name="accordion-1" type="checkbox" />
                    <label for="ac-2">
                        <div class="lbt1">

                       
                        Весь функционал в одной программе
                    <!--  -->
                            <div class="plus1">
                                <input id="ac-5" name="accordion-2" type="checkbox" />

                                <div class="gp">

                                </div>
                                <div class="vp">
                                    
                                </div>
                            </div>
                    <!--  -->
                         </div>
               
                    </label>
                    
                    <article>
                        <p>
                            Мы предлагаем единый интерфейс для творчества, визуализаций и документации, что значительно ускоряет процесс, снижает расходы и вообще упрощает жизнь

                        </p>
                    </article>
                </div>
                <div>
                    <input id="ac-3" name="accordion-2" type="checkbox" />
                    <label for="ac-3">
                        <div class="lbt1">
                         Бесплатная генерация изображений
                             <!--  -->
                             <div class="plus1">
                                <input id="ac-5" name="accordion-2" type="checkbox" />

                                <div class="gp">

                                </div>
                                <div class="vp">
                                    
                                </div>
                            </div>
                            <!--  -->
                        </div>
                   
                    </label>
                    <article>
                        <p>      
                         Вы сможете фантазировать и придумывать бесчисленное количество вариантов, просто делая текстовый запрос, а система сама визуализирует Ваши идеи  

                        </p>
                    </article>
                </div>
                <div>
                    <input id="ac-4" name="accordion-2" type="checkbox" />
                    <label for="ac-4">
                        <div class="lbt1">
                           Сокращение времени и расходов на подбор
                             <!--  -->
                             <div class="plus1">
                                <input id="ac-5" name="accordion-2" type="checkbox" />

                                <div class="gp">

                                </div>
                                <div class="vp">
                                    
                                </div>
                            </div>
                            <!--  -->
                        </div>
                    </label>
                    
                    <article>
                        
                        <p>
                            Вам больше не нужно думать, какие растения подобрать под данные условия участка или где купить выгоднее - система сама все рассчитает и предложит Вам готовые варианты. 
                        </p>
                    </article>
                </div>
         </section>
    </div>
</template>
<script>

export default{

}
</script>

<style scoped>
.gp{
    height: 1px;
    width: 35px;
    background-color: #383838;
    transform: rotate(0deg);
    z-index: 2;

}
.vp{
    height: 1px;
    width: 35px;
    background-color: #383838;
    transform: rotate(90deg);
    margin-top: -1px;
    z-index: 1;
}
.plus1{
    position: absolute;
    right: 0;
   
    justify-content: right;
    margin-right: 2%;
    margin-top: 12px;
}
.plus1 :checked{
    transform: rotate(45deg);
}
.body{
    font-family: "Montserrat-Bold"; 
    
}
.ac-container{
    width: 85%;
    margin: 16px auto 30px auto;
    /* margin-left: 25%; */
    
}

.ac-container label{
    font-family: "Montserrat-Bold";
    padding: 5px 20px;
    margin-top: 45%;
    position: relative;
    z-index: 20;
    display: block;
    height: 85px;
    cursor: pointer;
    color: #000000;
    text-shadow: 1px 1px 1px rgba(255,255,255,0.8);
    line-height: 33px;
    font-size: 36px;
    /* background: -moz-linear-gradient(top, #ffffff 1%, #eaeaea 100%);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(1%,#ffffff), color-stop(100%,#eaeaea));
	background: -webkit-linear-gradient(top, #ffffff 1%,#eaeaea 100%);
	background: -o-linear-gradient(top, #ffffff 1%,#eaeaea 100%);
	background: -ms-linear-gradient(top, #ffffff 1%,#eaeaea 100%); */
	/* background: linear-gradient(top, #ffffff 1%,#eaeaea 100%); */
	/* filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#eaeaea',GradientType=0 ); */
	/* box-shadow: 
		0px 0px 0px 1px rgba(155,155,155,0.3), 
		1px 0px 0px 0px rgba(255,255,255,0.9) inset, 
		0px 2px 2px rgba(0,0,0,0.1); */
}


.ac-container label{
    background: #fff;
    margin-top: 0.5%;
}

.lbt1{
    margin-top: 2.5%;
    margin-left: 2.5%;
    display: flex;
    
}

.ac-container input:checked + label,
.ac-container input:checked + label:hover{
    background: #ffffff;
    color: #000000;
    text-shadow: 0px 1px 1px rgb(255, 255, 255);
    /* box-shadow: 
        0px 0px 0px 1px rgba(155,155,155,0.3), 
        0px 2px 2px rgba(0,0,0,0.1); */
}
.plus1 input:checked{
    /* transform: rotate(45deg); */
    -webkit-transition: height 0.3s ease-in-out, box-shadow 0.6s linear;
	-moz-transition: height 0.3s ease-in-out, box-shadow 0.6s linear;
	-o-transition: height 0.3s ease-in-out, box-shadow 0.6s linear;
	-ms-transition: height 0.3s ease-in-out, box-shadow 0.6s linear;
	transition: height 0.3s ease-in-out, box-shadow 0.6s linear;
    box-shadow: 0px 0px 0px 1px rgb(253, 253, 253);
    height: 110px;
}
.ac-container label:hover:after,
.ac-container input:checked + label:hover:after{
    content: '';
    position: absolute;
    width: 24px;
    height: 24px;
    right: 13px;
    top: 7px;
    /* background: transparent url(http://netcribe.com/example/arrow_down.png) no-repeat center center;   */
}

.ac-container input:checked + label:hover:after {
    /* background-image: url(http://netcribe.com/example/arrow_up.png); */
}
.ac-container input{
    display: none;
}

.ac-container article{
    background: rgb(255, 255, 255);
    margin-top: -1px;
    overflow: hidden;
    height: 0px;
    position: relative;
    z-index: 10;
    -webkit-transition: height 0.3s ease-in-out, box-shadow 0.6s linear;
	-moz-transition: height 0.3s ease-in-out, box-shadow 0.6s linear;
	-o-transition: height 0.3s ease-in-out, box-shadow 0.6s linear;
	-ms-transition: height 0.3s ease-in-out, box-shadow 0.6s linear;
	transition: height 0.3s ease-in-out, box-shadow 0.6s linear;
}
.ac-container input:checked ~ article{
     -webkit-transition: height 0.3s ease-in-out, box-shadow 0.6s linear;
	-moz-transition: height 0.3s ease-in-out, box-shadow 0.6s linear;
	-o-transition: height 0.3s ease-in-out, box-shadow 0.6s linear;
	-ms-transition: height 0.3s ease-in-out, box-shadow 0.6s linear;
	transition: height 0.3s ease-in-out, box-shadow 0.6s linear;
    box-shadow: 0px 0px 0px 1px rgb(253, 253, 253);
    height: 140px;
}

.ac-container article p{
    /* font-style: italic; */
    margin-top: -0%;
    text-align: justify;
    margin-left: 2.5%;
    color: #000000;
    line-height: 33px;
    font-size: 24px;
    padding: 20px;
    /* text-shadow: 1px 1px 1px rgba(255,255,255,0.8); */
    font-family: 'Montserrat-Regular';
    width: 92%;
}

.ac-container div label{
    font-size: 36px;
    font-family: 'Montserrat-SemiBold';
    padding: 0px, 0px, 0px, 0;
}
@media (max-width: 1600px) {
    .ac-container div label{
    font-size: 30px;
    font-family: 'Montserrat-SemiBold';
    padding: 0px, 0px, 0px, 0;
}
}

</style>
