<template>
    <heder/> 
    <!-- <hederd/> -->
    <div class="heder">
        <br>
            <div class="up">
                <a href="#constructor">
                <p><img src="./ikons/strelkanazad1.png">  Назад</p>
                </a>
                <div class="versions">
                    <p>
                    Версия 1
                </p>
                <p>
                    Версия 2
                </p>
               
                <p>
                    Версия 2
                </p> 
                </div>
               
                <div class="close">
                    <!-- <save/> -->
                    <button class="btn closebtn">
                        Закрыть проект
                    </button>
                 </div>
               
               
            </div>
            <div class="bottom">
                <div class="blockp1">
                    <div class="p1">
                        <strong>
                            Без названия
                        </strong>
                        <button
                        @click="pr_rename"
                         class="btn rename">
                            <img class="imgren" src="./ikons/rename.png">
                        </button>
                    </div>
                    
                </div> 
              
                <div class="heder2">

                <a href="#shablon">
                        <div class="p2">
                        Результат генерации
                    </div>  
                    </a>         
                
                    <div class="p2">
                        Основные данные
                    </div>
                    <a href="#visual">
                    <div class="p2">
                        Визуальные предпочтения
                    </div>  
                    </a>
                
                    <a href="#docs">
                    <div class="p2">
                        Документация
                    </div>
                    
                    </a>
                    <a href="#orderplant">
                    <div class="p2">
                        Подборки растений
                    </div>
                    </a>


                </div>

            </div>
            <br>
        </div> 
        <div class="body">
  
            <div class="content">

                <div class="lcont">
                    <div class="imgbox">
                         <img class="img" src="./ikons/27-42.jpg">
                    <button class="btn downloat">
                       <img class="dwnltbtn" src="./ikons/output-onlinepngtools.png">
                        Скачать или поделиться
                    </button><br>
                    <button class="btn togalery">
                         Опубликовать в галерею   
                    </button>
                    </div>
                   

                </div>

                <div class="rcont">
                        <div class="rhed">
                            <p>
                                <strong>
                                     Доступно на аккаунте PRO
                                </strong>
                               
                            </p>
                            <label>
                                Приобретите проффесиональный аккаунт
                                и получите доступ к подробной
                                документации проекта
                                и также закажите растения
                                удобно у поставщика 
                                со скидкой 15%. 
                            </label>
                        </div>
                        <div class="rbox">
                            <button class="btn docbtn">
                                Эскиз
                            </button>
                             <button class="btn docbtn">
                               Генеральный план

                            </button>
                             <button class="btn docbtn">
                                Посадочный план

                            </button>
                             <button class="btn docbtn">
                               Ведомость растений
                            </button>
                             <button class="btn docbtn">
                               Ведомость материалов

                            </button>
                            <div class="btnbox">
                                <div class="box">
                                     <button class="btn dwnlt">
                  <!-- <img class="dwnltbtn" src="./ikons/output-onlinepngtools.png">  -->

                                Скачать все одним архивом

                                </button>
                                <div class="ques">?</div>
                                </div>
                               <div class="box">
                                <button class="btn order">
                                  <img class="orderimg" src="./ikons/free-icon-basket-8181447.png">
                                  Создать заказ

                                </button> <div class="ques">?</div>
                               </div>
                                
                            </div>
                            
                        </div>
                </div>
            </div>
           
       
    </div>
    
</template>



<script>
import heder from "./components/hederTree.vue";
import axios from "axios";
// import filter1 from "./components/filtrModal.vue";
//import hederd from "./components/hederDuo.vue";

export default{
    components:{
     // hederd,
     
        heder,

       
        
    },

    methods :{

        openmodal1(){
            // Открыть модальное окно
                document.getElementById("open-modal-btn").addEventListener("click", function() {
                    document.getElementById("my-modal1").classList.add("open")
                })

                // Закрыть модальное окно
                document.getElementById("close-my-modal-btn").addEventListener("click", function() {
                    document.getElementById("my-modal1").classList.remove("open")
                })

                // Закрыть модальное окно при нажатии на Esc
                window.addEventListener('keydown', (e) => {
                    if (e.key === "Escape") {
                        document.getElementById("my-modal1").classList.remove("open")
                    }
                });

                // Закрыть модальное окно при клике вне его
                document.querySelector("#my-modal1 .modal__box").addEventListener('click', event => {
                    event._isClickWithInModal = true;
                });
                document.getElementById("my-modal1").addEventListener('click', event => {
                    if (event._isClickWithInModal) return;
                    event.currentTarget.classList.remove('open');
                });
                        },

        search(){
            const options = {
            method: 'GET',
            url: 'http://185.221.153.253:5700/api/search',
            headers: {Authorization: 'Bearer 0ded13aa-624b-40cf-bef7-dc7a2bf97c2c'},
            data: {
                f_compos_type: this.valuesearch,
                f_template: this.valuesearch,
                f_temp_char: this.valuesearch,
                f_place: this.valuesearch,
                f_ground_sost: this.valuesearch,
                f_ground_kisl: this.valuesearch,
                f_ct_size: this.valuesearch,
                f_ct_form: this.valuesearch,
                f_ct_light: this.valuesearch,
                f_ct_color: this.valuesearch,
                f_ct_maf: this.valuesearch,
                f_ct_vl: this.valuesearch,
                f_ct_res: this.valuesearch,
                f_ct_aroma: this.valuesearch,
                f_ct_plants: this.valuesearch
            }
            };

            axios.request(options).then(function (response) {
            console.log(response.data);
            }).catch(function (error) {
            console.error(error);
            });
        }

        }
}
</script>

<style scoped>
.close{
     width: 40%; 
    text-align: right;
}
.bottom{
    text-align: left;
    font-size: 24px;
}
.versions{
    display: flex;
    text-align: center;
    margin-top: 0.2%;
    margin-left: 37%;
}
.versions p{
    margin-left: 3%;
    width: 85px;
}
.content{
    display: flex;
    margin-top: 2%;
    justify-content: center;

}

.rcont{
    margin-left: 1%;
    width: 30%;
}
.rhed p{
    font-size: 24px;
}
.dwnlt{
    display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 16px 20px;
gap: 10px;
margin-top: 2%;
/* position: absolute; */
width: 500px;
height: 54px;
/* left: 927px;
top: 847px; */

background: #949494;
border-radius: 10px;
}
.order{
    display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 16px 20px;
gap: 10px;
margin-top: 2%;
/* position: absolute; */
width: 500px;
height: 54px;
/* left: 927px;
top: 928px; */

background: #CD5E5E;
border-radius: 10px;
}
.orderimg{
    width: 40px;
}
.docbtn{
    display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 16px 20px;
gap: 10px;
margin-top: 2%;
/* position: absolute; */
width: 630px;
height: 55px;
/* left: 926px;
top: 393px; */
background: #D9D9D9;
border-radius: 10px;
border: none
}

.box{
    display: flex;
}
.ques{
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background-color: #D9D9D9;
    text-align: center;
    font-size: 24px;
    margin-left: 50px;
    margin-top: 3%;
}
.lcont{
    width: 60%;
    height: 50vh;
    /* margin-left: 2%; */

}
.imgbox{
    width: 100%;
    height: 100%;
    margin-left: -4%;
}
.imgbox button{
    position: relative;
    margin-left: 60%;
     margin-top: 1%;
    background: #D9D9D9;
}
@media screen and (max-width: 1880px) {
    .docbtn{
    display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 16px 20px;
gap: 10px;
margin-top: 2%;
/* position: absolute; */
width: 500px;
height: 55px;
/* left: 926px;
top: 393px; */
background: #D9D9D9;
border-radius: 10px;
border: none
} 
.ques{
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background-color: #D9D9D9;
    text-align: center;
    font-size: 24px;
    margin-left: 60px;
    margin-top: 3%;
}
}


@media screen and (max-width: 1480px) {
    .imgbox button{
        margin-left: 50%;
    }

    .docbtn{
    display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 16px 20px;
gap: 10px;
margin-top: 2%;
/* position: absolute; */
width: 430px;
height: 55px;
/* left: 926px;
top: 393px; */
background: #D9D9D9;
border-radius: 10px;
border: none
}


}
@media screen and (max-width: 1280px) {
    .imgbox button{
        margin-left: 35%;
    }
    .docbtn{
        width: 400px;

    }
    .ques{
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background-color: #D9D9D9;
    text-align: center;
    font-size: 24px;
    margin-left: 60px;
    margin-top: 3%;
}
   


}
@media screen and (max-width: 1100px) {
    .imgbox{
    width: 100%;
    height: 100%;
}
    .imgbox button{
        margin-left: 35%;
    }
     .content{
        display: grid;
    }
    .docbtn{
        width: 600px;

    }
    .lcont{
        width: 100%;
    }
    .rhed{
        width: 100%;
    }
    .img{
    width: 150%;
    position: absolute;
    
}

.imgbox button{
        margin-left: 25%;
    }

}
.img{
    width: 60%;
    position: absolute;
}
.dwnltbtn{
    width: 30px;
}
.downloat{
    min-width:350px ;
    min-height: 40px;    
    border-radius: 10px;
  
}

.downloat:hover{
    background: #D9D9D9;
}
.togalery{
    min-width:350px ;
    min-height: 40px; 
    border-radius: 10px;
}
.up input{
    float: left;
   
}
.up{
    display: flex;
    margin-top: -0.2%;
    width: 100%;
}

.heder{
    width: 98%;
    margin-top: 0%;
    text-align: center;
}
.heder a{
    text-align: center;
    justify-content: center;
    align-content: center;
}
.body{
    height: 120%;
    margin-top: 0px;
    font-family: "Montserrat-Regular";
}
/* Heder */

.heder{
    margin-left: 2%;
    margin-top: -2px;
    font-family: "Montserrat-Regular";
}
.heder2{
    text-align: center;
    margin-top: -40px;
    justify-content: center;
    align-content: center;
    margin-left: 18%;
}
.heder2 a{
    text-decoration: none;
    color: black;
}
.p2{
    float: left;
    margin-left: 1%;
    font-size: 16px;
    margin-top: 0.5%;
    text-align: center;
    width: 240px;
    height: 19px;
}
.up a{
    text-decoration: none;
    color: black;
}





</style>
