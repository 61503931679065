<template>
  <div class="link"  ><a href="#onePage">back</a></div>
    <!-- <div class="panel-button">

        <button @click="neurinetUpdate">Изменить </button>
        <button @click="neurinetDElete">Удалить</button>
       

    </div> -->


    <div class="panel">

        <input
            width="200px"           
            type="text"
            class="form-control"
            placeholder="descr"
            v-model="descr"
          >
          <input
          width="200px"
            type="text"
            class="form-control"
            placeholder="m_loss"
            v-model="m_loss"
          >
          <input
          width="200px"
            type="text"
            class="form-control"
            placeholder="m_metrics"
            v-model="m_metrics"
          >
          <input
          width="200px"
            type="text"
            class="form-control"
            placeholder="m_optimizer"
            v-model="m_optimizer"
          >
        
          <input
          width="200px"
            type="text"
            class="form-control"
            placeholder="name"
            v-model="name"
          >
          <input
          width="200px"
            type="text"
            class="form-control"
            placeholder="ID каталога"
            v-model="pic_folder_id "

          >
          

    </div> 
    <div class="panel-button">

<button @click="neurinetUpdate">Изменить </button>
<button @click="neurinetDElete">Удалить</button>


</div>
    <div class="training">

            <button @click="neuroStart">Обучить</button><br>
            <input
          width="200px"
            type="number"
            class="form-control"
            placeholder="epochs"
            v-model=epochs
          >
         
          <input
          width="200px"
            type="number"
            class="form-control"
            placeholder="verbose, всегда вводить значение 1!!!"
            v-model=verbose
          >
         
         
          </div>
          <div class="trainingresult">
            <!-- <button @click="neuroStat">result</button> -->
            <ul>
              <li v-for:="options8 in neuro">
                  {{options8}}
              </li>
            </ul>
          </div>


    <div class="div">
       
        <table class="table">
              <thead>
                <tr>
                    <th></th>
                    <th scope="col">descr</th>
                    <th scope="col">id</th>
                    <th scope="col">m_loss</th>
                    <th scope="col">m_metrics</th>
                    <th scope="col">m_optimizer</th>
                    <th scope="col">model_created</th>
                    <th scope="col">model_file</th>
                    <th scope="col">name</th>
                    <th scope="col">model_file</th>
                </tr>
              </thead>
              <tbody>
                <tr
                
                 
                >
                  <input
                  class="form-check-input" 
                  type="radio" 
                  name="flexRadioDefault" 
                  id="flexRadioDefault1"
                  
                  />
                  <td scope="row"  >{{(neuronet)? neuronet.descr : ''}}</td>
                    <td>{{(neuronet)? neuronet.id : ''}}</td>
                    <td>{{(neuronet)? neuronet.m_loss : ''}}</td>
                    <td>{{(neuronet)? neuronet.m_metrics : ''}}</td>
                    <td>{{(neuronet)? neuronet.m_optimizer : ''}}</td>
                    <td>{{(neuronet)? neuronet.model_created : ''}}</td>
                    <td>{{(neuronet)? neuronet.model_file : ''}}</td>
                    <td>{{(neuronet)? neuronet.name : ''}}</td>
                    <td>{{(neuronet)? neuronet.pic_folder_id : ''}}</td>
                </tr>
              </tbody>
           </table>
            {{neuro}}
           <!-- <button @click="neuroLayers">neurolayers</button> -->
              <div class="layerscreate">

                <!-- <div class="panel2">
                <button @click="neurinetCreate" >Создать </button>
                </div> -->
                <div class="panel1">
                  
              
                   <input
                    width="200px"           
                    type="text"
                    class="form-control"
                    placeholder="activation"
                    v-model="activation"
                  >
                  <input
                  width="200px"
                    type="text"
                    class="form-control"
                    placeholder="input_num"
                    v-model="input_num"
                  >
                  <input
                  width="200px"
                    type="text"
                    class="form-control"
                    placeholder="input_x"
                    v-model="input_x"
                  >
                  <input
                  width="200px"
                    type="text"
                    class="form-control"
                    placeholder="input_y"
                    v-model="input_y"
                  >
                  <input
                  width="200px"
                    type="text"
                    class="form-control"
                    placeholder="layer_type"
                    v-model="layer_type"  
                  >
                  <input
                  width="200px"
                    type="text"
                    class="form-control"
                    placeholder="n_count"
                    v-model="n_count"
                  >
                
                  <input
                  width="200px"
                    type="text"
                    class="form-control"
                    placeholder="order"
                    v-model="order"
                  >
                  <input
                  width="200px"
                    type="text"
                    class="form-control"
                    placeholder="x"
                    v-model="x"
                  >
                
                  <input
                  width="200px"
                    type="text"
                    class="form-control"
                    placeholder="y"
                    v-model="y"
                  >

                </div>
                
              </div>
              <div class="panel2">
                <button @click="neurinetCreate" >Создать </button>
                </div>
           <table class="table">
              <thead>
                <tr>
                    
                    <th scope="col">activation</th>
                    <th scope="col">input_num</th>
                    <th scope="col">input_x</th>
                    <th scope="col">input_y</th>
                    <th scope="col">layer_type</th>
                    <th scope="col">n_count</th>
                    <th scope="col">order</th>
                    <th scope="col">x</th>
                    <th scope="col">y</th>
                </tr>
              </thead>
              <tbody>
                <tr
                 v-for:="qwerty in neurolay" 
                 @click="neuroclick(qwerty.id) "
                >                  
                    <td scope="row"  >{{qwerty.activation}}</td>
                    <td>{{qwerty.input_num}}</td>
                    <td>{{qwerty.input_x}}</td>
                    <td>{{qwerty.input_y}}</td>
                    <td>{{qwerty.layer_type}}</td>
                    <td>{{qwerty.n_count}}</td>
                    <td>{{qwerty.order}}</td>
                    <td>{{qwerty.x}}</td>
                    <td>{{qwerty.y}}</td>
                    <td>{{qwerty.id}}</td>
                </tr>
              </tbody>
           </table>
          
    </div>
    
    </template>
    
    <script>
    
    
    //import { mapGetters } from 'vuex';
    import api from  "../../JS/api.js"    //import axios from "axios";
    //import VueRouter from "vue-router";
    
       //axios 
    
     export default{
      
        name: "neuroNet",
        data(){
       return{
           
            neuronet: null,
            neurolay: null,
            neuro: null
        }
    },
    
    mounted(){
      console.log(this,'1')
        this.neuronetIN(this)  //использовать для прорисовки по умолчанию
        this.neuroLayers(this)
    },

    methods: {
      
        
         neuronetIN(this3){

console.log('neuroIN', this.$route.query.neuronet) 
            let result = this.$route.query.neuronet
                console.log('Hello world','3');
          
          const options5 = {
            method: 'GET',
            url: 'http://185.221.152.242:5500/api/neuronet/'+ result,
        
        }
                              
          api.request(options5).then(function (response) {
                       
           console.log(this3,'4')
            console.log(response.data);
             this3.neuronet = response.data;
             //console.log(this3.neuronet.id)
              //console.log(options5)
              
          }) 
                  
          .catch(function (error) {
            console.error(error,'5');
            console.log(options5,'6')
          })
          
           },
           
           neurinetUpdate(){
           
             
           console.log('Hello world');
           let result = this.$route.query.neuronet
                console.log('Hello world','3');
          
           const options4 = {
           method: 'POST',
           url: 'http://185.221.152.242:5500/api/neuronet/'+ result,
           headers: {'Content-Type': 'application/json'},
         data: {
          

           descr: this.descr,

           
           m_loss: this.m_loss,

           m_metrics: this.m_metrics,
          
           m_optimizer: this.m_optimizer,

           model_created: this.model_created,

           model_file: this.model_file,

           name: this.name,

           pic_folder_id: this.pic_folder_id
          

           }
          
         };
          api.request(options4).then(function (response) {
           console.log(response);
            console.log(options4);
            window.location.reload();
        
          }) 
         
  
         },   
         neurinetDElete(){
           
             
           console.log('Hello world');
           let result = this.$route.query.neuronet
                console.log('Hello world','3');
          
           const options6 = {
           method: 'DELETE',
           url: 'http://185.221.152.242:5500/api/neuronet/'+ result,
           headers: {'Content-Type': 'application/json'},
        
         };
          api.request(options6).then(function (response) {
           console.log(response);
            console.log(options6);
            window.location.reload();
        
             
             
             
          }) 
         
  
         },   


         neuroStart(){
            let result = this.$route.query.neuronet
            const options7 = {
                method: 'POST',
                url: 'http://185.221.152.242:5500/api/neurostart/'+ result,
                data: {
                  epochs: this.epochs, //эпохи
                  verbose: this.verbose  // не трогать
                  
                    }
                };

                api.request(options7).then(function (response) {
                console.log(response.data);
                }).catch(function (error) {
                console.error(error);
                });


         },

         neuroStat(){
            let result = this.$route.query.neuronet
            const options8 = {
                method: 'GET',
                url: 'http://185.221.152.242:5500/api/neurostart/'+ result,
                
                };

                api.request(options8).then(function (response) {
                console.log(response.data, );
               //let neurt = response.data
                alert('data: ',response.data)
               // console.log(neurt, 'qwerrt');
                }).catch(function (error) {
                console.error(error);
                });
         },

         neuroLayers(this2){
            let result = this.$route.query.neuronet
            const options2 = {
                method: 'GET',
                url: 'http://185.221.152.242:5500/api/neurolayers/' + result,
            }

            api.request(options2).then(function (response) {
           
         
           localStorage.setItem('id', response.data.id)
          console.log(this2)
           console.log(response.data);
             this2.neurolay = response.data;
             let qwerty = response.data;
             console.log(qwerty);
            
             
         }) 
         },
         neuroclick( id){
          console.log('qwerty')
          console.log(id);
          //this.$router.push({path: this.$route.fullPath + '/'+'neuroNet' + '/' +id })
          // this.$router.push( {path: 'neuroLayer', query: { 'layer': id }})
         },
         calculator(){
          let result = this.$route.query.neuronet
            const options2 = {
                method: 'GET',
                url: 'http://185.221.152.242:5500/api/neurolayers/' + result,
            }

            api.request(options2).then(function (response) {
           
         
             localStorage.setItem('id', response.data.id)
             let id = response.data.id
             console.log('id'+ id);

            //  console.log(this2)
            //  console.log(response.data);
            //  this2.neurolay = response.data;
            //  let qwerty = response.data;
            //  console.log(qwerty);
            
             
         }) 
          //localStorage.setItem('id', response.data.id)
          
          
         },
         neurinetCreate(){
             
             console.log('Hello world');
             let result = this.$route.query.neuronet
             const options3 = {
             method: 'POST',
             url: 'http://185.221.152.242:5500/api/neurolayers/' + result,
             //headers: {'Content-Type': 'application/json'},
           data: {
 
            activation: this.activation,
 
            input_num: this.input_num,
 
            input_x: this.input_x,
 
            layer_type: this.layer_type,
 
            n_count: this.n_count,
 
             order: this.order,
 
             x: this.x,

             y: this.y
           
             }
             
           };
            api.request(options3).then(function (response) {
           
             
             console.log(response);
              console.log(options3);
              window.location.reload();
                             
            }) 
              
           },     

          //  pic_folder_id(){

          //  }


           
               
    }
     }

    </script>
    
    
    <style scoped>
    .link{
      margin-top: 1%;
      margin-left: 3%;
      
    }
    .trainingresult{

      margin-top: 1%;
        margin-left: 10%;
        display: flex;
    }
    .training{
      margin-top: 5%;
        margin-left: 10%;
        display: flex;
    }
    .panel-button{
        margin-top: 5%;
        margin-left: 10%;
        display: flex;
    }
    .panel{
        margin-top: 5%;
        margin-left: 10%;
        display: flex;
    }
    .panel1{
        
       margin-bottom: 3%;
        display: flex;
    }
    .panel2{
        margin-top: 1%;
       
        display: flex;
    }
    .div{
        margin-top: 5%;
        margin-left: 10%;
    }
    
    </style>