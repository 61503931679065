<template>
    
    <div class="body">
 <HederUno/>
       
       <tophead/>
       <div class="tarifs">
        <div class="tcont">
            <!-- <table>
                <tr>
                    <td>
                        <img alt="img">
                        <p  class="tp">Free</p>
                    </td>
                    <td>
                        <img alt="img">
                        <p class="tp">Premium 30 дней</p>
                    </td>
                    <td>
                        <img alt="img">
                        <p class="tp" >Premium 365 дней</p>
                    </td>
                </tr>
            </table> -->
        <p class="tarifhedp">
                    Выберите подходящий Вам тариф
                </p>
                <table>
                    <tr>
                        <td>
                           
                           <img class="ikon" src="../land/assets/icons/free-icon-tag-2048842.png" alt="img"   >                           
                                
                          
                           
                        </td>
                        <td>
                            <img class="ikon" src="../land/assets/icons/free-icon-premium-quality-4212243.png" alt="img"   >   
                        </td>
                        <td>
                            <img class="ikon" src="../land/assets/icons/free-icon-premium-services-14165.png" alt="img"   >   
                           
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <h3>
                                     <strong>
                                    Free 
                                      </strong>
                                </h3>                                
                                
                          
                        </td>
                        <td>
                            <h3>
                                     <strong>
                                        Premium 30
                                      </strong>
                                </h3>                                
                                
                          
                        </td>
                        <td>
                            <h3>
                                     <strong>
                                        Premium 365
                                      </strong>
                                </h3>                                
                                
                            
                        </td>
                    </tr>
                    <!--  -->
                    <tr>
                        <td>
                            <label>
                                Определение стилевых и эмоциональных предпочтений
                            </label>
                            <div class="gpoloska"></div>
                        </td>
                        <td>
                            <label>
                                Набор рекомендаций в соответствии с предпочтениями ко всем проектам                            </label>
                            <div class="gpoloska"></div>
                        </td>
                        <td>
                            <label>
                                Все, что включено в Премиум-30 на весь год!
                            </label>
                            <div class="gpoloska"></div>
                        </td>
                    </tr>
                        <!--  -->
                    <tr>
                        <td>
                            <label>
                                Доступ к бесплатной базе проектов
                            
                            </label>
                            <div class="gpoloska"></div>
                        </td>
                        <td>
                            <label>
                                Больше бесплатных шаблонов, бесплатные арт-шаблоны                            
                            </label>
                            <div class="gpoloska"></div>
                        </td>
                        <td>
                            <label>
                                Онлайн-сопровождение дизайнером                            
                            </label>
                            <div class="gpoloska"></div>
                        </td>
                    </tr>
                        <!--  -->
                    <tr>
                        <td>
                            <label>
                                Генерация до 10 изображений в день                            
                            </label>
                            <div class="gpoloska"></div> 
                        </td>
                        <td>
                            <label>
                                Безлимитная генерация изображений весь месяц                            </label>
                            <div class="gpoloska"></div>
                        </td>
                        <td>
                            <label>
                                Календарь и рекомендации по уходу ко всем проектам на год!
                            </label>
                            <div class="gpoloska"></div>
                        </td>
                    </tr>
                        <!--  -->
                    <tr>
                        <td>
                            <label>
                                Бесплатные шаблоны
                            
                            </label>
                            <div class="gpoloska"></div>
                        </td>
                        <td>
                            <label>
                                Генерация проектной документации   
                             </label>
                            <div class="gpoloska"></div>
                        </td>
                        <td>
                            <label>
                                Возможность командной работы                            
                            </label>
                            <div class="gpoloska"></div>
                        </td>
                    </tr>
                        <!--  -->
                    <tr>
                        <td>
                            <label>
                                Быстрый заказ
                            
                            </label>
                        </td>
                        <td>
                            <label>
                                Подбор растений и материалов
                                                         </label>
                            <div class="gpoloska"></div>
                        </td>
                        <td>
                            <label>
                                Личный менеджер и техподдержка 24/7
                            
                            </label>
                            <div class="gpoloska"></div>
                        </td>
                    </tr>
                        <!--  -->
                    <tr>
                        <td>

                            <!-- <div class="tariffoot">
                                <p class="tfp1">
                                    Бесплатной
                                </p>
                                <button class="btn button1">
                                    Забронировать
                                </button>
                            </div> -->
                        </td>
                        <td>
                            <label>
                                Подбор самых выгодных доставок
                             </label>
                        </td>
                        <td>
                            
                        </td>
                    </tr>
                        <!--  -->
                    <tr>
                        <td>
                            <div class="tariffoot">
                                <p class="tfp4">
                                    Бесплатной
                                </p>
                                <button class="btn button1">
                                    Забронировать
                                </button>
                            </div>
                        </td>
                        <td>
                            <div class="tariffoot">
                                <p class="tfp4">
                                    300 рублей
                                </p>
                                <button class="btn button1">
                                    Забронировать
                                </button>
                            </div>
                        </td>
                        <td>
                           <div class="tariffoot">
                                <p class="tfp4">
                                    300 рублей
                                </p>
                                <button class="btn button1">
                                    Забронировать
                                </button>
                            </div>
                        </td>
                    </tr>
                        <!--  -->
                  
                        <!--  -->
                        <tr>
                            <td>
                                
                            </td>
                        </tr>
                </table>
                <br>
            <!-- <div class="tarifshed"> 
                
                <div class="tarif1">
                    <div class="tarifhed">
                        <img alt="img">
                        <p  class="tp">Free</p>
                    </div>
                    <div class="tarifbody">
                            <div class="gpoloska"></div>
                            <label>
                                Определение стилевых и эмоциональных предпочтений
                            </label>
                            <div class="gpoloska"></div>
                            <label>
                                Доступ к бесплатной базе проектов
                            
                            </label>
                            <div class="gpoloska"></div>
                            <label>
                                Генерация до 10 изображений в день                            
                            </label>
                            <div class="gpoloska"></div>
                            <label>
                                Бесплатные шаблоны
                            
                            </label>
                            <div class="gpoloska"></div>
                            <label>
                                Быстрый заказ
                            
                            </label>
                            <div class="gpoloska"></div>
                            <div class="tariffoot">
                                <p class="tfp1">
                                    Бесплатной
                                </p>
                                <button class="btn button1">
                                    Забронировать
                                </button>
                            </div>
                    </div>
                </div>
                <div class="vpoloska"></div>
                
                <div class="tarif2">
                    <div class="tarifhed">
                        <img alt="img">
                        <p class="tp">Premium 30 дней</p>
                    </div>
                    <div class="tarifbody">
                            <div class="gpoloska"></div>
                            <label>
                                Набор рекомендаций в соответствии с предпочтениями ко всем проектам                            </label>
                            <div class="gpoloska"></div>
                            <label>
                                Больше бесплатных шаблонов, бесплатные арт-шаблоны                            
                            </label>
                            <div class="gpoloska"></div>
                            <label>
                                Безлимитная генерация изображений весь месяц                            </label>
                            <div class="gpoloska"></div>
                            <label>
                                Генерация проектной документации   
                             </label>
                            <div class="gpoloska"></div>
                            <label>
                                Подбор растений и материалов
                                                         </label>
                            <div class="gpoloska"></div>
                            <label>
                                Подбор самых выгодных доставок
                             </label>
                        
                            <div class="tariffoot">
                                <p class="tfp2">
                                    300 рублей
                                </p>
                                <button class="btn button1">
                                    Забронировать
                                </button>
                            </div>
                    </div>
                </div>
                <div class="vpoloska"></div>
                <div class="tarif3">
                    <div class="tarifhed">
                        <img alt="img">
                        <p class="tp" >Premium 365 дней</p>
                    </div>
                    <div class="tarifbody">
                            <div class="gpoloska"></div>
                            <label>
                                Все, что включено в Премиум-30 на весь год!
                            </label>
                            <div class="gpoloska"></div>
                            <label>
                                Онлайн-сопровождение дизайнером                            
                            </label>
                            <div class="gpoloska"></div>
                            <label>
                                Календарь и рекомендации по уходу ко всем проектам на год!
                            </label>
                            <div class="gpoloska"></div>
                            <label>
                                Возможность командной работы                            
                            </label>
                            <div class="gpoloska"></div>
                            <label>
                                Личный менеджер и техподдержка 24/7
                            
                            </label>
                            <div class="gpoloska"></div>
                            <div class="tariffoot">
                                <p class="tfp3">
                                    Бесплатной
                                </p>
                                <button class="btn button1">
                                    Забронировать
                                </button>
                            </div>
                    </div>
                </div>
            </div>-->
        </div>
       </div>
          <!-- <quest/> -->
        <boost/>
       
        <Footer/>
    </div>
</template>

<script>
import HederUno from './hederUno.vue'
import Footer from './footerUno.vue'
import boost from './components/components/boostUno.vue'
import tophead from './components/components/topHead.vue'
//import quest from './components/components/questsUno.vue'
export default{
    components:{
        HederUno,

        Footer,
        boost,
        tophead,
      //  quest,
    },


}
</script>

<style scoped>
.ikon{
    width: 100px;
}
table{
    justify-content: space-between;
    width: 100%;

}
tr{
    width: 30%;
    text-align: center;
    /* border: 2px solid red; */
}
td{
    width: 30%;
    border: 24px solid rgb(255, 255, 255);
}
.tarifbody label{
    margin-top: 5%;
    margin: 0;
    height: 90px;
    display: flex;
    align-items: center;
    /* align-content: center; */
    justify-content: center;
    
}
td label{
    margin-top: 5%;
    margin: 0;
    height: 90px;
    display: flex;
    align-items: center;
    /* align-content: center; */
    justify-content: center;
    
}
.td h3{
    font-family: 'Montserrat-Bold';
}
.tariffoot p{
    /* margin-top: 5%; */
}

.tfp1{
    margin-top: 29%;
}
.tfp2{
    margin-top: 5%;
}
.tfp3{
    margin-top: 23%;
}
.tfp4{
    margin-top: 0%;
}
.button1{
    /* margin-top: 5%; */
    background-color: #a0b19a;
    width: 50%;
    min-width: 200px;
    height: 50px;
    border-radius: 25px;
}
.gpoloska{
    width: 100%;
    height: 2px;
    background-color: rgb(230, 230, 230);  
    margin-top: 5%;  
}
/* .vpoloska{
    width: 2px;
    height: 400px;
    background-color: rgb(230, 230, 230);
    margin-left: 45%;
    
} */
.tarif1{
    float:left;

    /* /* margin-left: 1%; */
    width: 30%; 

}
.tarif2{
    float:left;
    /* margin-left: 2%; */
    width: 30%; 
    
}
.tarif3{
    float:left;
    /* margin-left: 1%; */
    width: 30%; 
}
.tp{
    font-size: 30px;
    font-family: 'Montserrat-Bold';
}
.tarif1 p{
    /* font-size: 25px; */
    font-family: 'Montserrat-Bold';
}
.tarif2 p{
    /* font-size: 25px; */
    font-family: 'Montserrat-Bold';
}
.tarif3 p{
    /* font-size: 25px; */
    font-family: 'Montserrat-Bold';
}
.tarifs{
    /*  */
    margin-left: 2%;
    font-family: 'Montserrat-Regular';
    text-align: center;
    font-size: 20px;
     /* width: 55%; */
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    /* align-content:center */
}

.tcont{
    width: 75%;
      display: flex;
    align-items: center;
    justify-content: space-between;
  
    flex-direction: column;
}
.tarifshed{
  widows: 75%;
    display: flex;
    justify-content: space-between;
    align-items: center;
   align-content: center;
    flex-direction: row;
}
.tarifhedp{
    font-family: 'Montserrat-Bold';
    font-size: 50px;
}
.body{
    /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; */
    font-family: 'Montserrat-Regular';
    z-index: 199999999999999999;
    /* margin-top: 100px; */
}
</style>
