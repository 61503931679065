<template>
    <div class="body">
     <swiper
    :modules="modules"
    :slides-per-view="1"
    :space-between="50"
    navigation
    :pagination="{ clickable: true}"
  
    @swiper="onSwiper"
    @slideChange="onSlideChange"
  >
    <swiper-slide>
   
        <div class="sl">
                <img class="teamimg" src="./../../assets/foto/Evgenia.png" alt="img">
               <div class="member">
                <p class="tp1">Евгения Крупина</p>
                            <p class="tp2">Генеральный<br> директор</p>
                    <label class="comlb">
                                Ландшафтный архитектор, специалист по маркетингу. Опыт руководства коммерческими организациями более 15 лет, опыт разработки и вывода продуктов на рынок.   
                     </label>
                </div>
        </div>    
         
    </swiper-slide>
    <swiper-slide>
        <div class="sl">
        <img class="teamimg" src="./../../assets/foto/Alexander.png" alt="img">
         <div class="member">
            <p class="tp1">Александр Жмурко</p>
                   
                    <p class="tp2">Руководитель<br> разработки</p>
                    <label class="comlb">
                        Опыт руководства инновационными проектами более 10 лет. Реализовано более 20 крупных инновационных проектов для ФОИВ и государственных компаний, в т.ч. решений с использованием ИИ 
                    </label>
                    </div>
         </div>
    </swiper-slide>
    <swiper-slide>
        <div class="sl">
                <img class="teamimg" src="./../../assets/foto/Tatiana.png" alt="img">
               <div class="member">
                <p class="tp1">Татьяна<br> Конопатова</p>
                    <p class="tp2">Руководитель бизнес-<br>планирования</p>
                    <label class="comlb">
                        Профессиональный бизнес-консультант (финансы, маркетинг). Опыт бизнес-планирования, продаж, управления проектами более 15 лет.
                    </label>
                </div>
        </div>     
    </swiper-slide>
    <swiper-slide>
     
      <div class="sl">
                <img class="teamimg" src="./../../assets/foto/Dima.png" alt="img">
               <div class="member">
                <p class="tp1">Дмитрий<br> Борзов</p>
                    <p class="tp2">Product Director</p>
                    <label class="comlb">
                      Опыт управления проектами в сфере безопасности и ML-разработки более 10 лет.                    </label>
                </div>
        </div>  
    </swiper-slide>
    <swiper-slide>
 <div class="sl">
                <img class="teamimg2" src="./../../assets/foto/Mikhail.jpg" alt="img">
               <div class="member">
                <p class="tp1">Червень<br> Михаил</p>
                    <p class="tp2">
                        Фронт-разработчик
                        
                        <br> 
                        
                        
                    </p>
                    
                    <label>

                    </label>
                </div>
        </div>  
    </swiper-slide>
  
  </swiper>
</div>
</template>

<script>
 import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
    export default{
        components: {
      Swiper,
      SwiperSlide,
    },
    setup() {
      const onSwiper = (swiper) => {
        console.log(swiper);
        
      };
      const onSlideChange = () => {
        console.log('slide change');
      };
      return {
        onSwiper,
        onSlideChange,
        modules: [Navigation, Pagination, Scrollbar, A11y],
      };
    },
    }
</script>

<style scoped>

.body{
    margin-top: 3%;
}
.member{
    display: flex;
    flex-direction: column;
    width: 30%;
}
.sl{
    display: flex;
    justify-content: space-evenly;
    justify-items: center;
    align-items: center;

}
.teamimg{
    padding: 25px;
}
.teamimg2{
  padding: 25px;
  
}
.tp1{
    font-size: 60px;
   }
   .tp2{
    font-size: 36px;
    color: #a2a2a2;
   }
   .comlb{
    margin-top: 0px;
    font-size: 24px;
   }
  .image-slider .swiper-button-next::after{
    color: #000;
   }
   .swiper-button-next:after, .swiper-container-rtl .swiper-button-prev:after {
content: '000';
}

.swiper-button-prev:after, .swiper-container-rtl .swiper-button-prev:after {
content: '';
color: #000;
}
.Navigation{
  content: '';
color: #000;
}

</style>