<template>
    <heder/>
    <!-- <hederd/> -->
    <div class="heder">
            <div class="up">
                <a href="#shagdva">
                <p><img src="./ikons/strelkanazad1.png">  Назад </p>
                </a>
                <div class="p1">
                        <!-- <input  v-model="pr_descr"  type="tel" placeholder="Без названия" > -->
                        <strong>
                              Без названия
                        </strong>
                          
                       
                        <button
                        @click="pr_rename"
                         class="btn rename">
                            <img class="imgren" src="./components/ik/rename.png">
                        </button>
                         
                    </div>
                    <div class="close">
                        <save/>
                     </div>
                <!-- <p class="upp">
                  <strong>
                     Профиль проекта
                  </strong> 

                </p>
                <div class="krug2">
                        
                        <p>4</p> 
                 </div> -->
            </div>
            <div class="bottom">
                <!-- <div class="blockp1">
                    <div class="p1">
                        <strong>
                            Без названия
                        </strong>
                    </div>
                </div>  -->
              
               
            </div>
            </div>
    <div class="body"><br><br>
        <!-- <div class="heder2">
            <div class="bott">
                <div class="tegs">
                    <button class="btn tegbtb">
                        Все теги <strong>254 </strong>
                    </button>
                </div>
                <label class="checkbox-btn">
            <input type="checkbox" checked>
            <span>Прованс</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" checked>
            <span>Скульптуры</span>
            </label> 
            <label class="checkbox-btn">
            <input type="checkbox" checked>
            <span>Уютное</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" checked>
            <span>Прованс</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" checked>
            <span>Скульптуры</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" checked>
            <span>Уютное</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" checked>
            <span>Прованс</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" checked>
            <span>Скульптуры</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" checked>
            <span>Уютное</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" checked>
            <span>Прованс</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" checked>
            <span>Скульптуры</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" checked>
            <span>Уютное</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" checked>
            <span>Прованс</span>
            </label>
                                   
            
            </div>
        </div><br> -->
        <div class="content">
            <div class="left">
                <div class="imgcont">
                   
                    <img class="img4" src="./photos/1633908674_126-p-zagorodnie-doma-i-kottedzhi-foto-132.jpg">
                    
                    <label class="box-btn1">
                    <input type="checkbox" >
                  
                        <span>
                           <!--    <img class="luk" src="./ikons/ser3.png" > -->
                             Сохранить эскиз
                             </span> 
                    </label>
                    <!-- <label class="box-btn">
                    <input type="checkbox" >
                    <span><img class="luk" src="./ikons/ser3.png" > Сохранить как новый проект</span> 
                    </label> -->
                   
                    
                        

                </div>

                <!-- <div class="butpan">
                    <button class="btn bt3">
                       Обновить
                    </button>
                    <button class="btn bt310">
                        <img class="imgbt31" src="./ikons/strnzd.png">
                    </button>
                    <button class="btn bt31">
                       <img src="./ikons/strprd.png">
                    </button>
                    <a href="#shagfoor" >
                      <button class="btn bt23">
                       Далее
                    </button>
                    </a>
                </div> -->
            </div>
            <div class="right">
                <p>
                    <strong>
                        Растения
                    </strong>
                </p>
                <div class="chex">
                     <label class="checkbox-btn">
            <input type="checkbox" checked >
            <span>Прованс</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" checked >
            <span>Скульптуры</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" checked>
            <span>Прованс</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" checked>
            <span>Скульптуры</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" checked>
            <span>Уютное</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" checked>
            <span>Прованс</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" checked>
            <span>Скульптуры</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" checked>
            <span>Уютное</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" checked>
            <span>Прованс</span>
            </label><br><br>
            
            <!--  -->
            <div class="bot-box">
               <input type="searh" class="search" placeholder="Найти растение">
               <button class="btn">
                    <img src="./ikons/srcbtn.png">
                
               </button>
           <a class="plants">
            <strong>
                <u>
                       Посмотреть все растения
                </u>
              
            </strong>
           
           </a>  
            </div>
           
            
                </div><br>
               

            <p>
                    <strong>
                        Оттенки
                    </strong>
                </p>
                <div class="chex">
                    <label class="checkbox-btn">
            <input type="checkbox" checked >
            <span>Скульптуры</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" checked>
            <span>Прованс</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" checked>
            <span>Скульптуры</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" checked>
            <span>Уютное</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" checked>
            <span>Прованс</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" checked>
            <span>Скульптуры</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" checked>
            <span>Уютное</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" checked>
            <span>Прованс</span>
            </label>
                </div>
                <br><br>
                <p>
                    <strong>
                        Стили
                    </strong>
                </p>
                <div class="chex">
                    <label class="checkbox-btn">
            <input type="checkbox" checked >
            <span>Скульптуры</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" checked>
            <span>Прованс</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" checked>
            <span>Скульптуры</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" checked>
            <span>Уютное</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" checked>
            <span>Прованс</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" checked>
            <span>Скульптуры</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" checked>
            <span>Уютное</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" checked>
            <span>Прованс</span>
            </label>
                </div>
                <div class="butpan">
                    <button class="btn bt3">
                       Обновить
                    </button>
                    <button class="btn bt310">
                        <img class="imgbt31" src="./ikons/strnzd.png">
                    </button>
                    <button class="btn bt31">
                       <img src="./ikons/strprd.png">
                    </button>
                    <a href="#shagfoor" >
                      <button class="btn bt23">
                       Далее
                    </button>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import heder from "./components/hederTree.vue";
//import hederd from "./components/hederDuo.vue";
import save from "./components/SaveModal.vue"
export default{
    components:{
        //hederd,
        heder,
        save,
    }

}
</script>


<style scoped>
.close{
    width: 90%;
    text-align: right;
}
.bot-box{

}

.search{
    margin-left: 2%;
    width: 300px;
}

.plants{
    text-decoration: none;
    color:#000000;
    margin-left: 200px;
    
}
.p1{
    float: left;
    margin-left: 0%;
    font-size: 24px;
    text-align: center;
    width: 177px;
    height: 29px;
    font-weight: 400;
    line-height: 29px;
    display: flex;
}
.tegs{
    margin-left: 2%;
    float: left;
}
.tegbtb{
    padding: 15px;
    margin-top: -10px;
    border: 1px solid #000000;
    border-radius: 10px;
}
.upp{
    margin-left: 84%;
    margin-top: -1.4%;
    float: left;
}
.krug2{
    margin-top: -1.4%;
  
    margin-left: 1%;
    text-align: center;
    width: 30px;
    height: 30px;
    background-color: #707070;
    border-radius: 15px;
    float: left;
}
.krug2 p{
    margin-top: 10%;
    color: white;
}
.bt3{
    background-color: #D9D9D9;
    /* margin-left: 0%; */
    width: 300px;
    height: 54px;
    text-align: center;

}
.bt23{
  
    background-color: #D9D9D9;
    /* margin-left: 0%; */
    width: 300px;
    /* padding: 15px; */
    height: 54px;
    text-align: center;

}
.imgbt31{
    margin-left: -10%;
}
.bt31{
    display: flex;
    background-color: #D9D9D9;
    /* margin-left: 3%; */
    width: 52px;
height: 52px;
margin: 0;
/* text-align: center; */
}

.bt310{
    background-color: #D9D9D9;
    /* margin-left: 7%; */
    width: 52px;
height: 52px;
margin: 0;
/* text-align: center; */
}

@media screen and (max-width: 1800px) {
    .bt23{
  
  background-color: #D9D9D9;
  /* margin-left: 0%; */
  width: 150px;
  /* padding: 15px; */
  height: 54px;
  text-align: center;

}
.bt3{
    background-color: #D9D9D9;
    /* margin-left: 0%; */
    width: 150px;
    height: 54px;
    text-align: center;

}


}
@media screen and (max-width: 1800px) {



}
.butpan{
    display: flex;
    justify-content: center;
    margin-top: 4%;
    /* position: relative; */
    /* margin-top: 64%; */
}
.butpan button{
    /* padding: 0px 15px 0 15px; */
    margin-left: 1%;
}
.butpan a{
    text-decoration: none;
    color: black;
}
.imgbox{
 width: 100%;
}
.img3{
    width: 40%;
}
.img4{
    width: 98%;
    position: absolute;
}
.content{
    margin-left: 2%;
    margin-top: 1%;
    display: flex;
    justify-content: center;
    
}
.left{
    /* float: left; */
    width: 60%;
}
.imgcont{
    width: 100%;
    position: relative;
}
.right{
    /* float: left; */
    width: 40%;
    font-size: 18px;
}
.right p{
    font-size: 24px;
}
.imgtr{
   
}
.checkbox-btn {
	display: inline-block;
	margin-top: 1%;
    margin-left: 2%;
	user-select: none;
	position: relative;
    /* border-radius: 25px; */

}
.checkbox-btn input[type=checkbox] {
	z-index: -1;
	opacity: 0;
	display: block;
	width: 0;
	height: 0;
    
}
.box-btn input[type=checkbox] {
	z-index: -1;
	opacity: 0;
	display: block;
	width: 0;
	height: 0;
    
}
.box-btn1 input[type=checkbox] {
	z-index: -1;
	opacity: 0;
	display: block;
	width: 0;
	height: 0;
    margin-left: 2%;
    
}

.checkbox-btn span {
	display: inline-block;
	cursor: pointer;
	padding: 0px 10px;
	line-height: 30px;
	border: 1px solid #999;
	border-radius: 24px;
	transition: background 0.2s ease;
}
.luk{
    width: 26px;
}
.box-btn{

    /* position: relative; */
    color: white;
width: 126px;
height: 36px;
padding: 5px;
margin-top: 0px;
margin-left: 0px;
/* background: #707070; */
border-radius: 10px;
   z-index: 999;
   position: absolute;
}
.box-btn1{
     position: absolute;
     color: white;
    width: 126px;
    height: 56px;
    padding: 5px;
    margin-top: 2%;
    left: 70%;
    /* background: #707070; */
    border-radius: 10px;
    z-index: 999;
    position: relative;
    opacity: 50%;
    transition:  0.5s ease;
}
.box-btn1:hover{
    opacity: 100%;
    transition:  0.5s ease;
}
.box-btn span {
	display: inline-block;
    width: 400px;
	cursor: pointer;
    background: #707070;
	padding: 0px 10px;
	line-height: 30px;
	border: 1px solid #999;
	border-radius: 24px;
	transition: background 0.2s ease;
}
.box-btn1 span {
    /* position: relative; */
	display: inline-block;
    text-align: center;
    width: 200px;
	cursor: pointer;
	padding: 0px 10px;
    background: #707070;
	line-height: 50px;
	border: 1px solid #999;
	border-radius: 24px;
	transition: background 0.2s ease;
}

 
/* Checked */
.checkbox-btn input[type=checkbox]:checked + span {
	background: #8d8d8d;
    color: white;
}
.box-btn input[type=checkbox]:checked + span {
	background: #eb0000;
    color: white;
}
.box-btn1 input[type=checkbox]:checked + span {
	background: #eb0000;
    color: white;
}
.heder2{
    width: 100%;
}

.body{
    /* margin-top: 100px; */
    font-family: "Montserrat-Regular";
    
}

/* heder */
.heder{
    margin-left: 2%;
    margin-top: 0.5%;
    font-family: "Montserrat-Regular";
}
.p1{
    float: left;
    margin-left: 0%;
    font-size: 24px;
    text-align: center;
    width: 297px;
    height: 29px;
    font-weight: 400;
    line-height: 29px;
}
.blockp2{
    margin-left: 22%;
}
.blockp2 a{
    text-decoration: none;
    color: black;
}
.p2{
    float: left;
    margin-left: 1%;
    font-size: 16px;
    margin-top: 0.5%;
    text-align: center;
    width: 240px;
    height: 19px;
}
.up a{
    text-decoration: none;
    color: black;
}
.body{
    /* margin-top: 100px; */
    font-family: "Montserrat-Regular";
}
</style>
