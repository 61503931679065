<template>
  <div class="link">
    <a v-if="!accessToken" href="#onePage">К работе с нейросетями</a>
  <!-- <a href="#threePage">threePage</a> -->
  </div>
    <div class="div">
      
        <div class="content">
                    <button @click="picCreate">Создать папку</button>
                    <!-- <button>Изменить параметры</button>
                    <button>Добавить папку в корень </button>
                    <button>Удалить папку</button><br>
                                         -->
            
        </div>
        <div class="input">
            <input
            width="200px"           
            type="text"
            class="form-control"
            placeholder="descr"
            v-model="descr"
          >
          <input
          width="200px"
            type="text"
            class="form-control"
            placeholder="name"
            v-model="name"
          >
        </div>
        <table class="table">
              <thead>
                <tr>
                    <th></th>
                    <th scope="col">descr</th>
                    <th scope="col">id</th>
                    <th scope="col">name</th>
                    <th scope="col">root_id</th>
                </tr>
              </thead>
              <tbody>
                <tr
                 v-for:="options2 in neuronet" 
                 
                >
                  <input
                  class="form-check-input" 
                  type="radio" 
                  name="flexRadioDefault" 
                  id="flexRadioDefault1"
                  
                  />
                    <th scope="row" @click="neuroclick(options2.id)" >{{options2.descr}}</th>
                    <td>{{options2.id}}</td>
                    <td>{{options2.name}}</td>
                    <td>{{options2.root_id}}</td>
                </tr>
              </tbody>
           </table>

               
           
        </div>
    
   
    
    </template>
    
    <script>
    //import axios from "axios";
    import api from "../../JS/api.js"
    export default{
       
        data(){
            return{
                accessToken: null,
                neuronet: null,

            }
        },

        mounted(){
            this.getAccessToken()
            console.log(this)
            this.neuronetIN(this) 
        },

        updated(){
            this.getAccessToken()
        },

        methods:{
            
            getAccessToken() {
                this.accessToken = localStorage.getItem('accessToken')
            },

            neuronetIN(this2){  //picfolders
                const options2 = {
            method: 'GET',
            url: 'http://185.221.152.242:5500/api/picfolders',
            
          };
          
          api.request(options2).then(function (response) {
          
           console.log(this2)
            console.log(response.data);
              this2.neuronet = response.data;                           
          }) 
                  
          .catch(function (error) {
            console.error(error);
            console.log(options2)
          });
          
           },
           neuroclick( id){
          console.log('qwerty')
          console.log(id);
         
          this.$router.push( {path: 'picFolder', query: { 'folder': id }})
      
      },

        picCreate(){
           
                const options2 = {
            method: 'POST',
            url: 'http://185.221.152.242:5500/api/picfolders' ,
            data: {
                descr: this.descr, 
                name: this.name
            }
          };
          
          api.request(options2).then(function (response) {
          
         
            console.log(response.data);
            window.location.reload();                      
          }) 
                  
          .catch(function (error) {
            console.error(error);
            console.log(options2)
          });
        }
        }
    
    }
    
    </script>
    
    
    <style scoped>
     .link{
      margin-top: 1%;
      margin-left: 3%;
      
    }
    .input{
      margin-top: 1%;
      display: flex;
    }
        .div{
            padding: 5px;
            margin-left: 5%;
        }
    
        .content{
            margin-top: 5%;
            margin-left: 5%;
    
        }
        
        .table{
            width: 60%;
            margin-top: 3%;
        }

        .form-control{
            width: 60%;
        }
        
        .imguploader{
            padding: 5px;
            margin-top: 2%;
        }
    
    </style>