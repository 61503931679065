<template>
    <div class="body">
        <HederUno/>
        <tophead/>
        <div class="content">
            <div class="contenthed">
                <p>
                    Что может сделать наш сервис максимально полезным для Вас?
                </p>
                <label>
                    Напишите Ваш вопрос или пожелание. Мы обязательно ответим на Ваш вопрос в ближайшее время и постараемся учесть все пожелания!
                </label>
            </div><br>
            <div class="contentbody">
                <input type="text" placeholder="Напишите Ваше имя"><br>
                <input type="text" placeholder="Напишите Ваш email или телефон"><br><br><br>
                <label>Напишите Ваш вопрос или предложение</label>
                <textarea class="txt" placeholder="Напишите Ваш вопрос или предложение" >

                </textarea>
                <a>
                <button class="btn send">
                    Отправить
               </button>
              </a>
              <p class="sogl">
                *Нажимая "Отправить" Вы соглашаетесь с политикой обработки персональных данных
            </p>
            </div>
        </div>
        <boost/>
        <Footer/>
</div>
</template>

<script>
import HederUno from './hederUno.vue'
import Footer from './footerUno.vue'
import boost from './components/components/boostUno.vue'
import tophead from './components/components/topHead.vue'
export default{
    components:{
        HederUno,
        Footer,
         boost,
        tophead,
        
    },


}
</script>

<style scoped>


.sogl{
    font-size: 15px;
    margin-left: 10%;
    margin-top: 3%;
}
.send{
    margin-top: 5%;
    font-size: 24px;
    width: 40%;
    height: 60px;
    border-radius: 30px;
    margin-left: 31%;
    background-color: #a0b19a;
}
.txt{
    width: 100%;
    height: 350px;
    /* margin-top: 5%; */
    padding: 5px;
}
.contentbody input{
    width: 100%;
    height: 50px;
    margin-top: 5%;
    padding: 5px;
}
.contentbody{
    width: 70%;
    margin-left: 13%;
    
}
.contentbody label{
    font-size: 24px ;
}
.contenthed label{
    font-size: 24px ;
}

.content{
    width: 60%;
    margin-left: 20%;
    text-align: left;
    margin-top: 4%;
}
.contenthed p{
   font-family: 'Montserrat-Bold';
    font-size: 36px ;
}
.body{
    font-family: 'Montserrat-Regular';
}
</style>
