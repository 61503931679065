<template>
    <div class="body">
        <div id="hed" class="heder">
            <a href="#landing">
            <div class="logo">
                A I . G A R D E N
            </div>
            </a>
            <div class="panel">
                <a href="#aboutUs#hed">
                <button class="btn buton">
                    О нас
                </button>
                </a>
                <a  href="#tarifsCosts#hed">
                <button class="btn buton">
                    Тарифы и цены
                </button>
                </a>
                <a  href="#forPartners#hed">
                <button class="btn buton">
                    Партнёрам
                </button>
                    </a>
            </div>
             <div class="rightbutton">
                        <a href="https://dev.desaigners.com/authentication">
                        <button class="btn buton2">
                           Вход/Регистрация
                        </button>
                        </a>
                    </div> 

                <!-- 
                    ==============================
                 -->
                 <!-- <input class="menu-btn" type="checkbox" id="menu-btn" name="menu-btn" />
                <label class="menu-icon" for="menu-btn">
                    <span class="navicon" aria-label="Hamburger menu 'icon'" />
                </label>
                <nav class="menu">
                    <a href="#aboutUs">
                <button class="btn buton">
                    О нас
                </button>
                </a>
                <a  href="#tarifsCosts">
                <button class="btn buton">
                    Тарифы и цены
                </button>
                </a>
                <a  href="#forPartners">
                <button class="btn buton">
                    Партнёрам
                </button>
                </a>
                 
                </nav> -->
              
        </div>
    </div>
  
</template>

<script>
    export default{

    }
</script>

<style scoped>
 .menu a {
    /* padding: 20px 20px; */
    /* border-right: 1px solid #f4f4f4; */
    /* background-color: #eee; */
    width: 300px;
    text-decoration: none;
    text-align: center;
    margin-left: 50%;
    margin-top:-10%
  }
    .body{
   
      z-index: 4444444444444444;
        height: 100px;
        position: absolute;
        top: 0;
        width: 100%;
        background-color: black;
        padding: 5px;
        position: fixed ;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    filter: alpha(opacity=80);
    -moz-opacity: 0.80;
    -khtml-opacity: 0.8;
    opacity: 0.8;
        /* opacity:0; */
     /* transition: 1s; /*Скорость перехода состояния элемента*/
 /*animation: show 3s 1; /* Указываем название анимации, её время и количество повторов*/
 /*animation-fill-mode: forwards; /* Чтобы элемент оставался в конечном состоянии анимации */
 /*animation-delay: 1s; Задержка перед началом */
}
.body::after{
  opacity: 50%;
}
@keyframes show{
0%{
opacity:0;
}
100% {
opacity:1;
}
    }
    .heder{
        margin-top: 0.5%;
        font-family: "Montserrat-Regular";
        display: flex;
        justify-content: center;
        text-align: center;

    }
    
    .logo{
        margin-left: 2%;
        position: absolute;
        margin-top: 0.5%;
        left: 2%;
        font-size: 35px;
        font-family: "Montserrat-Regular";
        color: aliceblue;
    }
    .panel{
        position: absolute;
        /* margin-left: 10%; */
        width: 40%;
        margin-top: 0.5%;

    }
    .buton{
        color: aliceblue;
        font-size: 24px;
        margin-left: 2%;
        /* width: 300px; */
        font-family: "Montserrat-Regular";
    }
    .rightbutton{
        position: absolute;
        right: 5%;
        color: aliceblue;
    }
    .buton2{
        width: 270px;
    height: 70px;
        background-color: aliceblue;
        color: rgb(0, 0, 0);
        font-size: 24px;
        margin-left: 2%;
        border-radius: 45px;
        font-family: "Montserrat-Bold";
    }
    /* width: 250px;
    height: 80px;
    border-radius: 45px;
    background-color: black;
    color: aliceblue;
    margin-left: 0%;
    font-size: 24px;
    margin-top: 5%;
    font-family: 'Montserrat-Bold'; */

   
    header a {
  text-decoration: none;
}

/* "Hide" checkbox -- moves it off screen*/
#menu-btn {
  position: absolute;
  top: -100%;
  left: -100%;
}
/* Hide hamburger for bigger screens */
.menu-icon {
  visibility: hidden;
}
.menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 250px;
  margin-right: 2rem;
}
/* Set width for mobile/smaller screen size. */
/* I set it big here so I don't have to shrink the screen so much */
/* for testing purposes */
@media screen and (max-width: 1100px) {
  header {
    display: grid;
    grid-template-areas:
      "title title hamburger"
      "nav nav nav";
  }
  h1 {
    grid-area: title;
  }
  .menu a {
    text-decoration: none;
    color: black;
  }
  .menu-btn {
    display: none;
  }
  .menu-icon {
    grid-area: hamburger;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    padding: 30px 20px 30px 0;
    position: relative;
    user-select: none;
    visibility: visible;
  }
  .navicon {
    background: #333;
    display: block;
    height: 2px;
    width: 18px;
    position: relative;
  }
  .navicon:before {
    top: 5px;
  }
  .navicon:after {
    top: -5px;
  }
  .navicon:before,
  .navicon:after {
    background: #333;
    display: block;
    width: 100%;
    height: 100%;
    content: "";
    position: absolute;
    transition: all 0.2s ease-out;
  }
  .menu {
    grid-area: nav;
    max-width: unset;
    max-height: 0;
    transition: max-height 0.2s ease-out;
    overflow: hidden;
    margin: 0;
    padding: 0;
    background-color: #fff;
    display: flex;
    flex-direction: column;
  }
  .menu a {
    padding: 20px 20px;
    border-right: 1px solid #f4f4f4;
    background-color: #eee;
    width: 100%;
    text-align: center;
  }
  .menu-btn:checked ~ .menu {
    max-height: 240px;
  }
  .menu-btn:checked ~ .menu-icon .navicon {
    background: transparent;
  }
  .menu-btn:checked ~ .menu-icon .navicon:before {
    transform: rotate(-45deg);
  }
  .menu-btn:checked ~ .menu-icon .navicon:after {
    transform: rotate(45deg);
  }
  .menu-btn:checked ~ .menu-icon .navicon:before,
  .menu-btn:checked ~ .menu-icon .navicon:after {
    top: 0;
  }
}
</style>
