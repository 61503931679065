<template>
    <div class="body">
         <div class="bbtn">

              
                <button v-on:click="openmodal" id="open-modal-btn" class="btn brd3">
                   Войти
                </button>
                <!--  -->
             
                

        <!-- <button >Открыть модальное окно</button> -->
    </div>

    <div class="modal" id="my-modal">
        <div class="modal__box">
            <button class="modal__close-btn" id="close-my-modal-btn">
                <svg width="23" height="25" viewBox="0 0 23 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.09082 0.03125L22.9999 22.0294L20.909 24.2292L-8.73579e-05 2.23106L2.09082 0.03125Z"
                        fill="#333333" />
                    <path d="M0 22.0295L20.9091 0.0314368L23 2.23125L2.09091 24.2294L0 22.0295Z" fill="#333333" />
                </svg>
            </button>

            <div class="body2">
          
          <div class="content">

              <div class="contentbox">

                  <div class="contentleft">

                      <div v-show="step===1" class="left">
                          <p>
                              Зарегистрируйтесь чтобы начать работу 
                          </p>
                          <label
                           class="label0"
                            for=""
                           
                            >
                              Ваш E-mail

                          </label>
                          
                          <br>
                          <input 

                           v-model = "email"  
                           
                           type="text">
                           <br>
                           <label
                           class="label0"
                            for=""
                           
                            >
                              Ваш пароль

                          </label>
                          
                          <br>
                          <input 

                           v-model = "pwd"  
                           
                           type="password">
                           <br>
                          <!-- <a href="#authd"> -->
                          <button @click="auth1" class="btn button1">
                              Войти
                          </button>
                              <!-- </a> -->
                          <div class="pbox">
                                  <div class="poloska1"></div>
                              <label class="label1">
                                  или
                              </label>
                                                          
                                  <div class="poloska2"></div>
                          </div>
                           <button @click="auth1" class="btn button1">
                              Зарегестрироваться
                          </button>   
                          <!-- <label class="label2">
                              Упрощенная регистрация через:
                          </label> -->


                          <!-- <div class="rotbox">
                              <button class="btn buttonR">
                                  <img class="img1" src="./ik/Vk.png">
                              </button>
                               <button class="btn buttonR">
                                  <img class="img1" src="./ik/icons8-google-30.png">
                              </button>
                               <button class="btn buttonR">
                                  <img class="img1" src="./ik/icons8-yandex-67.png">
                              </button>
                            
                          </div> -->
                          <label class="label3">
                              Нажимая кнопку “Зарегистрироваться” Вы соглашаететсь с политикой конфиденциальности
                          </label>
                      </div>
                      <!-- <div v-show="step===2"  class="left">
                            <p>
                                Зарегистрируйтесь чтобы начать работу 
                            </p>
                            <label class="label0" for="">
                                Введите  код из смс или из почты
                            </label><br>
                            <input

                             v-model="pin" 
                             type="text"
                             
                             >
                             <br>
                           <a href="#tips"> 
                            <button @click="auth2" class="btn button1">
                                Зарегистрироваться
                            </button>
                           </a> 
                            <div class="pbox">
                                    <div class="poloska1"></div>
                                <label class="label1">
                                    или
                                </label>                                
                                    <div class="poloska2"></div>
                            </div>
                            <label class="label2">
                                Упрощенная регистрация через:
                            </label>
                            <div class="rotbox">
                              <button class="btn buttonR">
                                  <img class="img1" src="./ik/Vk.png">
                              </button>
                               <button class="btn buttonR">
                                  <img class="img1" src="./ik/icons8-google-30.png">
                              </button>
                               <button class="btn buttonR">
                                  <img class="img1" src="./ik/icons8-yandex-67.png">
                              </button>
                            
                          </div>
                            <label class="label3">
                                Нажимая кнопку “Зарегистрироваться” Вы соглашаететсь с политикой конфиденциальности
                            </label>
                        </div> -->
                        <div v-show="step===2"  class="left">
                            <tips/>
                        </div>

                  </div>
                 
                  <!-- <div class="contentright">

                      <div class="right">

                          <p>
                              Или ознакомьтесь с функционалом в сопровождении виртуального помощника
                          </p>
                          <img class="img2" src="./ik/robot1.png"><br>
                          <button class="btn button4">
                              Поехали!
                          </button>
                      </div>

                  </div> -->

              </div>

          </div>
  </div>
        </div>


                <!--  -->
               
               
             </div>
    </div>
</template>

<script>
import axios from "axios"
// import api from  "../../../JS/api.js"  
import tips from "./tipPolzov.vue"
export default{
    components:{
      //  heder
      tips
        },
    data(){
            return{
                step:1,
                id:null
            }
        },
        methods :{

            nextStep(){
                this.step++
            },

            openmodal(){
                        // Открыть модальное окно
           if ( document.getElementById("open-modal-btn") != null){
                document.getElementById("my-modal").classList.add("open")
            }

            // Закрыть модальное окно
            document.getElementById("close-my-modal-btn").addEventListener("click", function() {
                document.getElementById("my-modal").classList.remove("open")
            })

            // Закрыть модальное окно при нажатии на Esc
            window.addEventListener('keydown', (e) => {
                if (e.key === "Escape") {
                    document.getElementById("my-modal").classList.remove("open")
                }
            });

            // Закрыть модальное окно при клике вне его
            document.querySelector("#my-modal .modal__box").addEventListener('click', event => {
                event._isClickWithInModal = true;
            });
            document.getElementById("my-modal").addEventListener('click', event => {
                if (event._isClickWithInModal) return;
                event.currentTarget.classList.remove('open');
            });
            },

            auth1(){
                
                // const options = {

        //         method: 'POST',
        //         url: 'http://185.221.153.253:5700/api/user/pinlogin',
        //         headers: {'Content-Type': 'application/json'},
        //         // headers: {
        //         //     Authorization: 'Bearer 0f1bc8ef-c67e-462a-8b70-03a8b6a70aa0'},
        //         data: {
        //             pin: this.email
        //          //   username
                   
        //         };
        //         console.log(this.pin)
        //         axios.request(options).then(function (response) {
        //         console.log(response.data);
        //   localStorage.setItem('access_token', response.data.access_token)
        // //   localStorage.setItem('username', response.data.username)
        //   console.log("qwe");
        // //   let name = response
        //   console.log(name.email);

        //         }).catch(function (error) {
        //         console.error(error);
        //         });
                // import axios from "axios";

              let email= "email="+this.email 
                  let pwd= "pwd="+this.pwd
                const options = {
                method: 'POST',
                // url: 'http://185.221.153.253:8000/api/aigarden/auth/simple/email?'+email&pwd,
               
                url: 'http://185.221.153.253:8000/api/aigarden/auth/simple/email',
                params: {email:email, pwd: pwd}

                // data: {
                //    provider: "email",
                //      email: this.email, 
                //      pwd: this.pwd}
                };
                console.log("url");
                console.log(options.url);
                console.log(options["url"]);
                axios.request(options).then(function (response) {
                    localStorage.setItem('access_token', response.data.JWT)
                    console.log("response");
                    console.log(response.data);               
                    console.log("response");
                    console.log(response.data.JWT);  
                // console.log(response.data);

                }).catch(function (error) {
                console.error(error);
                });
                 this.step++
            },

            auth2(){
                this.step++
                const options2 = {
                method: 'POST',
                url: 'http://185.221.153.253:5700/api/user/pinactivate',
                // headers: {Authorization: 'Bearer 0f1bc8ef-c67e-462a-8b70-03a8b6a70aa0'},
                data: {
                    pin: this.pin,
                    token: localStorage.getItem('access_token')}
                };

                axios.request(options2).then(function (response) {
                console.log(response.data);
                // localStorage.setItem('access_token', response.data.access_token)
                }).catch(function (error) {
                console.error(error);
                });
            },

            


                    }
}
</script>

<style scoped>
.bbtn{
    /* font-size: 24px; */
    font-family: "Montserrat-Regular";
    margin-top: -3%;
    height: 30px;
}

.brd3{
    font-size: 24px;
    height: 40px;
}
.button4{
    width: 80%;
    height: 50px;
    /* background-color: #D9D9D9; */
    margin-top: 7%;
    background-color: #97c004;
}
.img2{
    margin-top: 7%;
    width: 40%;
    /* margin-left: 8%; */
    text-align: center;
}
.right{
    width: 85%;
    margin-left: 5%;
    text-align: center;
    /* margin-top: 20%; */
}

.contentright p{
    margin-top: 20%;
    font-size: 30px;
    color: white;
    text-align: center;
}
.label3{
    margin-top: 4%;
    width: 80%;
    font-size: 12px;
}
.img1{
    width: 140%;
    margin-left: -18%;
}

.buttonR{
    border-radius: 40%;
    background-color: white;
    width: 40px;
    margin-left: 2%;
    margin-top: 4%;
}
.rotand{
    border: 1px solid #000000;
    width: 20%;
    margin-left: 2%;
    margin-top: 2%;
    border-radius: 50px;
    rotate: 50%;
}
.label2{
    margin-top: 4%;
    margin-left: 5%;
    font-size: 20px;
}
.pbox{
    display: flex;
    width: 80%;
    margin-left: 10%;
    margin-top: 4%;
}
.label1{
    margin-left: 5%;
    font-size: 20px;
}

.poloska1{
    border: 1px solid #000000;
    width: 40%;
    margin-top: 4%;
    height: 1px;
    
}
.poloska2{
    border: 1px solid #000000;
    width: 40%;
    margin-top: 4%;
    height: 1px;
    margin-left: 5%;
}
.left{
    width: 85%;
    margin-left: 7.5%;
}
.contentleft{
    width: 90%;
    height: 100%;
    background-color: white;
    float: left;
    
}
.contentleft p{
    margin-top: 20%;
    font-size: 30px;
}
.label0{
    margin-top: 4%;
    font-size: 12px;
}
.contentleft input{
    margin-top: 2%;
    width: 80%;
    height: 50px;
}
.button1{
    margin-top: 4%;
    width: 80%;
    height: 50px;
    background-color: #97c004;
}
.contentright{
    width: 50%;
    height: 100%;
    float: left;
    background-color: #404040;
} 
.contentbox{
    width: 90%;
    margin-left: 0%;
    margin-top: 0%;
    height: 700px;
    /* border: 1px;
    border-color: black; */
    position: fixed;
    /* border: 1px solid #000000; */
    text-align: center;
}
.body2{
    font-family: "Montserrat-Regular";
    background-color: rgb(255, 255, 255);
    height: 700px;
    width: 90%;
    color: black;
}

/*  */

html {
    box-sizing: border-box;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

a {
    color: black;
    text-decoration: none;
}

img {
    max-width: 100%;

}

body {
    font-family: Arial, Verdana, sans-serif;
}

.container {
    max-width: 600px;
    margin: 0 auto;
    padding: 0 15px;
}

.modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: 99998;
    /* background-color: rgba(0, 0, 0, .3); */
    display: grid;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
    visibility: hidden;
    /* opacity: 0; */
    transition: opacity .4s, visibility .4s;
}

.modal__box {
    position: relative;
   width: 750px;
    padding: 45px;
    z-index: 1;
    /* margin: 30px 15px; */
    /* background-color: rgb(211, 211, 211); */
    /* box-shadow: 0px 0px 17px -7px rgba(34, 60, 80, 0.2); */
    transform: scale(0);
    transition: transform .8s;
    height: 745px;
}

.modal__close-btn {
    position: absolute;
    top: 8px;
    right: 8px;
    border: none;
    background-color: transparent;
    padding: 5px;
    cursor: pointer;
}

.modal__close-btn svg path {
    transition: fill .4s;
}

.modal__close-btn:hover svg path {
    fill: blue;
}

.modal__close-btn:active svg path {
    fill: rgb(186, 53, 0);
}

.modal.open {
    visibility: visible;
    opacity: 1;
}

.modal.open .modal__box {
    transform: scale(1);
}
</style>
