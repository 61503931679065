
<template>
    <div class="content-box">
        <div class="panel">
            <button>Uptate</button>
            <button>Delete</button>
        </div>
        <div class="imgloader">
            <input
            name="image"
            type="file" 
            accept="image/*" 
            @change="picload" 
            id="file-input"
            v-on="file"
            multiple />
            </div>
        <!-- <button @click="imgReturn">qwerty</button> -->
        <div class="content">
            <table class="table">
              <thead>
                <tr>
                    <th>  
                      filename          
                  </th>
                  <th>  
                      id          
                  </th>
                  <th>  
                    pf_id          
                  </th>
                  <th>  
                     picname          
                  </th>
                    
                </tr>
              </thead>
              <tbody>
                <tr
                 
                >

                    <!-- <th scope="row" >{{neuronet.filename}}</th>
                    <td>{{neuronet.id}}</td>
                    <td>{{neuronet.pf_id}}</td>
                    <td>{{neuronet.picname}}</td> -->
                    <td scope="row"  >{{(neuronet)? neuronet.filename : ''}}</td>
                    <td>{{(neuronet)? neuronet.id : ''}}</td>
                    <td>{{(neuronet)? neuronet.pf_id : ''}}</td>
                    <td>{{(neuronet)? neuronet.picname : ''}}</td>
                  
                </tr>
              </tbody>
           </table>

        </div>
    </div>
</template>

<script>
import api from "../../JS/api.js"

export default{

    data(){
        return{
            neuronet: null,
        }
    },

    mounted(){
       this.neuronetIN(this)
       this.imgReturn(this)
    },

    methods:{

        neuronetIN(this2){
          let result = this.$route.query.description
          console.log('Hello world');

          const options2 = {
          method: 'GET',
          url: 'http://185.221.152.242:5500/api/pic/' + result,

          };

          api.request(options2).then(function (response) {


          localStorage.setItem('id', response.data.id)
          console.log(this2)
          console.log(response.data);
          this2.neuronet = response.data;


          }) 
            
          .catch(function (error) {
          console.error(error);
          console.log(options2)
          });

          },

          picload(){

            let result = this.$route.query.description
           // let id = this.id
            const URL = 'http://185.221.152.242:5500/api/picload/' + result;
            let data = new FormData();

            data.append('image', event.target.files[0]); 

            let config = {
            header : {
                'Content-Type' : 'multipart/form-data'
            }
            }
            //  console.log(event.target.files, 'qwerty')
            api.post(
            URL, 
            data,
            config
            ).then(
            response => {
                console.log('image upload response > ', response)
               
            }
            )


            },
            
            
          imgReturn(){
            let result = this.$route.query.picfolder
            const options = {
            method: 'GET',
            url: 'http://185.221.152.242:5500/api/picload/'+ result,
            
            
          };

          api.request(options).then(function (response) {
            console.log(response.data);
          }).catch(function (error) {
            console.error(error);
          });
          }
       
    }


}

</script>

<style scoped>
.panel{
    margin-top: 2%;
    margin-left: 3%;
}
.imgloader{
    margin-top: 2%;
    margin-left: 3%;
}
.content{
    margin-top: 2%;
    margin-left: 3%;
}
.content-box{
    margin-top: 2%;
    margin-left: 3%;
}

</style>