<template>
  <div class="body">
   <swiper
  :modules="modules"
  :slides-per-view="1"
  :space-between="50"
  navigation
 

  @swiper="onSwiper"
  @slideChange="onSlideChange"
>
  <swiper-slide>
   <div class="doc">
        <img class="docimg" src="./../../assets/docs/patent.jpg" alt="img">

   </div>


   
  </swiper-slide>

  <swiper-slide>
    <div class="doc"> 
    <img class="docimg" src="./../../assets/docs/СВ_2023668373_page-0001.jpg" alt="img">
      </div>

  </swiper-slide>
  <swiper-slide>
    <div class="doc">
    <img class="docimg" src="./../../assets/docs/СВ_2023668441_page-0001.jpg" alt="img">
      </div>
  </swiper-slide>


</swiper>
</div>
</template>

<script>
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
  export default{
      components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
      
    };
    const onSlideChange = () => {
      console.log('slide change');
    };
    return {
      onSwiper,
      onSlideChange,
      modules: [Navigation, Pagination, Scrollbar, A11y],
    };
  },
  }
</script>

<style scoped>

.body{
  margin-top: 3%;
}

.doc{
  display: flex;
  justify-content: center;
}
.docimg{
  padding: 25px;
 width: 35%;
}
 .comlb{
  margin-top: 0px;
  font-size: 24px;
 }
.image-slider .swiper-button-next::after{
  color: #000;
 }
 .swiper-button-next:after, .swiper-container-rtl .swiper-button-prev:after {
content: '000';
}

.swiper-button-prev:after, .swiper-container-rtl .swiper-button-prev:after {
content: '';
color: #000;
}
.Navigation{
content: '';
color: #000;
}

</style>