<template>
    <div class="body">
        
            <HederUno/>
       
        
        <tophead/>
        <div class="slide">
            
        <!-- <div class="slide1" >
          <img class="slide1" src="./assets/backGardenOne.jpg"> -->
                       <!-- <img class="logoimg" src="./assets/Botany.jpg"> 

            <div class="hedtext">
                <h1 class="hedertext1">A I . G A R D E N</h1>
              
                <p class="hedertext2">Воплощай мечты в реальность</p>

               <button class="buton">Легко!</button>
            </div>
           </div>  -->
        </div>
        
<!--  -->
        <div class="slide2"><br><br>
            <div class="zz">
                <div class="zack"></div>
                <div class="zick"></div>
                <div class="zack"></div>
                <div class="zick"></div>
                <div class="zack"></div>
                <div class="zick"></div>
                <div class="zack"></div>
                <div class="zick"></div>
                <div class="zack"></div>
                <div class="zick"></div>
                <div class="zack"></div>
                <div class="zick"></div>
                
            </div>
            <div class="txt2">
                <label>
                    Создавай авторский дизайн легко и быстро,<br>
получай экспертную поддержку на всех этапах, автоматизируй рутину и получай удовольствие от настоящего творчества! 
                </label>
            </div>
            <div class="zzd">
                <div class="click"></div>
                <div class="clack"></div>
                <div class="click"></div>
                <div class="clack"></div>
                <div class="click"></div>
                <div class="clack"></div>
                <div class="click"></div>
                <div class="clack"></div>
                <div class="click"></div>
                <div class="clack"></div>
                <div class="click"></div>
                <div class="clack"></div>
                
                
            </div>
             <!-- <p>  Создавай авторский дизайн легко и быстро,</p> 
             <p>
        получай экспертную поддержку на всех этапах, автоматизируй рутину 
    </p>
        <p> и получай удовольствие от настоящего творчества</p> -->

            

        </div>
        <div class="slide3">
            <div class="box1">

                <div class="card-body">
                    <br>
                    <img class="img card-img-top"  src="./assets/free-icon-beet.png">

 
                    <h5 class="card-title">
                        <p> 
                              Собственникам
                        </p>                      
                        </h5>
    <label class="card-text"> 
          Создай стильный дизайн
           участка, исключи дорогостоящие
            ошибки и закажи материалы 
            по самым выгодным ценам        
        </label>
               
              <div class="top1">
                       
          
                       <a  href="#forLendlords"   class="btn btn-primary2">
                           Узнать больше
                       </a>
                       </div>
    </div>
               
            </div>

            <!--  -->
            <div class="box2">
     <div class="card-body"><br>           
  <img  src="./assets/free-icon-chard-8049469.png" class="img card-img-top" alt="...">
  
    <h5 class="card-title">
        <p>
        Дизайнерам
        </p>
    </h5>
    <label class="card-text">
        Передавай сложную рутину  AI, точно определяй потребности, ускоряй работу над проектом и легко подбирай материалы
        <br>
                
                </label>
               
                 <div class="top2">
                       
          
                    <a href="#designers" class="btn btn-primary2">
                        Узнать больше
                    </a>
                </div>
                </div>
  
            </div>

            <div class="box2">
     <div class="card-body"><br>           
              <img  src="./assets/free-icon-plants-8049480.png" class="img card-img-top" alt="...">
  
    <h5 class="card-title"

    >
        <p>
    Питомникам
    </p>
        </h5>
    <label class="card-text">
       Привлекай больше заказов
        онлайн, ускоряй сделки, 
        снижай расходы, повышай 
        лояльность клиентов
        <br>
                
                </label>
               
                 <div class="top2">
                        
          
                    <a href="#" class="btn btn-primary2">
                        Узнать больше
                    </a>
                </div>
    </div>
  
            </div>
            
            </div>

        </div>
        <!--  -->
        <div class="slide4">
            <div class="heder">
                <h1>
                    <p class="how">
                    Как это работает?
                    </p>

                </h1>
<br>
            </div>

            <div class="kards">
<!--  --> 
                <div class="kards1">
                   
                    <div class="kard1">

                        <div class="img2">
                            <img  class="img1" src="./assets/kard11.jpg">
                        </div>

                        <div class="text1">
                            <h1>
                                <p>
                                Определение предпочтений
                                </p>
                            </h1>
                            <label class="txt11">
                            Нейросеть автоматически определяет 
                                предпочтения и потребности пользователя 
                                на основе его профиля и выбора 
                                понравившихся изображений
                            </label>    
                            
                        </div>

                    </div>

               
                   
                   <div class="kard5">

                       <div class="img2">
                           <img  class="img1" src="./assets/kard3.jpg">
                       </div>

                       <div class="text1">
                           <p>Создание документации</p>
                           <label class="txt11">
                           Система генерирует необходимую 
                           проектную документацию,
                            а также подбирает конкретные
                            растения исходя из композиции
                            и условий местности
                           </label>
                       </div>

                   </div>

               </div>
<!--  -->
                <div class="kards2">

                    <div class="rotand">
                        <label>
                           <h2>1</h2>   
                        </label>
                     
                    </div>

                    <div class="palocka">

                    </div>

                    <div class="rotand">
                        <label>
                           <h2>2</h2>   
                        </label>  
                    </div>

                    <div class="palocka">

                    </div>

                    <div class="rotand">
                        <label>
                           <h2>3</h2>   
                        </label>
                    </div>
                    <div class="palocka">

                    </div>
                    <div class="rotand">
                        <label>
                           <h2>4</h2>   
                        </label>
                    </div>

                </div>
<!--  -->
                <div class="kards3">

                    <div class="kard2">

                        <div class="img2">
                            <img  class="img1" src="./assets/tild6432.jpg">
                        </div>

                        <div class="text1" >
                            <p>Генерация изображений</p>
                            <label class="txt11">
                            На основе предпочтений пользователя 
                           генерируются фотореалистичные 
                           изображения композиций,
                            зон и план участка
                            </label>
                        </div>

                    </div>
                    <div class="kard4">

                        <div class="img2">
                            <img  class="img1" src="./assets/kard4.jpg">
                        </div>

                        <div class="text1" >
                            <p>Быстрый заказ</p>
                            <label class="txt11">
                            По сделанной документации
                             пользователь может получить 
                             информацию о поставщиках 
                             материалов и услуг, 
                             сравнить предложения
                             и цены и в два клика сделать
                              заказ у одного или 
                              нескольких поставщиков
                            </label>
                        </div>

                        </div>

                </div>
<!--  -->
            </div>

        </div>
        <!--  -->
        <div class="slide5">
            <div class="slide5body">
                <acordeon/>
               <!--  -->
                        <!--  -->
            </div>

        </div>

        <div class="slide6">
<div class="slide6panel">
                 <!-- <a href="#enterTeam">

                <button class="btn buton5"> 
                    
                    <a  href="#enterTeamDuo">
                          Я с вами
                    </a>
                 
                </button>

                </a> -->
                 <a class="buton5" href="#enterTeam">

                    <label>
                            <div class="lbtext">
                                <div class="qazwsx">
                                       Я с вами
                                </div>
                               
                            </div>
                           
                        </label>

                </a>
                <!-- <button class="btn buton6">
                    <a href="#forPartners">
                         Я подумаю
                    </a>
                  
                </button> -->
                     <a class="buton6" href="#forPartners">
                        <label>
                            <div class="lbtext">
                                  Я подумаю
                            </div>
                           
                        </label>
                       
                    </a>
            </div>
            

            <div class="dualblock">
                
                <div class="lefttext">
                    
                   <p> 
                    <strong> О нас</strong>

                   </p>
<br>
                    <h3><label class="label1">
                        Наша миссия - стать 
                        медиатором между 
                        творчеством людей и
                         интеллектом машин, 
                         создавая новый ракурс
                          видения на привычные 
                          вещи
                    </label></h3><br>
                    <label class="label2">
                        Мы мечтаем открывать новые 
                        возможности творчества для 
                        дизайнеров и простых пользователей 
                        при помощи технологий. Мы знаем, 
                        что каждый человек уникален и 
                        обладает неограниченным творческим
                         потенциалом. И мы верим, 
                         что новые технологии способны
                          раскрыть эти возможности в каждом из нас.
                    </label>

                </div>
                    <img class="img imageright" src="./assets/Kviz/12.jpg" alt="">

               

            </div>

        </div>
        <div class="slide7">

            <div class="command">
                <div class="teamhed">
                   <h1> <p class="teamhedp"> Команда проекта</p></h1>
                </div>
               
                 <slider/>
                   <sliderdoc/>
                 </div>
                 </div>         
        
        <div class="slide11">
            <Footer/>
            
        </div> 
        <!--  -->
       

    
</template>


<script>
// import sliderduo from './components/slider/sliderDuo.vue'
import slider from './components/slider/sliderSwiper.vue'
import sliderdoc from './components/slider/sliderCom.vue'
import tophead from './components/components/topHead.vue'
// import opros from "./components/oprosUno.vue"
import acordeon from "./components/acordeonUno.vue"
import HederUno from './hederUno.vue'
import Footer from './footerUno.vue'
import axios from "axios";

export default{
    components:{
        HederUno,
        Footer,
        acordeon,
        tophead,
        slider,
        sliderdoc,
        // sliderduo,
        // opros,
    },
    data(){
            return{
                step:1,
                id:null
            }
        },
        methods:{
            nextStep(){
                this.step++
            },
            backStep(){
                this.step--
            },
            
            OneStep(){
                this.step=1
            },
            ErrorStep(){
                this.step=7
            },
            tableid(){
                var  id=1
                console.log(id)
            },
            neuroclick( id){
        //  console.log('qwerty')
          console.log(id);
          //this.$router.push({path: this.$route.fullPath + '/'+'neuroNet' + '/' +id })
         // this.$router.push( {path: 'neuroLayer', query: { 'layer': id }})
         },
         kviz(){
            const options = {
                method: 'POST',
                url: 'https://api.gbif.org/v1/dataset',
                headers: {
                    'Content-Type': 'application/json',
                
                },
                data: {
                    img1: 'img',
                    img2: 'img',
                    img3: 'img',
                    img4: 'img',
                    email: 'email',
                    number: 'number',
                    name: 'name'
                }
                };

                axios.request(options).then(function (response) {
                console.log(response.data);
                }).catch(function (error) {
                console.error(error);
                });
         }
            
        }

}
</script>

<style scoped>
    
    .polot{
        margin-top: 10%;
    }
    .zelenayapoloska{
        width: 350px;
        height: 20px;
        background-color: #a0b19a;
        position: absolute;
       
        display: flex;
    }
    .serayapoloska{
        width: 400px;
        height: 10px;
        background-color: #b3b3b3;
        position: absolute;
         margin-top: 5px;
    }
    .carouselkard{
        width: 40%;
        height: 700px;
        display: block;
        margin-top: 5%;
    }
    .leftitem{
        width: 50%;
        position: absolute;
        left: 15%;
    }
    .rightitem{
        width: 30%;
        font-family: "Montserrat-Regular";
        float: left;
        position: absolute;
        right: 15%;
    }
   
    .table{
        max-width: 95%;
        height: auto;
    }
    .tr{
        position: relative;
        max-width: 95%;
        min-width: 40%;
        display: flex;        
        float: left;
    }
    .body{
        /* width: 1850px; */
        font-family: "Montserrat-Regular";
    }
    @media (max-width: 1280px) {
        .body{
        max-width: 1280px;

    }
    }
   .slide{
    top:10%;
    min-width: 100%;
    margin-top: 4%;
    background-image: url('./assets/Botany.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
   }
   @media (max-width:1280px){
        .slide{
            /* max-width: 900px; */
            width: auto;
        }
        .slide3{
            display: block;
        }
}
.logoimg{
    z-index: 4;
    margin-left: 0;
    position: relative;
}
    .slide1{
        display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
        margin-top: 0%;
        /* margin-left: 7%; */
        z-index: 7;
        width: 90%;
        height: 900px;
        max-height: 900px;
        /* background-image: url('./assets/Botany.jpg'); */
       
    }

     .hedtext{
        z-index: 8;
        position: absolute;
        /* margin-left:33%; */
        /* margin-top: 19%; */
        width: auto;
        /* max-width: 100%;
       margin-left: 16.5%;   */
       text-align: center;
       
    }
    
   .hedertext1{
    font-size: 120px;
    font-family: "Montserrat-Bold";
    text-align: center;
    
   }
   .hedertext2{
    margin-top: 3%;
    font-size: 48px;
    margin-left: 0%;
       font-family: "OlgaCTTw";
   }
   .buton{
    width: 250px;
    height: 80px;
    border-radius: 45px;
    background-color: black;
    color: aliceblue;
    margin-left: 0%;
    font-size: 24px;
    margin-top: 5%;
    font-family: 'Montserrat-Bold';
   }
   @media (max-width:1280px){
    .hedtext{
      
      
       margin-left: 5%;  
       
       
    }
}
    .slide2{
        margin-top: -2%;
        width: 100%;
        height: 350px;
        background-color: #a0b19a;
        display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
     gap: 7%;
        /* top: 120%; */
        
        }
    .txt2{
        /* position: absolute; */
        width: 55%;
        /* top: 31%; */
        font-size: 24px;
        text-align:center;
        left: 23%;
        font-family: "Montserrat-Bold";
        /* margin-left: 25%;
        margin-top: 2%; */
       
    }
    .zz{
        /* margin-left: 47%;*/
        /* margin-top: 1%;  */
        display: flex;
        text-align:center;
        padding: 0, 15px, 0, 0;
        
    }
    .zzd{
        /* margin-left: 40%; */
        /* margin-top: 4%; */
        justify-content: center;
        
        display: flex;
        text-align:center;
    }
    
    .zick{
        height: 20px;
        width: 4px;
        background-color: #000000;
        transform: rotate(50deg);
        margin-left: 9px;
    }
    .zack{
        height: 20px;
        width: 4px;
        background-color: #000000;
        transform: rotate(-50deg);
        margin-left: 9px;
    }
    .click{
        height: 20px;
        width: 4px;
        background-color: #000000;
        transform: rotate(50deg);
        margin-left: 9px;
    }
    .clack{
        height: 20px;
        width: 4px;
        background-color: #000000;
        transform: rotate(-50deg);
        margin-left: 9px;
    }
    .slide3{
        /* width: 99%; */
        display: flex;
        /* position: absolute;
        top: 160%; */
        justify-content: center;
        text-align: center;
        margin-top: 3%;
    }



   


    .box1{
       padding: 5px;
        width: 435px;
        height: 600px;
        /* background-color: #eeeeee  ; */
       

    }
    
    .box2{
        padding: 5px;
        width: 435px;
        height: 600px;
       /* background-color: #eeeeee ; */
       margin-left: 6%;

   }
   .box2 button{
    margin-top: 5%;
   }
   
   .box3{
        padding: 5px;
        width: 400px;
        height: 600px;
       /* background-color: #eeeeee ; */
       margin-left: 6%;

   }

   @media (max-width:1280px){
        .slide3{
            /* width: 99%; */
            display: block;
            margin-left: 32%;
        }
        .box2{
        padding: 5px;
        width: 435px;
        height: 600px;
       /* background-color: #eeeeee ; */
       margin-left: 0%;

   }
   .box2 button{
    margin-top: 0%;
   }
} 
   .box3 a{
    margin-top: 21%;
   }
   .box2 a{
    margin-top: -2%;
   }
   .top1{
    
      height: 62px;
      margin-top: 41px;
    }
    .top2{
        height: 62px;
      margin-top: 50px;
    }
    /* Кнопки слайда 2 */
.btn-primary{
    background-color: #000000;
        border-radius: 30px;
        width: 320px;
        /* margin-top: 165%; */
        color: #ffffff;
        font-size: 24px;
        font-family: "Montserrat-Bold";
    }
    .btn-primary2{
        background-color: #000000;
        border-radius: 30px;
        width: 320px;
        /* top: 15%; */
        color: #ffffff;
        font-size: 24px;
        font-family: "Montserrat-Bold";
    }
   .card-title{
    font-size: 36px;
    font-family: "Montserrat-Bold";
    margin-top: 8%;
   }
.card-text{
    height: 130px;
    font-size: 24px;
    margin-top: 4%;
   }
   
   .img{
    margin-top: 10%;
    width: 32%;
    margin-left: 0;
   }


 .slide4{
    margin-top: 3%;
    /* top: 227%; */
    width: 100%;

    height: auto;


   }

   .heder{
    width: 99%;
    /* position: absolute;
    top: 190%; */
    
   }
   .how{
    margin-top: 5%;
    font-size: 50px;
    text-align: center;
    font-family: 'Montserrat-SemiBold';
   }
   /* .heder p{
    margin-left: 35%;
   } */
   .txt3{
    margin-left: 5%;
   }
  
   .kards{
    width: 52%;
    /* position: absolute;
    top: 221%; */
    margin-left: 23%;
    display: flex;
    height: auto;
  
   }
   .kards1{
    margin-top: 10%;
    width: 45%;
    text-align: right;
    margin-right: 1.5%;
   }
   .text1 p{
    font-family: "Montserrat-SemiBold";
    font-size: 36px;
   }
   .txt11{
    font-size: 24px;
    font-family: 'Montserrat-Regular';
   }
   .kards2{
    margin-top: 13%;
    width: 70px;
    /* margin-left: 8%; */
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    
  
   }
   .kards2 label{
    margin-top: 10%;
   }
   
   .rotand{
    display: flex;
    justify-content: center;
    width: 53px;
    height: 50px;
    background-color: black;
    border-radius: 100%;
    color: white;
    font-size: 20px;
    margin-top: 10%;
   }
   .rotand h1{
    /* margin-left: 30%; */
   }
   .palocka{
    background-color: black;
    width: 5%;
    height: 36vh;
    /* margin-left: 45%; */
    margin-top: 15%;
   }
   .kards3{
    width: 45%;
    margin-left: 1.5%;
    text-align: left;
   }
   
   .kard5{
    width: 100%;
    margin-top: 110%;
    /* margin-left: -6%; */
   }
   .kard4{
    /* width: 45%; */
    /* position: absolute;
    top: 300%; */
    margin-top: 85%;
   }
   @media (max-width: 3420px) {
    .kards3{
    margin-top: -10%;
   }
   .kard4{
    
    margin-top: 110%;
   }
   .kard5{
    width: 100%;
    margin-top: 120%;
    /* margin-left: -6%; */
   }
   }
   @media (max-width: 1900px) {
    .kards3{
    margin-top: 20%;
   }
   .kard4{
    
    margin-top: 235%;
   }
   .kard5{
    width: 100%;
    margin-top: 240%;
    /* margin-left: -6%; */
   }
   }
   .img1{
    width: 100%;
   }
   .img2{
    margin-left: -2%;
   }
   .img3{
    /* margin-left: -2%; */
    /* width: 100%; */
   }
   .kard2{
    /* position: absolute;
    top: 100%; */
    margin-top: 155%;
   }
   
   .slide5{
    /* position: absolute;
    top: 420%; */
    margin-top: 7%;
    width: 100%;
    padding: 30px;
    background-color: #eeeeee;
    height: 750px;
    font-family: "Montserrat-SemiBold";
    display: flex;
    align-items: center;
    justify-content: center;
   }
   
   @media (max-width:1800px) {
    .kard2{
        margin-top: 170%;
    }
    .kard5{
        margin-top: 160%; 
    }
    
    .kard4{
        margin-top: 140%; 
    }
   }
   @media (max-width:1624px) {
    .kard2{
        margin-top: 190%;
    }
    .kard5{
        margin-top: 180%; 
    }
    
    .kard4{
        margin-top: 160%; 
    }
   }
   @media (max-width:1500px) {
    .kard2{
        margin-top: 210%;
    }
    .kard5{
        margin-top: 180%; 
    }
    
    .kard4{
        margin-top: 170%; 
    }
   }
   @media (max-width:1350px) {
    .kard2{
        margin-top: 230%;
    }
    .kard5{
        margin-top: 190%; 
    }
    
    .kard4{
        margin-top: 200%; 
    }
   }
   @media (max-width: 1900px) {
    .kards3{
    margin-top: 20%;
   }
   .kard4{
    
    margin-top: 235%;
   }
   .kard5{
    width: 100%;
    margin-top: 240%;
    /* margin-left: -6%; */
   }
   }
   @media (max-width: 2000px) {
    .kards3{
    margin-top: -15%;
   }
   .kard4{
    
    margin-top: 35%;
   }
   .kard5{
    width: 100%;
    margin-top: 50%;
    /* margin-left: -6%; */
   }
   }
   .slide5body{
    width: 75%;
   /* margin-left: 17%; */
   /* margin-top: 5.6%; */
   color: #000000;
   }
/* --------------------------------------------------------------------------- */
   .slide6{
    width: 98%;
    /* position: absolute;
    top: 500%; */
    margin-top: 7%;
    font-family: "Montserrat-Regular";
   }
   .slide6panel{
     margin-top: 0%;
     display: flex;   
     justify-content: center;
   }
   .buton5{
    /* margin-left: 25%; */
    /* position: absolute; */
    width: 370px;
    height: 80px;
    background-color: #a0b19a;
    border-radius: 40px;
    border: none;
    font-family: 'Montserrat-Bold';
    font-size: 24px;
    /* border: 4px solid #000000; */
    text-decoration: none;
    color: #000000;
    text-align: center;
   }
   .qazwsx{
    margin-top: 5%;
   }
   .buton5 label{
    width: 100%;
    height: 100%;
   }
   .buton5 :hover{
    border-radius: 40px;
    
    color: white;
    background-color: #000000;
   }
   /* 
     border:1px;
    border-color: #000000;
    width: 370px;
    height: 80px;
    background-color: #f3f3f3;
    color: rgb(0, 0, 0);
    border-radius: 40px;
    margin-left: 1%;
    font-family: 'Montserrat-Bold';
    font-size: 30px;
   */
   .buton6{
    /* right: 25%;
    position: absolute; */
    margin-left: 5%;
    width: 370px;
    height: 80px;
    background-color: #ffffff;
    border-radius: 40px;  
    font-family: 'Montserrat-Bold';
    font-size: 24px;
    border: 4px solid #000000;
    text-decoration: none;
    color: #000000;
    text-align: center;

    }
   .buton6 label{
    
    width: 100%;
    height: 100%;
   }
   .lbtext{
margin-top: 4%;
   }
   .buton6 :hover{
    border-radius: 40px;
    
    color: white;
    background-color: #000000;
   }
   .buton5 a{
    text-decoration: none;
    color: #000000;
   } 
   .dualblock{
    /* position: absolute; */
    margin-top: 5.7%;
    margin-left: 8.5%;
    display: flex;
    justify-content: space-between;
   }
   .lefttext{
    margin-left: 0%;
    /* width: 42%; */
   }
   .lefttext p{
    font-family: 'Montserrat-Bold';
    font-size: 60px;
   }
   .label1{
    font-size: 36px;
    line-height: 150%;
    color: #a2a2a2;
   }
   .label2{
    text-align: justify;
    font-size: 24px;
   }
   .rightimage{
    /* position: absolute;
      top: -45%; */
    /* right: 15%; */
    float: left;
    margin-left: 0%;
    text-align: center;
  
   }
   .imageright{
    width: 61%;
    /* margin-left: 3%; */
    /* margin-right: 13%; */
    margin-top: -0%;
    float: right;
   }
   @media (max-width: 2000px) {
    .imageright{
        width: 50%;
    }

   }

   .slide7{
    /* position: absolute;
    top: 570%; */
    width: 100%;
    margin-top: 6.5%;
   }
   .tp1{
    font-size: 60px;
   }
   .tp2{
    font-size: 36px;
    color: #a2a2a2;
   }
   
   .teamhed{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0%;
    margin-top: 7%;
    width: 100%;
    height: 294px;
    background-color: #a0b19a;
   }
   .teamhed p{
    /* position: absolute;
    margin-top: 6.5%;
    margin-left: 37%;
    text-align: center; */
    font-family: 'Montserrat-Bold';
    font-size: 48px;
}
    .teamimg{
        border-radius: 100px;
    }
 
   .commandcart{
    width: 95%;
    background-color: #ffffff;
    margin-top: 5.5%;
    display: flex;
    margin-left: 5%;
    text-align: center;
    justify-content: center;
   }


   @media (max-width:1280px){
         .commandcart{
            width: 95%;
            background-color: #ffffff;
            margin-top: 5.5%;
            display: flex;
            margin-left: 0%;
            text-align: center;
            justify-content: center;
        }

}
   .comcont{
    margin-top: 8px;
   }
   .comcont2{
    margin-top: 6px;
   }
   .comcont3{
    
    }
    .comcont4{
        
    }
    .comcont5{
        
    }
   .comlb{
    margin-top: 0px;
    font-size: 24px;
   }
   
   .btcom{
    width: 370px;
   }
   .buton61{
    /* right: 25%;
    position: absolute; */
    margin-left: 5%;
    width: 370px;
    height: 80px;
    background-color: #000000;
    border-radius: 40px;  
    font-family: 'Montserrat-Bold';
    font-size: 24px;
    border: 4px solid #000000;
    text-decoration: none;
    color: #ffffff;
    text-align: center;

    }
   .buton61 label{
    
    width: 100%;
    height: 100%;
   }
   .lbtext2{
/* margin-top: 4%; */
   }
   .buton61 :hover{
    border-radius: 40px;
    
    color: rgb(0, 0, 0);
    background-color: #ffffff;
   }




   
   .commandbody0{
    width: 30%;
    margin-left: 0%;
    margin-top: 1%;
   }
   .commandbody{
    width: 30%;
    margin-left: 1%;
    margin-top: 1%;
   }
   .commandbody label{
    font-size: 24px;
    
   }
   .commandbuttonpanel{
    width: 98%;
    margin-left: 1%;
    height: 80px;
    margin-top: 5%;
    /* position: absolute;
    top: 105%; */
    /* background-color: #a0b19a; */
   
     margin-top: 6.6%;
     display: flex;   
     justify-content: center;
   
   }
   .commandbuttonpanel a{
    height: 80px;
   }
   .commandbutton1{
    /* border:1px; */
    width: 370px;
    height: 80px;
    background-color: #a0b19a;
    border-radius: 40px;
    border: none;
    font-family: 'Montserrat-Bold';
    font-size: 24px;
    /* border: 4px solid #000000; */
    text-decoration: none;
    color: #000000;
    text-align: center;
    margin-left: 2%;

   }

   .commandbutton1 :hover{
    border-radius: 40px;    
    color: white;
    background-color: #000000;
    height: 100%;
    width: 100%;
    /* margin-top: -4%; */

   }

   .qwe{
   border:1px;
    width: 370px;
    height: 80px;
    background-color: #a0b19a;
    border-radius: 40px;
    margin-left: 20%;
    font-family: 'Montserrat-Bold';
    font-size: 24px;
    text-decoration: none;
    color: #000000;
    padding: 20px;

/* ----------------- */

    width: 370px;
    height: 80px;
    background-color: #ffffff;
    border-radius: 40px;
    border: none;
    font-family: 'Montserrat-Bold';
    font-size: 24px;
    border: 4px solid #000000;
    text-decoration: none;
    color: #000000;
    text-align: center;

}
    .commandbutton1 label{
    width: 370px;
    height: 80px;
    text-align: center;
    margin-top: 0%; 
   }
   .commandbutton1 label :hover{
    width: 370px;
    height: 40px;
    text-align: center;
    margin-top: 5%; 
   } 
   .commandbutton2{
    /* border:1px; */
    width: 370px;
    height: 80px;
    background-color: #000000;
    border-radius: 40px;
    border: none;
    font-family: 'Montserrat-Bold';
    font-size: 24px;
    /* border: 4px solid #000000; */
    text-decoration: none;
    color: #ffffff;
    text-align: center;
    margin-left: 2%;

   }

   .commandbutton2 :hover{
    border-radius: 40px;    
    color: rgb(0, 0, 0);
    background-color: #a0b19a;
    height: 100%;
    width: 101%;
    /* margin-left: -0.1%; */
    /* margin-top: -4%; */

   }

   
 
    .commandbutton2 label{
    width: 370px;
    height: 80px;
    text-align: center;
    margin-top: 0%; 
    
   }
   .commandbutton2 label :hover{
    width: 370px;
    height: 40px;
    text-align: center;
    margin-top: 5%; 
    margin-left: 0%;
    background-color: #a0b19a;
   } 
   .commandbutton3{
    /* border:1px; */
    width: 370px;
    height: 80px;
    background-color: #ffffff;

    border-radius: 40px;
    border: 4px solid  #000000;
    font-family: 'Montserrat-Bold';
    font-size: 24px;
    /* border: 4px solid #000000; */
    text-decoration: none;
    color: #000000;
    text-align: center;
    margin-left: 2%;

   }

   .commandbutton3 :hover{
    border-radius: 40px;    
    color: white;
    background-color: #000000;
    height: 100%;
    width: 100%;
    /* margin-top: -4%; */

   }

   
    .commandbutton3 label{
    width: 370px;
    height: 80px;
    text-align: center;
    margin-top: 0%; 
   }
   .commandbutton3 label :hover{
    width: 360px;
    height: 40px;
    text-align: center;
    margin-top: 5%; 
    margin-left: -0%;
   } 
   /* .lbtext1{
    margin-top: 4%; 
    height: 80px;
   }
   .lbtext1 p :hover{
    margin-top: -4%;
    height: 80px;
   } */
   .lbtext{
    
   }
   .rfv{
    margin-top: 5%;
    height: 100%;
    width: 100%;
}

   
   .slide8{
    position: absolute;
    top: 770%;
    width: 99%;
    background-color: #b3b3b3;
    height: 400px;
   }
   .game{
    margin-left: 35%;
    margin-top: 5%;
    width: 30%;

    }
    .slide10{
        width: 100%;
        height: 265px;
        background-color: #a0b19a;
        /* position: absolute;
        top: 780%; */
        margin-top: 7%;
        text-align: center;
        font-family: 'Montserrat-Regular';
    }
    .p222{
        margin-top: 0%;
    }
    .slide10 p{
        margin-left: 0%;
        /* margin-top: 7%; */
        font-size: 50px;

    }
    .opros{
        width: 60%;
    }
    /* .text1{
    
    } */
    .text{
        margin-top: 5%;
        margin-left: 4%;
        font-size: 36px;
    }

    .slide11{
      margin-top: 5%;
        width: 99%;
        text-align: center;

    }
    .footertext{

        width: 40%;
        margin-left: 30%;

    }
    .footertext label{

    }
    .footerbutton1{
        width: 40%;
        height: 100px;
        border-radius: 40px;
        margin-left: 1%;
        background-color: #a0b19a;
        margin-top: 4%;
        color: #ffffff;
        font-family: 'Montserrat-Bold';
    }
  
    .footer{
        margin-top: 5%;
        margin-right: 8%;
       text-align: left;
       
    }
    .footer a{
        text-decoration: none;
        margin-left: 1.5%;
        padding: 15px;
        color: #000000;
        font-family: "Montserrat-Bold"; 
         font-size: 20px;
    }
    .footfooter{
        width: 99%;

    }
    .urtext{
        width: 20%;
        height: 150px;
        margin-left: 40%;
        margin-top: 5%;
      
 
    }


   
   .slide9{
    position: absolute;
    top: 680%;
    width: 99%;
   }
   @media (max-width:1200px) {
        .slide9{
            max-width: 900px;
        }
   }
   .preferenc{
 
    width: 60%;
    margin-left: 20%;
    height: 600px;
    background-color: beige;
   }
   .prefhed{
    width: 98%;
    height: 100px;
    background-color: #a0b19a;
   }
   .prefhed p{
    margin-top: 5%;
   }
   .pprefhed{  
     
    margin-left: 27%;
    
   }
   .preferbody{
    position: absolute; 
   
   }
   .imagecont{
    
   margin-left: 1.3%;
   }
   @media(max-width:1200px){
    .imagecont{
        max-width: 100px;
        float: left;
    }
   }

   .imagecont td, tr{
    padding: 5px;
   }
   .img3{
    width: 200px;
    height: 130px;
    border: 3px;
    margin-left: 2%;
   }
   .btnpanel{
    position: absolute;
    width: 99%;
    padding: 5px;
    display: flex;
    margin-top: 5%;
   }

   .preferbodyhed{
   
    margin-left: 27%;
   }
   
   .footer{
    margin-left: 10%;
   }
   .footertext{
    font-size: 30px;
    text-align: center;
   }
   .footertext p{
    text-align: center;
    font-size: 60px;
   }
   .buton1{
    position: absolute;
    left: 4%;
    border-radius: 5px;
    background-color: aliceblue;
    padding: 5px;
    width: 70px;
   }

   .buton2{
    position: absolute;
    right: 4%;
    background-color: #a0b19a;
    border-radius: 5px;
    padding: 5px;
    width: 70px;
   }
   
   .input{
    border:none;
    border-radius: 5px;
    width: 100%;
    height: 40px;
    margin-top: 4%;
    margin-left: 25%;
   }
   .inputbox{
    position: absolute;
    
   }
   .preferbody5{
    position: absolute;
    width: 50%;
   }
   .card{
    background-color: #eeeeee ;
    height: 400px;
    width: 360px;
    text-align:center;
   }
   .card-title{
    font-family: "Montserrat-Bold";
    font: 60px;

   }
 
</style>
