<template>
    <div class="body">

        <div class="content">

            <div class="contentbox">

                <p class="p1">
                    Какой вы тип пользователя?
                </p>
                <div class="pbox">

                    <div class="polzovatel">
                        <a href="#alias">
                        <div class="imgbox">
                            
                        </div>
                        <p>
                            Собственник
                        </p>
                        <label>
                            для собственных нужд
                        </label>
                        </a>
                    </div>
                   

                  
                    <div class="polzovatel">
                        <a href="#alias">
                         <div class="imgbox">
                            
                        </div>
                        <p>
                            Дизайнер
                        </p>
                        <label>
                            для учебы/работы
                        </label>
                        </a>
                    </div>
                   
                   
                    <div class="polzovatel">
                        <a href="#alias">
                        <div class="imgbox">
                            
                        </div>
                        <p>
                            Продавец
                        </p>
                        <label>
                            для работы
                        </label>
                        </a>
                    </div>
                   
                </div>
            </div>
        </div>
           
    </div>
</template>

<script>

// import heder from "./components/hederUno.vue";
export default{
    components:{
      //  heder
        }
}

</script>

<style scoped>


.pbox{
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-left: 10%; */
    margin-top: 5%;

}

.polzovatel{
    border: 1px;
    border: 1px solid #000000;
    margin-left: 2%;
    min-width: 220px;
    width: auto;
    text-align: center;
    border-radius: 15px;
    padding: 5px;
    
}
.polzovatel a{
    text-decoration: none;
    color: #000000;
}
.polzovatel p{
    font-size: 30px;
    margin-top: 15%;
}

.polzovatel label{
   margin-bottom: 15%;
}
.imgbox{
    width: 80px;
    height: 80px;
    margin-top: 15%;
    background-color: #D9D9D9;
    border-radius: 50px;
    margin-left: 37%;
}
.contentbox{
    width: 90%;
    /* margin: 5%, 15%, 5%, 5%; */
    text-align: center;
    margin-left: 0;
    margin-top: 15%;
}

.p1{
  margin-top: 5%;
  font-size: 40px;
  /* margin-left: 30%; */

}
.content{
    width: 90%;
    height: 94%;
    margin-left: 0%;
    margin-top: 0%;
    position: fixed;
    background-color: white;
}
.body{
    font-family: "Montserrat-Regular";
    /* background-color: rgb(211, 211, 211); */
    height: 1000px;
}

</style>
