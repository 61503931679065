<template>
    <div class="body" >

        {{getproject}}
        <div class="bd" v-for:="options in get">
{{options}}
        </div>
        

    </div>  

</template>
<script>
 import apiint from  "../../JS/apiint.js" 
export default{
   

    methods:{
        getproject(){
                    

            const options = {
            method: 'GET',
            url: 'http://185.221.153.253:8000/api/aigarden/project',
            
            
            };

            apiint.request(options).then(function (response) {
            console.log(response.data);
            var get=response.data
            console.log(get)
            }).catch(function (error) {
            console.error(error);
            });
                    },
            qwe(){
                const q="qwert"
                console.log(q)


            }



                },
    mounted(){
        const q="qwert"
                console.log(q)
        this. getproject()
        this. qwe()
    } 
}
</script>

<style scoped>


</style>