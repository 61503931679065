<template>
    <div class="body">
        <div class="hed">
            <div class="lefthed">
                <div class="lcont">
                     <h1>
                    <p class="hedtext1">
                        A I . G A R D E N
                    </p>

                </h1>
                <p class="hedtext2">
                    Создай сад мечты
                </p>
                <a>
                <button class="btn hedbtn">
                     Легко!
                </button>
                </a>
                </div>
               
                
            </div>
            <div class="righthed">
                <img class="img1" src="@/land/assets/tild6432.jpg">
            </div>
          
        </div>
    </div>
</template>

<script>
export default{

}
</script>

<style scoped>
.lefthed{
    display: flex;
    flex-direction: column;

   /* justify-content: space-between; */
   /* float: left; */
   text-align: left;
   margin-left: 2%;
  
}
.lcont{
    margin-left: 0%;
    width: 130%;
   z-index: 3333;
}
.hedtext1{
    justify-content: center;
   font-size: 100px;
    font-family: "Montserrat-Bold";
    
}
.hedtext2{
    justify-content: center;
   color: rgb(172, 172, 172);
   font-size: 60px;
   /* margin-top: 50px; */
}
.righthed{
    display: flex;
   
    justify-content: right;
}
.img1{
    /* justify-content: center; */
   width: 65%;
   min-width: 40%;
   z-index: 1;
   margin-left: 10%;
}

@media (max-width: 1720px) {
    .hedtext1{
        font-size: 80px;
    }
    .hedtext2{
        font-size: 40px;
    }
    .img1{
        width: 60%;
    }
    }
    @media (max-width: 1580px) {
    .hedtext1{
        font-size: 60px;
    }
    .hedtext2{
        font-size: 40px;
    }
    .img1{
        width: 50%;
    }
    }
    @media (max-width: 1424px) {
    .hedtext1{
        font-size: 48px;
    }
    .hedtext2{
        font-size: 30px;
    }
    .img1{
        width: 50%;
    }
    }
.hedbtn{
    justify-content: center;
   width: 300px;
   height: 90px;
   background-color: black;
   color: white;
   border-radius: 50px;
   font-size: 34px;
   margin-left: 2%;
margin-top: 180px;
}
.hed{
    margin-top: 5%;
    /* margin-left: 20%; */
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5%;
    /* text-align: center;
    /* opacity:0;
    transition: 1s; /*Скорость перехода состояния элемента
    animation: show 3s 1; /* Указываем название анимации, её время и количество повторов
    animation-fill-mode: forwards; /* Чтобы элемент оставался в конечном состоянии анимации 
        animation-delay: 1s;  Задержка перед началом 
    }
    @keyframes show{
    0%{
    opacity:0;
    }
    100% {
    opacity:1;
    } */
}
</style>
