<template>
    <div class="body">
       <HederUno/>
       <tophead/>
       <div class="content">
            <div class="contentbox">
            <h1>
                <p>
                    Чем наш сервис полезен питомникам?
                </p>
            </h1>
            <label>
                Мы предлагаем питомникам индивидуальный формат сотрудничества. Наша цель - автоматизация процессов взаимодействия с клиентами для максимального снижения расходов и повышения лояльности покупателей. Мы разрабатывам технологии для бесшовного взаимодействия поставщиков, проектировщиков и конечных пользователей в цифровой среде и готовы к диалогу для наиболее полного удовлетворения потребностей наших клиентов!
            </label>

             </div>
             <br>
             <div class="zelcont">
                <div class="zag">
                <h1>
                    <p >
                        Оптимизируйте рабочие процессы
                        и привлекайте больше клиентов онлайн!
                    </p>
                </h1>
                </div>
                <div class="kardcont">

                <div class="kard">
                    <h1>
                    <p>
                      Скорость
                    </p>
                </h1>
                    <label>
                        Сокращайте время на получение и обработку заказа
                    </label>
                </div>
                <!--  -->
                <div class="kard2"></div>
                <!--  -->
                <div class="kard">
                    <h1>
                    <p>
                         Качество
                    </p>
                </h1>
                <label>
                    Повышайте качество обслуживания 
                </label>
                </div>
                 <!--  -->
                 <div class="kard2"></div>
                <!--  -->
                <div class="kard">
                    <h1>
                    <p>
                        Лояльность
                    </p>
                </h1>
                <label>
                    Привлекайте больше клиентов за счет уникальных услуг
                </label>
                </div>
                
                <!--  -->
                </div>
             </div>
        </div>
        <boost/>
       <Footer/>
    </div>
</template>

<script>
import HederUno from './hederUno.vue'
import Footer from './footerUno.vue'
import boost from './components/components/boostUno.vue'
import tophead from './components/components/topHead.vue'
export default{
    components:{
        HederUno,
        Footer,
         boost,
        tophead,
        
    },


}
</script>

<style scoped>
.zag{
    margin-top: 5%;
    
}
.zag p{
    margin-top: 5%;
}
.kard2{
    height: 200px;
    width: 2px;
    margin-left: 1%;
    background-color: rgb(202, 202, 202);
}
.kard{
    width: 200px;
    height: 400px;
    margin-left: 1%;
}
.kardcont{
    display: flex;
    margin-left: 30%;
}
.zelcont{
    text-align: center;
}
.zelcont{
    width: 100%;
    height: 700px;
    background-color: #a0b19a;
}
.body{
    font-family: "Montserrat-Regular";
}
.contentbox{
    width: 60%;
    margin-left: 20%;
    
}
.content{
    margin-top: 5%;

}

</style>
