<template>
    <div class="body">
        <a href="https://www.flaticon.com/free-icons/rename" title="rename icons"></a>
        <div class="heder">
            <div class="up">
                <a href="#alias">
                    
                <p><img src="./ik/strelkanazad1.png">  назад к списку проектов</p>
                </a>
                <div class="versions">
                    <p>
                    Версия 1
                </p>
                <p>
                    Версия 2
                </p>
               
                <p>
                    Версия 2
                </p> 
                </div>
                <div class="close">
                    <!-- <save/> -->
                    <button class="btn closebtn">
                        Закрыть проект
                    </button> </div>
            </div>
            <div class="bottom">
                <div class="blockp1">
                    <div class="p1">
                       <label class="name">
                        <strong>
                             Без названия
                        </strong>
                       
                    </label>
                        <!-- <input  v-model="pr_descr"  type="tel" placeholder="Без названия" > -->
                        <button
                        @click="pr_rename"
                         class="btn rename">
                            <img class="imgren" src="./ik/rename.png">
                        </button>
                    </div>
                </div> 
                <div class="blockp2"> 
                    <a href="#shablon">
                         <div class="p2">
                        Результат генерации
                    </div>  
                    </a>         
                    <a href="#info">
                        <div class="p2">
                        Основные данные
                    </div>
                    </a>
                    

                    <a href="#visual">
                       <div class="p2">
                        Визуальные предпочтения
                    </div>  
                    </a>
                   
                    <a href="#docs">
                    <div class="p2">
                        Документация
                    </div>
                     
                    </a>
                     <a href="#docs">
                    <div class="p2">
                        Подборки растений
                    </div>
                    </a>
   
                </div> 
               

            </div>
        </div>
       </div> 
</template>

<script>
 import api from  "../../../JS/api.js"  
export default{

    name: "proJect",
        data(){
       return{
           
            project1: null,
           
        }
    },

        mounted(){
           
            console.log(this,'1') 
  console.log(this)
    this.projects(this)  //использовать для прорисовки по умолчанию
},
        methods:{
        // api.request(options2).then(function (response)

            projects(this2){
                let result = this.$route.query.project
                console.log('Hello world','3');
                const options = {
                    method: 'GET',
                    url: 'http://185.221.153.253:5700/api/project/' + result,
                                   
                    };
                  
                    api.request(options).then(function (response) {
                    localStorage.setItem('id', response.data.id)      
                            
                    this2.project1 = response.data
                    console.log(this2.project1);
                    let qwerty = response.data
                    console.log("========");
                    console.log(qwerty);
                    }).catch(function (error) {
                    console.error(error);
                 
                    })
                
            },

            pr_rename(){
                let result = this.$route.query.project
                console.log('Hello world','3');
                const options2 = {
                method: 'POST',
                url: 'http://185.221.153.253:5700/api/project/' + result,
                headers: {
                    'Content-Type': 'application/json',
                },
                data: {
                    pr_descr: this.pr_descr, 
                    }
                };

                api.request(options2).then(function (response) {
                console.log(response.data);
                }).catch(function (error) {
                console.error(error);
                });
            }
            
        }
    

}
</script>

<style scoped>
.close{
    margin-top: -40px;
     /* width: 40%;  */
    text-align: right;
}
.versions{
    display: flex;
    text-align: center;
    margin-top: -2%;
    margin-left: 37%;
}
.versions p{
    margin-left: 3%;
    width: 85px;
}
.imgren{
    margin-top: -25%;
}
.name{
    width: 200px;
}
.rename{
    margin-left: 1%;
}
.heder{
    margin-left: 2%;
}
.p1{
    float: left;
    margin-left: 0%;
    font-size: 24px;
    text-align: center;
    width: auto;
    height: 29px;
    font-weight: 400;
    line-height: 29px;
    display: flex;
}
.blockp2{
    margin-left: 22%;
}
.blockp2 a{
    text-decoration: none;
    color: black;
}
.p2{
    float: left;
    margin-left: 1%;
    font-size: 16px;
    margin-top: 0.5%;
    text-align: center;
    width: 240px;
    height: 19px;
}
.up a{
    text-decoration: none;
    color: black;
    
}
.body{
    margin-top: 0px;
    font-family: "Montserrat-Regular";
}

</style>
