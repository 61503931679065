<template>
    <div class="body">

        <div class="contentfoot">
            <button data-trigger v-on:click="modal(openModal)" class="btn button1" >    
                Создать

            </button>
        </div>

     
        <!-- ---------------- -->

        <div class="modal-container"> 

        <div class="modal-content">
            <button class="btn close">х</button>
           
            <div class="mdcont">
               <p class="modalhed">
              <strong>
                Новый проект
              </strong>
            </p>
            <br>
              <label>
                Имя проекта
              </label><br>

              <input
               class="prname"
                type="text"
                v-bind="title"
                 placeholder="Без названия">
                 <br><br>
                 <label>
                  Папка проекта
              </label><br>
            
              <input
               class="prfolder"
                type="text"
                v-bind="prfolder"
                 placeholder="Новая композиция">
                 <br> <br><br>
                 <a >
              <button @click="createproject" class="gooo">
                Поехали!

              </button>
            </a>
            </div>

        </div>
        </div>

    </div>
</template>

<script>
 import apiint from  "../../../JS/apiint.js"  



export default{
    data(){
            return{
                step:1,
                id:null
            }
        },
        methods :{

            // nextStep(){
            //     this.step++
            // },

          
            modal(){
       (function(){
            var modal = document.querySelector('.modal-container');
            var closeButton = document.querySelector('.close');
            var modalTriggers = document.querySelectorAll('[data-trigger]');

            var isModalOpen = false;
            var pageYOffset = 0;

            var openModal = function() {
                pageYOffset = window.pageYOffset;
                modal.classList.add('is-open');
                isModalOpen = true;
            }

            var closeModal = function() {
                modal.classList.remove('is-open');
                isModalOpen = false;
            }

            var onScroll = function(e) {
                if (isModalOpen) {
                e.preventDefault();
                window.scrollTo(0, pageYOffset);
                }
            }

            modalTriggers.forEach(function(item) { 
              
                item.addEventListener('click', openModal);
            })

            document.addEventListener('scroll', onScroll);

            closeButton.addEventListener('click', closeModal);
            

            })();
    },
    
    createproject(id){

            const crproject = {
                method: 'POST',
                url: 'http://185.221.153.253:8000/api/aigarden/project',

                data: {
                    title:this.title,
                    "location": "string",
                    catalog_id: this.prfolder
                }
                };

                apiint.request(crproject).then(function (response) {
                console.log(response.data);
                localStorage.setItem('id', response.data.id)  
                console.log(response.data.id)
                console.log('succes create')
            console.log(id);
            //  
            // this.$router.push({path: this.$route.fullPath + '/'+'neuroNet' + '/' +id })

            localStorage.getItem(id)    
            console.log(id)
             this.$router.push( {path: 'constructor', query: { 'project': id }})
            console.log('123')
            //this.$router.push( {path: 'create', query: { 'project': id }})
            //   window.location.reload()
                
                }).catch(function (error) {
                console.error(error)
                if(error.response.status === 422){
                    this.$router.push( {path: 'constructor', query: { 'project': id }})
                    window.location.reload()
                }
                });

              
            },
        }
}
</script>
<style scoped>
.body{
    font-family: "Montserrat-Regular";
    height: 100px;

}

.button1{
    background-color: #707070;
    width: 500px;
    height: 80px;
    margin-left: -5%;
}


/* ================ */
html {
    box-sizing: border-box;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

a {
    color: black;
    text-decoration: none;
}

img {
    max-width: 100%;

}

body {
    font-family: Arial, Verdana, sans-serif;
}





/* ---------------------- */

/* .modal-container {
  position: fixed;
  left: 0; 
  top: 0; 
  display: flex;
  width: 100vw;
  height: 100vh; 
  padding: 30px;
  background-color: rgba(0, 0, 0, 0.5);
}
.modal-content {
   
  overflow: scroll;
  width: 100%; 
  height: 100%; 
  max-width: 800px; 
  max-height: 400px; 
  background-color: #fff; 
}
.close {
 position: absolute;
 right: 0;
 top: 0;
 width: 30px;
 height: 30px;
}
.modal-container { 
  pointer-events: none; 
  opacity: 0;

}
.modal-container.is-open { 
  pointer-events: all; 
  opacity: 1;

} */
* {
	box-sizing: border-box;
}

.container {
	padding: 100px 0;
}

.modal-container {
	pointer-events: none; 
  opacity: 0;
	position: fixed;
  z-index: -1;
	left: 0;
	top: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100vw;
	height: 100vh;
	padding: 30px;
	background-color: rgba(0, 0, 0, 0);
  transition: opacity .3s, background-color .5s;
}

.is-open {
	opacity: 1;
	pointer-events: all;
  z-index: 1;
	background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
	overflow: auto;
	width: 100%; 
  height: 100%; 
  max-width: 550px; 
  max-height: 450px;
	padding: 20px;
  background-color: #fff;
  border-radius: 3px;
}

.mdcont{
  text-align: center;
  justify-content: center;
}
.mdcont p{
  font-size: 24px;
}
.gooo{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 41px;
  gap: 10px;
  text-align: center;
  position: absolute;
  width: 319px;
  height: 51px;
  margin-left: 18%;
  background: #D9D9D9;
  border-radius: 10px;
  }
  .prname{
    box-sizing: border-box;
    /* position: absolute; */
    margin-left: 2%;
    width: 325px;
    height: 50px;
    
    background: #FFFFFF;
    border: 1px solid #A09F9F;
    border-radius: 5px;
  }
  .prfolder{
    box-sizing: border-box;
    /* position: absolute; */
    margin-left: 2%;
    width: 325px;
    height: 50px;    
    background: #FFFFFF;
    border: 1px solid #A09F9F;
    border-radius: 5px;
  }
.close {

	position: absolute;
	right: 2%;
	top: 0;
	width: 80px;
	height: 30px;
  background-color: #fff;
  /* border: solid 1px #222; */
  border-radius: 3px;
}

</style>
