<template>
 <a  href="#onePage">К работе с нейросетями </a>
        <a href="#twoPage">К работе с изображениями</a> 
    <div class="content-box">
        



        <div class="content1">

            <!-- <button @click="neurorec">Запустить</button> -->

            <!-- <select class="select select-initialized" type="multitype" placeholder="Выбрать нейросеть">
                <option   v-for:="options2 in neuronet" >{{options2.id}}</option>
                
            </select> -->

            <input 
            type="form-label select"
            placeholder="введите id"
            v-model="id"
            >
               
        
        </div>
        
        <div class="content2">

            <div class="imguploader">
            <input
            name="image"
            type="file" 
            accept="image/*" 
            @change="picload" 
            id="file-input"
            v-on="file">

            
                
        </div>
      
        <table class="table">

<tbody>
<tr

>
            
<td>{{(response)? response.data : ''}}</td>
    
  
</tr>
</tbody>

</table>
        </div>

   </div>
    
    </template>
    
    <script>
    //import axios from "axios";
    import api from "../../JS/api.js"
    
    export default{
        data(){
            return{
                neuronet: null,
                rezultat: null,
            }
        },

        methods:{

            // neurorec(){
            //     let id = this.id
            //     const options = {
                    
            //     method: 'POST',
            //     url: 'http://185.221.152.242:5500/api/neurorec/' + id,
                
            //     };

            //     api.request(options).then(function (response) {
            //     console.log(response.data);
            //     }).catch(function (error) {
            //     console.error(error);
            //     });


            // },

            picload(this2){

                //let result = this.$route.query.picdescr
                let id = this.id
                const URL = 'http://185.221.152.242:5500/api/neurorec/' + id;//+result; 

                let data = new FormData();

                data.append('image', event.target.files[0]); 

                let config = {
                header : {
                    'Content-Type' : 'multipart/form-data'
                }
                }
                //  console.log(event.target.files, 'qwerty')
                api.post(
                URL, 
                data,
                config
                ).then(
                response => {
                    console.log('image upload response > ', response, '1234')
                    //alert('image upload  > ', response.data)
                    this2.rezultat = response.data
                    
                    alert(JSON.stringify(response.data))

                    // alert({text: 'Пример', stringify: function() {
                    // return ` ${response}`
                    // }})
                    
                }
             )


            },

            neuronetIN(this2){

                console.log('Hello world');

                const options2 = {
                method: 'GET',
                url: 'http://185.221.152.242:5500/api/neuronet',

                };

                api.request(options2).then(function (response) {


                localStorage.setItem('id', response.data.id)
                console.log(this2)
                console.log(response.data, '1234');
                this2.neuronet = response.data;
                alert( response.data)
                }) 
                
                .catch(function (error) {
                console.error(error);
                console.log(options2)
                });

                },

                picReturn() {
            let result = this.$route.query.picdescr;
            const imagereturn = {
              method: 'GET',
              url: 'http://185.221.152.242:5500/api/picload/' + result,
             data: {filename: this.filename, picname: this.picname}
            };

            api.request(imagereturn).then(function (response) {
              console.log(response.data);
              let image = response.data
              console.log(image)
            }).catch(function (error) {
              console.error(error);
            });
        },

        }

    
    }
    
    </script>
    
    
    <style scoped>

        .content-box{
            display: flex;
            padding: 5px;
            
        }

        .content1{
            margin-top: 6%;
            width: 48%;
            height: auto;
            background-color: rgb(98, 98, 99);
            padding: 5px;
            border-radius: 5px;
            margin-left: 1%;
        }

        .content2{
            margin-top: 6%;
            width: 48%;
            height: auto;
            background-color: rgb(98, 98, 99);
            padding: 5px;
            border-radius: 5px;
            margin-left: 1%;
        }
    
    
    
    </style>