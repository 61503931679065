<template>
    <div class="body">
       <HederUno/>
       <tophead/>
       <div class="content">
            <div class="contentbox">
            <h1>
                <p>
                    Что мы можем предложить нашим пользователям уже сегодня?
                </p>
            </h1>
            <label>
                Пока наш продукт находится в разработке, мы активно собираем обратную связь и ожидания наших потенциальных пользователей. Мы будем признательны, если Вы ответите на несколько вопросов - это позволит нам сделать продукт наиболее подходящим именно для Вас! Мы также предлагаем Вам оставить свои контакты - как только наш сервис будет готов, Вы сможете первыми протестировать его и получить в дальнейшем специальные условия пользования НАВСЕГДА! Подписывайтесь на наши новости, чтобы не пропустить ничего интересного!
            </label>

             </div>
             <br>
             <div class="zelcont">

                <div class="adverthed">
                    <br><br><br>
                <p>
                    Сделайте Ваших клиентов счастливыми!
                </p>
                <label>
                    Работайте в удовольствие, творите и фантазируйте, а рутину оставьте искусственному интеллекту!
                </label>
                </div>
               <div class="table">
                    <div class="tablebody1">
                        <label>
                            Уникальная система определения предпочтений и потребностей пользователя
                        </label>
                        <img src="./assets/icons/free-icon-creative-brain-4385919.png" alt="img">
                    </div>
                    <div class="tablebody2">
                        <label>
                            Генерация фотореалистичных визуализаций по тегам и отобранным изображениям
                        </label>
                        <img src="./assets/icons/free-icon-insert-picture-icon-16410.png" alt="img">
                    </div>
                   
                </div>
                <div class="table">
                    
                    <div class="tablebody1">

                         <label>
                            Вся работа в одном онлайн-сервисе без тяжелых программ и конвертирования файлов
                        </label>
                        <img src="./assets/icons/free-icon-innovation-2452643.png" alt="img">
                    </div>
                    <div class="tablebody2">
                        <label>
                            Дополнительный заработок на заказах и доставке, удобный формат работы с поставщиками!
                    </label>
                    <img src="./assets/icons/free-icon-profit-154221.png" alt="img">
                    </div>
             </div>
             </div>
             <div class="btndiv">
                <a>
                    
                </a>
                <button class="btn button1">
                    Стать вип пользователем
                </button>
             </div>
        </div>
        <boost/>
       <Footer/>
    </div>
</template>

<script>
import HederUno from './hederUno.vue'
import Footer from './footerUno.vue'
import boost from './components/components/boostUno.vue'
import tophead from './components/components/topHead.vue'
export default{
    components:{
        HederUno,
        Footer,
         boost,
        tophead,
        
    },


}
</script>

<style scoped>
.button1{
    width: 100%;
    height: 100px ;
    background-color: #a0b19a;
}
.table{
    display: flex;
    width: 100%;
    margin-top: 2%;
}
.tablebody1 img{
    width: 125px;
    margin-left: 2%;
}
.tablebody2 img{
    width: 125px;
    margin-left: 2%;
}
.tablebody{

}
.tablebody1 label{
    margin-top: 2%;
    text-align: left;
    font-size: 25px ;
   margin-left: 30%;
    width: 50%;
    font-family: 'Montserrat-Bold';
    float: left;
}
.tablebody2 label{
    margin-top: 2%;
    text-align: left;
    font-size: 25px ;
   margin-left: 5%;
    width: 50%;
    font-family: 'Montserrat-Bold';
    float: left;
}
.adverthed label{
   
    font-size: 25px ;
    width: 30%;
    text-align: center;
}
.adverthed p{
    /* margin-left: 33%; */
    font-family: 'Montserrat-Bold';
    font-size: 30px ;
    text-align: center;
}
.zelcont{
    text-align: center;
    margin-top: 4%;
}
.zelcont{
    width: 100%;
    height: 700px;
    background-color: #cecece;
}
.body{
    font-family: "Montserrat-Regular";
}
.contentbox{
    width: 60%;
    margin-left: 20%;
    
}
.content{
    margin-top: 5%;

}

</style>
