<template>
    <div class="body">
        <!--  -->
        <div class="content">   

<!--  -->
            <div class="hed">
                <div class="deh">
                    <p>
                    <strong>Что вы хотите<br> создать?</strong> 
                    </p>  
                </div>
                
            </div>
            <div class="cbody">
               
                <button class="btn buttoncrt">
                    Садовая композиция
                </button>
                <br>

                <button class="btn buttoncrt">
                    Зона на участке
                </button><br>
                
                <button class="btn buttoncrt">
                    Дизайн всего участка
                </button>
            </div>
        </div>
    </div>
</template>

<script>

export default{

}
</script>

<style scoped>
.content{
    text-align: center;
}

.hed{
    margin-left: 0%;
    margin-top: 20%;
    font-size: 40px;
    font-family: "Montserrat-SemiBold" ;
   text-align: center;
}
.deh p{
     /* text-align: left; */
}
.cbody{
   
    margin-left: 0%;
    margin-top: 2%;
}
.cbody a{
    text-decoration: none;
    color: black;
}
.buttoncrt{
    width: 270px;
    height: 60px;
    border-radius: 5px;
    margin-top: 2%;
    background-color: #D9D9D9;
}
.body{
    margin-top: 0px;
    font-family: "Montserrat-Regular";
    background-color: rgb(255, 255, 255);
    height: 865px;
    width: 40%;
    z-index: 5;
    float: left;
}
</style>
