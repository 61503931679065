<template>
<div class="link"><a href="#twoPage">back</a></div>
    <div>
    

        <div class="button">
            <button @click="folderUpdate">Изменить </button>
            <button @click="folderDelate">Удалить</button>

            
        </div>
        <!-- <div class="input">
            <input
            name="descr"
            width="200px"           
            type="text"
            class="form-control"
            placeholder="descr"
            v-model="descr"
          >
          <input
          name="name"
          width="200px"
            type="text"
            class="form-control"
            placeholder="name"
            v-model="name"
          >
        </div> -->
       


           
        </div>
        <div class="content">
            <table class="table">
              <thead>
                <tr>
                 
                    <th scope="col">descr</th>
                    <th scope="col">id</th>
                    <th scope="col">name</th>
                    <th scope="col">root_id</th>
                </tr>
              </thead>
              <tbody>
                <tr
                
                >
                  
                   
                    <td scope="row"  >{{(neuronet)? neuronet.descr : ''}}</td>
                    <td>{{(neuronet)? neuronet.id : ''}}</td>
                    <td>{{(neuronet)? neuronet.name : ''}}</td>
                    <td>{{(neuronet)? neuronet.root_id : ''}}</td>
                </tr>
              </tbody>
           </table>
           
 <!-- ------------------------------------------------------------------- -->          
                <!-- <div             
                class="picview"
                >
              <button @click="picsCreate">Создать</button>

            </div> -->
              <div class="input">

                <input
                name="descr"
            width="200px"           
            type="text"
            class="form-control"
            placeholder="descr"
            v-model="descr"
          >
          <input
          name="name"
          width="200px"
            type="text"
            class="form-control"
            placeholder="name"
            v-model="name"
        
 >

              </div>
              <div             
                class="picview"
                >
              <button @click="picsCreate">Создать</button>
              
            </div>
                <table class="table">
                 
              <thead>
                 <tr> Список подкаталогов</tr>
                <tr>
                    <th>  
                      Описание подкаталога          
                  </th>
                  <th>  
                      id          
                  </th>
                  <th>  
                        Имя         
                  </th>
                  <th>  
                        root_id    
                  </th>
                    
                </tr>
              </thead>
              <tbody>
                <tr
                 v-for:="pic in picview" 
                 
                >
                                 
                    <td 
                    scope="row" 
                     @click="neuroclick(pic.id)"  >
                     {{pic.descr}}</td>
                    <td
                                      
                    >{{pic.id}}</td>
                    <td>{{pic.name}}</td>
                    <td>{{pic.root_id}}</td>
                   
                </tr>
              </tbody>
           </table>
           <!-- <button @click="pictest">qwerty</button> -->

                
        </div>
   


</template>

<script>
//import axios from "axios"
import api from "../../JS/api.js"
    export default{
       
        data(){
            return{
                accessToken: null,
                neuronet: null,
                 image : null,
                file: null,
                picview: null,

        }
        },

        mounted(){
            this.getAccessToken()
            console.log(this)
            this.neuronetIN(this) 
            this.picVue(this)
        },

        updated(){
            this.getAccessToken()
        },

        methods:{
            
            getAccessToken() {
                this.accessToken = localStorage.getItem('accessToken')
            },

            neuronetIN(this2){  //picfolders вывести  папку
                let result = this.$route.query.folder
                const options2 = {
            method: 'GET',
            url: 'http://185.221.152.242:5500/api/picfolder/' + result ,
            
          };
          
          api.request(options2).then(function (response) {
          
           console.log(this2)
            console.log(response.data);
              this2.neuronet = response.data; 
                                      
          }) 
                  
          .catch(function (error) {
            console.error(error);
            console.log(options2)
          });
        },

        folderUpdate(){ // изменить папку
            let result = this.$route.query.folder
                const options2 = {
            method: 'POST',
            url: 'http://185.221.152.242:5500/api/picfolder/' + result ,
            data: {
                descr: this.descr, 
                name: this.name
            }
          };
          
          api.request(options2).then(function (response) {
          
            console.log(this.descr)
            console.log(response.data);
            window.location.reload();                      
          }) 
                  
          .catch(function (error) {
            console.error(error);
            console.log(options2)
          });
        },
        folderDelate(){ //удалить папку
            let result = this.$route.query.folder
                const options2 = {
            method: 'DELETE',
            url: 'http://185.221.152.242:5500/api/picfolder/' + result ,
           
          };
          api.request(options2).then(function (response) {
          
           
            console.log(response.data);
                                      
          }) 
                  
          .catch(function (error) {
            console.error(error);
            console.log(options2)
          });
        },

      

    picVue(this2){ //просмотр подкаталогов каталога
        let result = this.$route.query.folder
        const picvue1 = {
        method: 'GET',
        url: 'http://185.221.152.242:5500/api/picfolders/' + result,        
        };
            api.request(picvue1).then(function (response) {
            console.log(response.data);
            console.log(picvue1)
            this2.picview = response.data
            let pic = response.data
            console.log(pic)
            }).catch(function (error) {
            console.error(error);
            });
            },

            neuroclick( id){
          console.log('qwerty')
          console.log(id);
          this.$router.push( {path: 'picDescr', query: { 'picfolder': id }})
      
            },

            picsCreate(){
              let result = this.$route.query.folder
              const options = {

              method: 'POST',
              url: 'http://185.221.152.242:5500/api/picfolders/'+ result,
              
              data: {descr: this.descr,  name: this.name}
            };

            api.request(options).then(function (response) {
              console.log(response.data);
              
              window.location.reload();  
            }).catch(function (error) {
              console.error(error);
            });
            },
            pictest(){
              let qwerty = {
               data: {filename: this.descr,  picname: this.name}
              }
              console.log(qwerty,'12344')
            }


        }        
}
    
</script>

<style scoped>

.picview{
  margin-top: 3%;
    margin-left: 4%;
    margin-bottom: 3%;
}
  .imguploader{
    margin-top: 3%;
    margin-left: 4%;
  }
 .link{
      margin-top: 1%;
      margin-left: 3%;
      
    }
.input{
    display: flex;
    margin-top: 3%;
    margin-left: 4%;
}
.content{
    margin-top: 3%;
    margin-left: 4%;
}
.button{
    margin-top: 3%;
    margin-left: 4%;
    
}
</style>