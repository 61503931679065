<template>
    <heder/>
     <create/>
    <div class="body">
       
        <div class="content">
            <div class="imgbox">
                
            
            <img class="img" src="./ikons/ucastok.png">
            
            <div class="hrefbox1">
                <p>
                     Рокарий
                </p>
                <div class="poloska1">

                </div>
                <div class="krug1">

                </div>
                <div class="zona1">

                </div>
            </div>
         

         
            <div class="hrefbox2">
                <p>
                    Древесно-кустариниковая<br> композиция
                </p>
                <div class="poloska2">

                </div>
                <div class="krug2">

                </div>
            </div>
         
             <a > 
            <div class="hrefbox3">
                <div class="box">
                   <p>
                    Грядка
                </p> 
                </div>
                 <div class="hidden-block">            

                    источник наслаждения  для настоящих садистов
                    </div>
                       
                
                <div class="poloska3">

                </div>
                <div class="krug3">

                </div>
            

            </div>
            </a>

            <!-- </a> -->

            
            <div class="hrefbox4">
                <p>
                    <!-- Создать<br>
                    древесно-<br>
                    кустарниковую<br>
                     композицию -->
                     Цветник
                    
                </p>
                <div class="poloska4">

                </div>
                <div class="krug4">

                </div>
            </div>
           

            </div>
        </div>
       
    </div>
 <div class="foot">
    <!-- <div class="contentfoot">
            <button v-on:click="modal(openModal)" class="btn button1" >    
                Создать

            </button>
            <button data-trigger>Open modal</button>
        </div>
       ---------------- 

        <div class="modal-container"> 

        <div class="modal-content">
            <button class="close">Закрыть</button>
            <p>Много контента для скролла</p>
        </div>
        </div> -->
        <foter/>
        </div>
      
</template>

<script>
import heder from "./components/hederTree.vue";
import create from "./components/leftCreate.vue";
 import foter from "./components/footerCreate.vue";
export default{
    components:{
        heder,
          foter,
        create,
       
    
    },
    methods :{

    modal(){
       (function(){
            var modal = document.querySelector('.modal-container');
            var closeButton = document.querySelector('.close');
            var modalTriggers = document.querySelectorAll('[data-trigger]');

            var isModalOpen = false;
            var pageYOffset = 0;

            var openModal = function() {
                pageYOffset = window.pageYOffset;
                modal.classList.add('is-open');
                isModalOpen = true;
            }

            var closeModal = function() {
                modal.classList.remove('is-open');
                isModalOpen = false;
            }

            var onScroll = function(e) {
                if (isModalOpen) {
                e.preventDefault();
                window.scrollTo(0, pageYOffset);
                }
            }

            modalTriggers.forEach(function(item) { 
                item.addEventListener('click', openModal);
            })

            document.addEventListener('scroll', onScroll);

            closeButton.addEventListener('click', closeModal);
            })();
    },

    
}


}
</script>

<style scoped>
.foot{
    margin-top: 5%;
}
.img{
    width: 40%;
    position: relative;
    margin-left: 5%;
    margin-top: 5%;
    
}

.hrefbox1{
    font-size: 24px;
    position: absolute;
    width: 200px;
    height: 70px;
    background-color: rgb(68, 68, 68);
    color: white;
    text-align: center;
    border-radius: 15px;
    margin-left: 35%;
    margin-top: -450px;
    /* position: fixed; */
}
.hrefbox1 p{
    margin-top: 4%;
}
/*.zona1{
    width: 50px;
    height: 20px;
    margin-left: 10%;
    background-color: aliceblue;
}
 .hrefbox1 div.zona1:hover {
    background-color: aqua;
} */

.hrefbox2{
    font-size: 24px;
    position: absolute;
    width: 300px;
    height: 100px;
    background-color: rgb(68, 68, 68);
    color: white;
    text-align: center;
    border-radius: 15px;
    margin-left: 75%;
    margin-top: -600px;
    position: fixed;
}
.hrefbox2 p{
    margin-top: 4%;
}
.hrefbox3{
    font-size: 24px;
    position: absolute;
    width: 300px;
    height: 70px;
    background-color: rgb(68, 68, 68);
    color: white;
    text-align: center;
    border-radius: 15px;
    margin-left: 35%;
    margin-top: -150px;
    position: fixed;
}
.hrefbox3 p{
    margin-top: 4%;
}
.hrefbox4{
    font-size: 24px;
    position: absolute;
    width: 250px;
    height: 70px;
    background-color: rgb(68, 68, 68);
    color: white;
    text-align: center;
    border-radius: 15px;
    margin-left: 60%;
    margin-top: -10px;
    position: fixed;
}
.hrefbox4 p{
    margin-top: 4%;
}
.hrefbox p{
    margin-top: 3%;
}
.content{
    margin-top: 4%;
    widows: 100%;
    height: 100%;
}
.imgbox{
    margin-top: -4%;
    width: 100%;
    height: 100%;
}
.imgbox a{
    /* position: fixed; */
    z-index: 13;
}
/* КРУЖКИ И ПОЛОСКИ */
.poloska1{
    background-color: rgb(68, 68, 68) ;
    width: 250px;
    height: 5px;
    margin-top: -10%;
    margin-left: 80%;
    transform: rotate(15deg);
}
.krug1{
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background-color: rgb(68, 68, 68) ;
    margin-left: 197%;
    margin-top: 10%;
}

.poloska2{
    background-color: rgb(68, 68, 68) ;
    width: 140px;
    height: 5px;
    margin-top: 23%;
    margin-left: -10%;
    transform: rotate(300deg);
}
.krug2{
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background-color: rgb(68, 68, 68) ;
    margin-left: 0%;
    margin-top: 15%;
}
.poloska3{
    background-color: rgb(68, 68, 68) ;
    width: 150px;
    height: 5px;
    margin-top: -65%;
    margin-left: 55%;
    transform: rotate(-30deg);
}

.hidden-block{
    width: 300px;
    background-color: rgb(68, 68, 68) ;
color:#fff;
font-size: 18px;
border:3px solid rgb(68, 68, 68);
border-radius: 0 0 15px 15px ;
padding:20px;
margin:auto;
text-align:center;
opacity:0; /*Элемент полностью прозрачный (невидимый)*/
transition: 1s; /*Скорость перехода состояния элемента*/
}
.box:hover+ .hidden-block{
opacity:1;
transition: 0.5s;
}

.krug3{
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background-color: rgb(68, 68, 68) ;
    margin-left: 100%;
    margin-top: -18%;
}
.poloska4{
    background-color: rgb(68, 68, 68) ;
    width: 150px;
    height: 5px;
    margin-top: -50%;
        margin-left: 40%;
    transform: rotate(97deg);
}
.krug4{
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background-color: rgb(68, 68, 68) ;
    margin-left: 70%;
    margin-top: -35%;
}
@media screen and (max-width: 1800px) {
    .hrefbox1{
        margin-top: -400px;
    }
    .img{
    margin-top: 20%;
    }
    .imgbox{
    margin-top: -4%;
    width: 100%;
    height: 100%;
    
}
.imgbox a{
    /* position: fixed; */
    z-index: 13;
}
/* КРУЖКИ И ПОЛОСКИ */
.poloska1{
    background-color: rgb(68, 68, 68) ;
    width: 70%;
    height: 5px;
    margin-top: 15%;
    margin-left: 80%;
    transform: rotate(55deg);
}
.krug1{
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background-color: rgb(68, 68, 68) ;
    margin-left: 130%;
    margin-top: 20%;
}
.hrefbox2{
    margin-top: -470px;
}
.poloska2{
    background-color: rgb(68, 68, 68) ;
    width: 140px;
    height: 5px;
    margin-top: 23%;
    margin-left: -10%;
    transform: rotate(300deg);
}
.krug2{
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background-color: rgb(68, 68, 68) ;
    margin-left: 0%;
    margin-top: 15%;
}
.poloska3{
    background-color: rgb(68, 68, 68) ;
    width: 200px;
    height: 5px;
    margin-top: -88%;
    margin-left: 55%;
    transform: rotate(-30deg);
}
.hrefbox3{
    margin-top: -75px;
    margin-left: 27%;
}

.hidden-block{
    width: 200px;
    background-color: rgb(68, 68, 68) ;
color:#fff;
font-size: 18px;
border:3px solid rgb(68, 68, 68);
border-radius: 0 0 15px 15px ;
padding:20px;
margin:auto;
text-align:center;
opacity:0; /*Элемент полностью прозрачный (невидимый)*/
transition: 1s; /*Скорость перехода состояния элемента*/
}
.box:hover+ .hidden-block{
opacity:1;
transition: 0.5s;
}
.krug3{
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background-color: rgb(68, 68, 68) ;
    margin-left: 115%;
    margin-top: -21%;
}
.hrefbox4{
    margin-left: 55%;
    margin-top: 50px;
}
.poloska4{
    background-color: rgb(68, 68, 68) ;
    width: 150px;
    height: 5px;
    margin-top: -50%;
        margin-left: 40%;
    transform: rotate(97deg);
}
.krug4{
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background-color: rgb(68, 68, 68) ;
    margin-left: 70%;
    margin-top: -35%;
}
.foot{
    margin-top: 12%;
}
}

/* ============ */
.modal-container {
  position: fixed;
  left: 0;
  top: 0; 
  display: flex;
  width: 100vw;
  height: 100vh; 
  padding: 30px;
  background-color: rgba(0, 0, 0, 0.5);
}
.modal-content {
  overflow: scroll;
  width: 100%; 
  height: 100%; 
  max-width: 500px; 
  max-height: 400px; 
  background-color: #fff; 
}
.close {
 position: absolute;
 right: 0;
 top: 0;
 width: 30px;
 height: 30px;
}
.modal-container { 
  pointer-events: none; 
  opacity: 0;
}
.modal-container.is-open { 
  pointer-events: all; 
  opacity: 1;
}
</style>
