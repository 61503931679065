<template>
   
    <div class="body">
         <heder/>
    <hederd/>
    <div class="content">
        <div class="tblock1">
            <p>
                Группа тегов 1
            </p>
            <label class="checkbox-btn">
            <input type="checkbox" checked>
            <span>Скульптуры</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" checked>
            <span>Вазоны</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" checked>
            <span>Розы</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" checked>
            <span>Фонтан</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" checked>
            <span>Пальмы</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" checked>
            <span>Зеркала</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" checked>
            <span>Скульптуры</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" checked>
            <span>Вазоны</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" checked>
            <span>Розы</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" checked>
            <span>Фонтан</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" checked>
            <span>Пальмы</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" checked>
            <span>Зеркала</span>
            </label>
        </div>
        <div class="tblock2">
            <p>
                Группа тегов 2
            </p>
            <label class="checkbox-btn">
            <input type="checkbox" checked>
            <span>Скульптуры</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" checked>
            <span>Вазоны</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" checked>
            <span>Розы</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" checked>
            <span>Фонтан</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" checked>
            <span>Пальмы</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" checked>
            <span>Зеркала</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" checked>
            <span>Скульптуры</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" checked>
            <span>Вазоны</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" checked>
            <span>Розы</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" checked>
            <span>Фонтан</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" checked>
            <span>Пальмы</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" checked>
            <span>Зеркала</span>
            </label>
        </div>
        <div class="tblock3">
            <p>
                Группа тегов 3
            </p>
            <label class="checkbox-btn">
            <input type="checkbox" checked>
            <span>Скульптуры</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" checked>
            <span>Вазоны</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" checked>
            <span>Розы</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" checked>
            <span>Фонтан</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" checked>
            <span>Пальмы</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" checked>
            <span>Зеркала</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" checked>
            <span>Скульптуры</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" checked>
            <span>Вазоны</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" checked>
            <span>Розы</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" checked>
            <span>Фонтан</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" checked>
            <span>Пальмы</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" checked>
            <span>Зеркала</span>
            </label>
        </div>
    </div>
    </div>
</template>

<script>
import hederd from "./components/hederDuo.vue";
import heder from "./components/hederTree.vue";

export default{
    components:{
        heder,
        hederd
    }

}
</script>

<style scoped>
.content{
    margin-top: 0;
}

.checkbox-btn {
	display: inline-block;
	margin-top: 2%;
    margin-left: 2%;
	user-select: none;
	position: relative;
    /* border-radius: 25px; */

}
.checkbox-btn input[type=checkbox] {
	z-index: -1;
	opacity: 0;
	display: block;
	width: 0;
	height: 0;
    
}
.checkbox-btn span {
	display: inline-block;
	cursor: pointer;
	padding: 0px 10px;
	line-height: 30px;
	border: 1px solid #999;
	border-radius: 24px;
	transition: background 0.2s ease;
}
 
/* Checked */
.checkbox-btn input[type=checkbox]:checked + span {
	background: #8d8d8d;
}

.tblock label {
   margin-left: 2%;
}
.tblock1{
    width: 32%;
    background-color: #ffffff;
    margin-left: -9%;
    margin-top: 2%;
    justify-content: left;
    padding: 5px;
    float: left;
}
.tblock2{
    width: 32%;
    background-color: #ffffff;
    margin-left: 0%;
    margin-top: 2%;
    justify-content: left;
    padding: 5px;
    float: left;
}
.tblock3{
    width: 32%;
    background-color: #ffffff;
    margin-left: 0%;
    margin-top: 2%;
    justify-content: left;
    padding: 5px;
    float: left;
}
.body{
    /* display: flex; */
    
    font-family: "Montserrat-Regular";
}

</style>
