<template>
    <div class="body">
        <HederUno/>
        <tophead/>
        <div class="content">
            <div class="contenthed">
                <p>
                    Мы ищем специалистов в команду!                </p>
                <label>
                    Нам в команду требуются специалисты разного профиля: ландшафтные дизайнеры, разработчики, специалисты по маркетингу. Если Вы обладаете одной из этих компетенций и вам интересен наш проект - оставьте ваши контакты, и мы с Вами свяжемся!                </label>
            </div><br>
            <div class="contentbody">
                <input type="text" placeholder="Напишите Ваше имя"><br>
                <input type="text" placeholder="Какие у вас компетенции"><br><br><br>
                <label>Что бы Вы хотели делать в рамках проекта?</label>
                <textarea class="txt" placeholder="Напишите Ваш вопрос или предложение" >

                </textarea>
                <a>
                <button class="btn send">
                    Отправить
               </button>
              </a>
              <p class="sogl">
                *Нажимая "Отправить" Вы соглашаетесь с политикой обработки персональных данных
              </p>
            </div>
        </div>
        <boost/>
        <Footer/>
</div>
</template>

<script>
import HederUno from './hederUno.vue'
import Footer from './footerUno.vue'
import boost from './components/components/boostUno.vue'
import tophead from './components/components/topHead.vue'
export default{
    components:{
        HederUno,
        Footer,
         boost,
        tophead,
        
    },


}
</script>

<style scoped>


.sogl{
    font-size: 15px;
    margin-left: 10%;
    margin-top: 3%;
}
.send{
    margin-top: 5%;
    font-size: 25px;
    width: 40%;
    height: 60px;
    border-radius: 30px;
    margin-left: 31%;
    background-color: #a0b19a;
}
.txt{
    width: 100%;
    height: 200px;
    /* margin-top: 5%; */
    padding: 5px;
}
.contentbody input{
    width: 100%;
    height: 50px;
    margin-top: 5%;
    padding: 5px;
}
.contentbody{
    width: 70%;
    margin-left: 13%;
    
}
.contentbody label{
    font-size: 24px ;
}
.contenthed label{
    font-size: 24px ;
}

.content{
    width: 60%;
    margin-left: 20%;
    text-align: left;
    margin-top: 4%;
}
.contenthed p{
   font-family: 'Montserrat-Bold';
    font-size: 36px ;
}
.sogl{
    font-size: 18px;
}
.body{
    font-family: 'Montserrat-Regular';
}
</style>
