<template>
    <heder/>
    <div class="body">
        <div class="leftmenu">
            <left/>
        </div>
        
        
        <div class="conten1t1">
          <content1/>
        </div>
    </div>
</template>

<script>
import content1 from "./component/contentUno.vue";
import heder from "/src/component/pages/components/hederUno.vue";
import left from "./component/leftMenu.vue";

export default{
    components:{
        content1,
        heder,
        left,
        
    }
}

</script>

<style scoped>
.lcontent{
    margin-left: 0%;
    width: 100%;
   
}
.hederLM{

}
.leftmenu{
    width: 20%;
    height: 100%;
    margin-left: 2%;
    float: left;
}
.conten1t1{
    float: left;
}
.body{
    margin-top: 50px;
    font-family: "Montserrat-Regular";
    
}
</style>
