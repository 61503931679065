<template>
    <div class="body">
        <HederUno/>
        <tophead/>
            <div class="content">
                <P>
                    Наш проект еще находится в разработке
                </P>
                <label>
                    Мы - молодой стартап, и еще ведем работу над нашим проектам, поэтому пока не готовы Вам его представить. если Вам интересно, вы можете задать нам вопрос или оставить свои контакты и предложения. Как только мы выпустим продукт - Вы будете первым, кто получит к нему доступ
                </label>
                <div class="bntcont">
                    <button type="button" class="btn btn-outline-secondary">
                        Задать вопрос
                    </button>
                    <button class="btn button2" >
                        Поддержать проект 
                    </button>
                    <button class="btn button3">
                        Предложить партнёрство
                    </button>
                   
                </div>
               
            </div>
            <div class="zelpoloska">
                 
                 <div>
                  <br>
                        <p>
                            Мы будем благодарны, если Вы немного поможете нам в разработке проекта
                        </p>
                        <label>
                            Ответьте, пожалуйста, на несколько вопросов.
Мы в долгу не останемся. Точно.
                        </label>
                    </div> <br>
            </div>
        <quest/>
        <boost/>
        <Footer/>
    </div>
</template>

<script>
import HederUno from './hederUno.vue'
import Footer from './footerUno.vue'
import boost from './components/components/boostUno.vue'
import tophead from './components/components/topHead.vue'
import quest from './components/components/questsUno.vue'
export default{
    components:{
        HederUno,

        Footer,
        boost,
        tophead,
        quest,
    },
}
</script>

<style scoped>
.zelpoloska label{
    font-size: 25px ;
    margin-left: 0%;
   width: 40%;
}
.qwer {
margin-top: 4%;
    
   
}
.zelpoloska p{
    margin-left: 30%;
   width: 40%;
   font-family: 'Montserrat-Bold';
    text-align: center;
    margin-top: 4%;
    font-size: 30px ;
}
.zelpoloska{
 
    background-color: #a0b19a;
    text-align: center;
    margin-top: 4%;
    height: 360px;
}
.button3{
    width: 23%;
    min-width: 350px;
    height: 60px;
    border-radius: 35px;
    background-color: #a0b19a;
    color: aliceblue;
    margin-left: 2%;
    
}
.button2{
    width: 23%;
    min-width: 350px;
    height: 60px;
    border-radius: 35px;
    background-color: black;
    color: aliceblue;
    margin-left: 2%;
}

.btn-outline-secondary{
    width: 23%;
    min-width: 350px;
    height: 60px;
    border-radius: 35px;

}
.bntcont{
    margin-top: 4%;
}
.content label{
    font-size: 25px ;
}
.content p{
    font-family: 'Montserrat-Bold';
    font-size: 30px ;
}
.content{
    width: 60%;
    margin-left: 20%;
    text-align: center;
    margin-top: 4%;
}

.body{
    font-family: 'Montserrat-Regular';
}

</style>
