<template>
    <heder/> 
    <!-- <hederd/> -->
        <div class="heder">
        <br>
        <div class="up">
                <a href="#constructor">
                <p><img src="./ikons/strelkanazad1.png">  Назад</p>
                </a>
                <!-- <p class="upp">
                  <strong>
                     Профиль проекта
                  </strong> 

                </p>
                <div class="krug2">
                        
                        <p>4</p> 
                 </div> -->
            </div>  
        <!-- <div class="up">
                <a href="#alias">
                <p><img src="./ikons/strelkanazad1.png">  Назад</p>
                </a>
                
               
            </div> -->
            <div class="bottom">
               <div class="blockp1">
                    <div class="p1">
                        <strong>
                            Без названия
                        </strong>
                    </div>
                </div> 
               <!-- <div class="versions">
                    <p>
                    Версия 1
                </p>
                <p>
                    Версия 2
                </p>
               
                <p>
                    Версия 2
                </p> 
                </div> -->

            </div>
            <br>
        </div> 
        <div class="heder2">
<!-- 
<a href="#shablon">
         <div class="p2">
        Результат генерации
    </div>  
    </a>         
 
    <div class="p2">
        Основные данные
    </div>
    <a href="#visual">
       <div class="p2">
        Визуальные предпочтения
    </div>  
    </a>
   
    <a href="#docs">
    <div class="p2">
        Документация
    </div>
     
    </a>
     <a href="#docs">
    <div class="p2">
        Подборки растений
    </div>
    </a> -->


</div><br><br>

    <div class="body">
        <div class="tegs">
            <label class="checkbox-btn1">
            <input type="checkbox" >
            <span>Найти дешевле</span>
            </label>
            <label class="checkbox-btn1">
            <input type="checkbox" >
            <span>Найти ближе</span>
            </label>
            <label class="checkbox-btn1">
            <input type="checkbox" checked>
            <span>Найти проверенного</span>
            </label>
            <label class="checkbox-btn1">
            <input type="checkbox" >
            <span>Найти в одном месте</span>
            </label>
        </div>
            
        <div class="content">
           
           <div class="lcontent">
            <div class="order">
                <p>
                    <strong>
                        Заказ 1 
                    </strong>
                   
                </p>
                <label>
                    Адрес и реквизиты СЦ или питомника
                </label>
                <table>
                    <tr>
                        <td>
                            <input class="cbx" type="checkbox">
                        </td>         
                        <td>
                            <img class="ikonimg" src="./ikons/flowers.jpeg">
                        </td>  
                        <td class="info">
                            <p>
                                Название 1 
                            </p>
                            <label class="lb1">
                                Краткое описание растения и кнопка
                            </label>
                            <label class="lb1">
                                В продаже: перечисление питомников и СЦ
                            </label>
                        </td>  
                        <td class="tdtifr">
                            <div class='count_box'>
                                <div class="minus">-</div>
                            <input class='inp_price' type="text" value="1"/>
                            <div class="plus">+</div>
                            </div> 
                        </td>    
                        <td class="tdtifr">
                            3060p
                        </td>
                    </tr>
                </table>
            </div><br>
            <div class="order">
                <p>
                    <strong>
                        Заказ 1 
                    </strong>
                   
                </p>
                <label>
                    Адрес и реквизиты СЦ или питомника
                </label>
                <table>
                    <tr>
                        <td>
                            <input class="cbx" type="checkbox">
                        </td>         
                        <td>
                            <img class="ikonimg" src="./ikons/flowers.jpeg">
                        </td>  
                        <td>
                            <p>
                                Название 1 
                            </p>
                            <label>
                                Краткое описание растения и кнопка
                            </label>
                            <label>
                                В продаже: перечисление питомников и СЦ
                            </label>
                        </td>  
                        <td class="tdtifr">
                            <div class='count_box'>
                                <div class="minus">-</div>
                            <input class='inp_price' type="text" value="1"/>
                            <div class="plus">+</div>
                            </div> 
                        </td>    
                        <td class="tdtifr">
                            3060p
                        </td>
                    </tr>
                </table>

                
            </div>
                <div class="lfooter">
                        <label class="checkbox-btn">
                        <input type="checkbox" checked >
                        <span>
                            
                            <p>
                            <input type="radio" checked>    Доставка                             </p>
                            <label>
                                Наша служба доставки привезёт заказ домой, в офис или на дачу
                            </label>
                           
                        </span>
                        </label>
                        <label class="checkbox-btn">
                        <input type="checkbox"  >
                        <span>
                            
                            <p>
                            <input type="radio" >    Самовывоз                             </p>
                            <label>
                                Заберите заказ бесплатно из магазина Леруа Мерлен или пункта самовывоза
                            </label>
                           
                        </span>
                        </label>
                    </div>
            </div>
            
            <div class="rcontent">
                <div class="rbox">
                 <br>
                    <div class="hedp">
                        
                        <input type="checkbox"> 
                        
                             Я юридическое лицо
                       
                       
                 
                    </div>
                    
                    <table>
                        <tr>
                            <td>
                                <label>Товары (8) - 68.50 кг </label>

                            </td>
                            <td>
                                10 866
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>
                                    Итого
                                </p>
                            </td>
                            <td>
                                <p>
                                    10866
                                </p>
                            </td>
                        </tr>
                    </table>
                    <button class="btn gotoorder">
                        <strong>
                              Перейти к оформлению
                        </strong>
                      
                    </button>
                   <p class="footp">
                        Дата и стоимость доставки или самовывоза определяются при оформлении заказа
                   </p>
                </div>
                <button class="btn replayorder">
                    <strong>
                        Создать заказ самостоятельно
                    </strong>
                 
                </button>
            </div>
           


        </div>
           
       
    </div>
    
</template>



<script>
import $ from "jquery";
import heder from "./components/hederTree.vue";
import axios from "axios";
// import filter1 from "./components/filtrModal.vue";
//import hederd from "./components/hederDuo.vue";

export default{
    components:{
     // hederd,
     
        heder,

       
        
    },
    mounted(){
           
           console.log(this,'1') 
            console.log(this)
        this.calculator(this)  //использовать для прорисовки по умолчанию
   },
    methods :{
        calculator(){
            
            
            $(document).ready(function() {
    $('.minus').click(function () {
        var $input = $(this).parent().find('input');
        var count = parseInt($input.val()) - 1;
        count = count < 1 ? 1 : count;
        $input.val(count);
        $input.change();
        return false;
    });
    $('.plus').click(function () {
        var $input = $(this).parent().find('input');
        $input.val(parseInt($input.val()) + 1);
        $input.change();       
        return false;
    });
    
  
});

        },

        openmodal1(){
            // Открыть модальное окно
                document.getElementById("open-modal-btn").addEventListener("click", function() {
                    document.getElementById("my-modal1").classList.add("open")
                })

                // Закрыть модальное окно
                document.getElementById("close-my-modal-btn").addEventListener("click", function() {
                    document.getElementById("my-modal1").classList.remove("open")
                })

                // Закрыть модальное окно при нажатии на Esc
                window.addEventListener('keydown', (e) => {
                    if (e.key === "Escape") {
                        document.getElementById("my-modal1").classList.remove("open")
                    }
                });

                // Закрыть модальное окно при клике вне его
                document.querySelector("#my-modal1 .modal__box").addEventListener('click', event => {
                    event._isClickWithInModal = true;
                });
                document.getElementById("my-modal1").addEventListener('click', event => {
                    if (event._isClickWithInModal) return;
                    event.currentTarget.classList.remove('open');
                });
                        },

        search(){
            const options = {
            method: 'GET',
            url: 'http://185.221.153.253:5700/api/search',
            headers: {Authorization: 'Bearer 0ded13aa-624b-40cf-bef7-dc7a2bf97c2c'},
            data: {
                f_compos_type: this.valuesearch,
                f_template: this.valuesearch,
                f_temp_char: this.valuesearch,
                f_place: this.valuesearch,
                f_ground_sost: this.valuesearch,
                f_ground_kisl: this.valuesearch,
                f_ct_size: this.valuesearch,
                f_ct_form: this.valuesearch,
                f_ct_light: this.valuesearch,
                f_ct_color: this.valuesearch,
                f_ct_maf: this.valuesearch,
                f_ct_vl: this.valuesearch,
                f_ct_res: this.valuesearch,
                f_ct_aroma: this.valuesearch,
                f_ct_plants: this.valuesearch
            }
            };

            axios.request(options).then(function (response) {
            console.log(response.data);
            }).catch(function (error) {
            console.error(error);
            });
        }

        }
}
</script>

<style scoped>


.tegs{
    display: flex;
}
.versions{
    display: flex;
    text-align: center;
    margin-top: -1.5%;
    margin-left: 37%;
}
.versions p{
    margin-left: 3%;
    width: 85px;
    font-size: 16px;
}

.hedp{
    text-align: left;
    background-color: white;
    width: 70%;
    margin-top: 1%;
    margin-left: 12%;
    border-radius: 5px;
    font-size: 24px;
    min-height: 40px;
}

.hedp input{
    margin-left: 2%;
    
}
.gotoorder{
    background-color: #16183f;
    text-align: center;
    width: 75%;
    min-width: 200px;
    height: 55px;
    margin-top: 4%;
    color: white;
}

.footp{
    text-align: left;
    width: 80%;
    margin-top: 2%;
    margin-left: 12%;
}
.replayorder{
    min-width: 200px;
    width: 75%;
    min-width: 200px;
    height: 55px;
   margin-left: 2%;
   background-color: #D9D9D9;;
   border-radius: 5px;
   margin-top: 4%;

}
.order table{
    margin-top: 2%;
    text-align: left;
    margin-bottom: 2%;
}
.ikonimg{
    
    width: 132px;
}

.order{
    padding: 5px;
    background-color: #D9D9D9;
    border-radius: 25px;
   
}
.order p{
    margin-left: 2%;
    font-size: 24px;
    text-align: left;
}
.order label{
    margin-left: 2%;
    text-align: left;

}
.rbox{
    background-color: #f1f1f1;
    margin-left: 3%;
    border-radius: 25px;
    text-align: center;
    min-height: 350px;
}
.rbox table{
   
    margin-top: 6%;
    text-align: left;
    margin-left: 15%;
}

.rbox table p{
    font-size: 24px;
}
.rcontent{
    width: 35%;
    text-align: center;
    margin-left: 2.5%;
}
.lcontent{
    border-radius: 25px;
    margin-left: 2%;
    width: 55%;
   
}
.p2{
    float: left;
    margin-left: 1%;
    font-size: 16px;
    margin-top: 0.5%;
    text-align: center;
    width: 240px;
    height: 19px;
}
.heder2{
    text-align: center;
    justify-content: center;
    align-content: center;
    margin-left: 18%;
}
.heder2 a{
    text-decoration: none;
    color: black;
}
.content table{
    /* text-align: center; */
 
    width: 100%;
}
.tdtifr{
    min-width: 130px;
}
.info{
    
    /* text-align: left;
    display: block;
    justify-content: left; */
}
.cbx{
    width: 30px;
}
.lb1{
    text-align: left;
 
}
.content{
    width: 100%;
    display: flex;
    margin-top: 0.5%;
}

.bottom{
    text-align: left;
    font-size: 24px;
    margin-left: 2%;
}
.up input{
    float: left;
   
}
.up{
    display: flex;
    margin-top: -0.2%;
}

.heder{
    width: 98%;
    margin-top: 0%;
    text-align: center;
}
.heder a{
    text-align: center;
    justify-content: center;
    align-content: center;
}
.body{
    height: 120%;
    margin-top: 0px;
    font-family: "Montserrat-Regular";
}
/* Heder */

.heder{
    margin-left: 2%;
    margin-top: -2px;
    font-family: "Montserrat-Regular";
}


.up a{
    text-decoration: none;
    color: black;
}


/* kol-vo */
.minus {
        cursor: pointer;
        width:25px;
        float:left;
        text-align:center;
        border-radius: 3px;
        border:1px solid gray;
    }
    .plus {
        cursor: pointer;
        width:25px;
        float:right;
        text-align:center;
        border:1px solid gray;
        /* margin-top: -25px; */
    }
    
    .inp_price {
        width:40px;
        text-align:center;
        border:1px solid gray;
        /* border-left:1px solid gray;
        border-right:1px solid gray; */
    }
    
    .count_box {
        width:90px;
        
        /* border:1px solid gray;   */
    }
    /* ===================== */
    /* ===================== */
    /* ===================== */
    /* check-box */
    .lfooter{
        width: 100%;
        display: flex;
    }
    .checkbox-btn {
	display: inline-block;
	margin-top: 1%;
    margin-left: 2%;
	user-select: none;
	position: relative;
    width:  49%;
    height: 125px;
    /* border-radius: 25px; */

}
.checkbox-btn input[type=checkbox] {
	z-index: -1;
	opacity: 0;
	display: block;
	width: 100%;
    height: 0;
    background: #8d8d8d;
}
.checkbox-btn span {
	display: inline-block;
	cursor: pointer;
	padding: 0px 10px;
    /* background: #8d8d8d; */
	line-height: 30px;
	border: 1px solid #999;
	border-radius: 25px;
	/* transition: background 0.2s ease; */
    width:  100%;
    height: 125px;
    padding: 5px;
    
}
 
/* Checked */
.checkbox-btn input[type=checkbox]:checked + span {
	/* background: #f5f5f5; */
    color: rgb(0, 0, 0);
    border: 1px solid #ff5151;
}

.checkbox-btn1 {
	display: inline-block;
	margin-top: 1%;
    margin-left: 2%;
	user-select: none;
	position: relative;
    width: auto;
    height: auto;
    /* border-radius: 25px; */

}
.checkbox-btn1 input[type=checkbox] {
	z-index: -1;
	opacity: 0;
	display: block;
	width: auto;
    height: 0;
    background: #D9D9D9;
    background-color: #D9D9D9;

}
.checkbox-btn1 span {
	display: inline-block;
	cursor: pointer;
	padding: 0px 10px;
    /* background: #8d8d8d; */
	line-height: 30px;
	border: 1px solid #999;
    background-color: #D9D9D9;

	border-radius: 25px;
	/* transition: background 0.2s ease; */
    width:  auto;
    height: auto;
    padding: 5px;
    text-align: center;
    
}
 
/* Checked */
.checkbox-btn1 input[type=checkbox]:checked + span {
	/* background: #f5f5f5; */
    background-color: #706D6D;
    color: rgb(255, 255, 255);
    border: 1px solid #706D6D;
}
</style>
