<template>
    <heder/>
   
    <div class="body">
        <div class="heder">
        <br>
            <div class="up">
                <a href="#constructor">
                <p><img src="./ikons/strelkanazad1.png">  Назад</p>
                </a>
                
            </div>
            <div class="bottom">
                Выберите растения в садовых центрах России
              
               

            </div>
        </div> 
        <div class="content">
            <p>
                <strong>
                    Мои заказы
                </strong>                
            </p>
            <u>
                Название проекта
            </u>
            <div class="cont-inp">

           
            <div class="object">
                <div class="img">

                </div>
                <label>
                    Астильба 
                    Арендса
                    15 шт.
                </label>
            </div>
            <div class="object">
                <div class="img">

                </div>
                <label>
                    Астильба 
                    Арендса
                    15 шт.
                </label>
            </div>
            
        </div>
        </div>


    </div>
</template>

<script>
import heder from "./components/hederTree.vue";

export default{
    components:{
        heder,
        
    }

}
</script>

<style scoped>
.cont-inp{
    display: flex;
    margin-top: 1%;
    border-bottom: 1px solid #000000;
    width: 98%;
}
.img{
    width: 200px;
    height: 200px;
    background-color: rgb(165, 165, 165);
    margin-left: 5px;
}
.object{
    width: 250px;
    margin-left: 2%;
}
.content{
    margin-left: 2%;
    font-size: 18px;
}
.content p{
    font-size: 24px;
}

.heder{
    margin-left: 2%;
    margin-top: -2px;
    font-family: "Montserrat-Regular";
}

.up a{
    text-decoration: none;
    color: black;
}
</style>