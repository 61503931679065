<template>
    
    <div class="body">
        <heder/>
        <leftmenu/>
        <div class="hedpoloska"></div>

        <div class="content">
          
            <!-- <a href="#create"> -->

            <div  class="contenthed">

              
                <div @click="createproject" class="createproject">

                    <div class="ched">
                        <div class="pphed1"></div>
                        <div class="pphed2"></div>
                    </div>
                    <div class="cbody" >
                        Создать новый <br> проект

                    </div>
                </div>
             
            </div>
            <!-- </a>  -->
            <div class="contentbody">

                <div class="cbodyhed">
                    <label class="cbhl" @click=" testr"> Последние проекты </label> 
                                       
                    
                    <div class="dropdown show">

                <!-- <a class="btn "  role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                 <strong>
                     Все проекты
                 </strong>
                   
                </a> -->

                <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <a class="dropdown-item" href="#">Action</a>
                    <a class="dropdown-item" href="#">Another action</a>
                    <a class="dropdown-item" href="#">Something else here</a>
                </div>
                </div>

               </div><br>
               <!-- <button @click="projects">
                qe
            </button> -->
               <div class="cbb">


                <div class="kard"
                v-for:="options in project4" 
                @click="projectclick(options.id)"
                >
                    <!-- <a href="#shablon" > -->
                     <div class="kardimg"></div>

                     <div class="kardb"
                     v-for:="options in project4" >
                        <img class="image1" src="@/component/pages/ikons/fail.png" >
                        {{(options)? project4.title : ''}}
                        {{(options)? project4 : ''}}
                        {{options.title}}
                        {{project4.title}}
                        {{options}}
                        {{(neuronet)? neuronet.descr : ''}}
                      
                      <div id="test1">
                        
                      </div >
                      
                        <br>
                     </div>
                     <!-- </a> -->
                </div>  
                <div class="kard"
                v-for:="getp in proect" 
                @click="projectclick(proect.id)"
                >
                    <!-- <a href="#shablon" > -->
                     <div class="kardimg"></div>

                     <div class="kardb">
                        <img class="image1" src="@/component/pages/ikons/fail.png" >
                        {{(getp)? proect : ''}}
                      
                      <div id="test1">
                        
                      </div >
                      
                        <br>
                     </div>
                     <!-- </a> -->
                </div> 
               <div @click="projects" class="kard">
                     <div class="kardimg">

                     </div>

                     <div class="kardb">
                        <img class="image1" src="@/component/pages/ikons/fail.png" >Цветник бохо<br>
                     </div>
                </div> 
                <div class="kard">
                     <div class="kardimg"></div>

                     <div class="kardb">
                        <img class="image1" src="@/component/pages/ikons/fail.png" >Цветник бохо<br>
                     </div>
                </div> 
                <div class="kard">
                     <div class="kardimg"></div>

                     <div class="kardb">
                        <img class="image1" src="@/component/pages/ikons/fail.png" >Цветник бохо<br>
                     </div>
                </div> 
                <div class="kard">
                     <div class="kardimg"></div>

                     <div class="kardb">
                        <img class="image1" src="@/component/pages/ikons/fail.png" >Цветник бохо<br>
                     </div>
                </div> 
                <div class="kard">
                     <div class="kardimg"></div>

                     <div class="kardb">
                        <img class="image1" src="@/component/pages/ikons/fail.png" >Цветник бохо<br>
                     </div>
                </div> 
                <div class="kard">
                     <div class="kardimg"></div>

                     <div class="kardb">
                        <img class="image1" src="@/component/pages/ikons/fail.png" >Цветник бохо<br>
                     </div>
                </div> 
                <div class="kard">
                     <div class="kardimg"></div>

                     <div class="kardb">
                        <img class="image1" src="@/component/pages/ikons/fail.png" >Цветник бохо<br>
                    
                    </div> 

                 <!--     <table class="table">
              
              <tbody>
                <tr
                v-for:="options in project1" 
                 
                >
                 
                  <td scope="row"  >{{(options)? project1.pr_descr : ''}}</td>
                    <td>{{(project1)? project1.pr_lat : ''}}</td>
                    <td>{{(project1)? project1.pr_lon : ''}}</td>
                    <td>{{(project1)? project1.pr_name : ''}}</td>
                    <td>{{(project1)? project1.id : ''}}</td>
                    <td>{{(project1)}}</td> 
                   
                    <td scope="row"  >{{qwerty.id }}</td>
                         <td> {{qwerty.pr_descr}}</td>
                    <td>{{ qwerty.pr_lat}}</td>
                    <td>{{qwerty.pr_lon}}</td>
                    <td>{{qwerty.pr_name}}</td> -->
                 

                    <!-- <td>{{qwerty.n_count}}</td>
                         вот это для вывода
                    <td>{{qwerty.order}}</td>
                    <td>{{qwerty.x}}</td>
                    <td>{{qwerty.y}}</td>
                </tr>
              </tbody>
           </table> -->
                </div> 
                       
               

               </div>

            </div>
        </div>
        <!-- <table>
            <td scope="row"  > {{(projects)? projects.pr_descr : ''}}</td>
                    <td> {{(projects)? projects.pr_lat : ''}}</td>
                    <td>{{(neuronet)? neuronet.pr_lon : ''}}</td>
                    <td>{{(neuronet)? neuronet.pr_name : ''}}</td>
        </table> -->
        
    </div>
</template>

<script>
 import apiint from  "../../JS/apiint.js"  
 import heder from "./components/hederTree.vue";
    import leftmenu from "./components/leftMenu.vue"

 //   import contentA from "./components/contentAlias.vue"
export default{
    components:{
        heder,
        leftmenu,
      //   contentA,
        },

        name: "proJect",
        data(){
       return{
           
            project4: null,
            proect: null,
           
        }
    },
    created(){      
                  
                this.projects()
                this.getprojects()
        
    },

        mounted(){
           

    this.$projects()    //использовать для прорисовки по умолчанию
},
    
        methods:{
        // api.request(options2).then(function (response)

        testr(){
            
                 console.log("message")
            },
          
           
        

            prifile(this2){
                const optionspr = {
                method: 'GET',
                url: 'http://185.221.153.253:8000/api/aigarden/profile/me',
                // headers: {
                //     Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2ODcwOTk2NzYsInVpZCI6MTR9.4Qwjrlh1wIoBj2OwrEABUI30pMiyypnw4_yXNTfK0AI'
                // }
                };

                apiint.request(optionspr).then(function (response) {
                    console.log("qwe");   
                    console.log(response.data);
                    this2.profile = response.data
                    console.log(this2.profile);
                }).catch(function (error) {
                console.error(error);
                });
             },


            projects(){
            
                const options = {
                    method: 'GET',
                    url: 'http://185.221.153.253:8000/api/aigarden/project',
                                   
                    };
                  
                    apiint.request(options).then(function (response) {
                    localStorage.setItem('id', response.data.id)      
                            
                    let project4 = response.data
                    console.log(project4);
                    let qwerty = response.data.title
                    console.log("========");
                    console.log(qwerty);
                    document.getElementById('test1').textContent = project4
                    }).catch(function (error) {
                    console.error(error);
                 
                    })
                
            },
            getprojects(){
               

            const getpr = {
            method: 'GET',
            url: 'http://185.221.153.253:8000/api/aigarden/project',
            
            };

            apiint.request(getpr).then(function (response) {
                console.log("hello worlf");   
            console.log(response.data);
              let proect = response.data
              console.log(proect)
              let  element = document.querySelector('.test1');
            let getprodj= response.data
            element.innerHTML= getprodj
            }).catch(function (error) {
            console.error(error);
            });
            },


            createproject(id){

                const options2 = {
                    method: 'POST',
                    url: 'http://185.221.153.253:8000/api/aigarden/project',
                
                    data: {
                         "title": "string",
                         "location": "string",
                         "catalog_id": "34"
                    }
                    };

                    apiint.request(options2).then(function (response) {
                    console.log(response.data);
                    localStorage.setItem('id', response.data.id)  
                    console.log(response.data.id)
                     console.log('succes create')
          console.log(id);
        //  
        // this.$router.push({path: this.$route.fullPath + '/'+'neuroNet' + '/' +id })
        
             localStorage.getItem(id)    
             console.log(id)
       // this.$router.push( {path: 'create', query: { 'project': id }})
        console.log('123')
        //this.$router.push( {path: 'create', query: { 'project': id }})
                 //   window.location.reload()
                    
                    }).catch(function (error) {
                    console.error(error)
                    if(error.response.status === 422){
                        this.$router.push( {path: 'create', query: { 'project': id }})
                        window.location.reload()
                    }
                    });

                   
           },

            createfolder(){
                const options3 = {
            method: 'POST',
            url: 'http://185.221.153.253:5700/api/picfolders',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer 4a010051-13b7-417c-94e6-8435b183f0a7'
            },
            data: {}
            };

            apiint.request(options3).then(function (response) {
            console.log(response.data);
            }).catch(function (error) {
            console.error(error);
            });
            },


            projectclick( id){
          console.log('qwerty')
          console.log(id);
          //this.$router.push({path: this.$route.fullPath + '/'+'neuroNet' + '/' +id })
          this.$router.push( {path: 'shablon', query: { 'project': id }})
         },
         

                    
            
        }
}
</script>

<style scoped>
.btn{
    font-size: 24px;
}
.hedpoloska{
    height: 2.5px;
    width: 100%;
    background-color:#D9D9D9;
    /* margin-left: 99%; */
}
.kard{
    width: 255px;
    float: left;
    margin-left: 0%;
    margin-top: 1%;
    margin-left: 0%;
    
}
.kard a{
    text-decoration: none;
    color: black;
}
.kardimg{
    width: 242px;
    height: 242px;
    background-color: #D9D9D9;
    text-align: center;
    
}
.kardb{
    margin-top: 1%;
}

.cbb{
    margin-top: 5%;
}
.cbhl{
    float: left;
    margin-top: 0.5%;
}
.dropdown{
    margin-left: 2%;
    float: left;
}
.vseproekti{

}
.contentbody{
    margin-left: 5%;
    margin-top: 5%;
}
.content{
    margin-left: 2.3%;
    float: left;
    margin-top: 0px;
    width: 75%;
    height: 100vh;

}
.content a{
    text-decoration: none;
    color: black;
}
.contenthed{
    margin-left: 5%;
    margin-top: 5%;
     display: flex;

}
.createproject{
    text-align: center;
    margin-left: 0%;
    background-color: #D9D9D9 ;
    width: 320px;
    height: 140px;
    border-radius: 20px;
   
    }


.ched{
    margin-top: 3%;
}
.pphed1{
    width: 25px;
    height: 2px;
    margin-left: 45%;
    margin-top: 8%;
    background-color: black;
}
.pphed2{
    width: 25px;
    height: 1.5px;
    margin-left: 45%;
    margin-top: -0.5%;
    background-color: black;
    transform: rotate(90deg);
}

.cbody{
    margin-top: 7%;
}
.body{
    font-family: "Montserrat-Regular";
    /* background-color: rgb(211, 211, 211); */
    z-index: 9;
    font-size: 24px;
}
</style>
