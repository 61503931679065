<template>
    <div class="body">
        <div class="heder">
            <a href="#alias">
            <div class="logo">
               <!-- <img  class="img5" src="@/component/pages/ikons/logo1.png">  -->
                A I . G A R D E N 
            
            </div>
            </a>
            <div class="panel">
                <!-- <a  href="">
                <button v-bind="!accesstoken" class="btn buton">
                   Мои проекты
                </button>
                </a> -->
                <!-- <a  href="">
                <button class="btn buton">
                    Тарифы
                </button>
                </a>
                <a  href="">
                <button class="btn buton">
                   Галерея
                </button>
                    </a>
                    <a  href="">
                <button class="btn buton">
                   Как работать
                </button>
                    </a>
                    <a  href="">
                <button class="btn buton">
                  Сообщество
                </button>
                    </a> -->
                    <header>
                    <input class="menu-btn" type="checkbox" id="menu-btn" name="menu-btn" />
                    <label class="menu-icon" for="menu-btn">
                        <span class="navicon" aria-label="Hamburger menu 'icon'" />
                    </label>
                    <nav class="menu">
                        <a class="nav-item" href="#">                            
                            Тарифы                                                      
                        </a>
                       
                        <a class="nav-item" href="#">                           
                            Галерея                     
                        </a>
                        <a class="nav-item qweasd" href="#">                             
                              Как работать                            
                         </a>
                        <a class="nav-item" href="#">                          
                            Сообщество                           
                        </a>
                    </nav>
                    </header>
            </div>
            <div class="rightbutton">
                <!-- <a href="#auth"> 
              <button v-if="!accessToken" class="btn">
                    Войти
                </button>
                </a> -->
                <auth/>


                <!-- <button v-if="!accessToken">
                    Name
                </button> -->


                <!-- <button type="button" class="btn btn-primary buton2" data-bs-toggle="modal" data-bs-target="#exampleModal">
                    Войти
</button>

  
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Зарегистрироваться</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
       

<div class="contentbox">

    <div class="contentleft">

        <div class="left">
            <p>
                Зарегистрируйтесь чтобы начать работу 
            </p>
            <label class="label0" for="">
                Введите  код из смс или из почты
            </label><br>
            <input type="text"><br>
            <a href="#tips">
            <button class="btn button1">
                Зарегистрироваться
            </button>
            </a>
            <div class="pbox">
                    <div class="poloska1"></div>
                <label class="label1">
                    или
                </label>                                
                    <div class="poloska2"></div>
            </div>
            <label class="label2">
                Упрощенная регистрация через:
            </label>
            <div class="rotbox">
                <button class="bnt buttonR">
                    <img class="img1" src="@/component/pages/ikons/Vk.png">
                </button>
                 <button class="bnt buttonR">
                    <img class="img1" src="@/component/pages/ikons/icons8-google-30.png">
                </button>
                 <button class="bnt buttonR">
                    <img class="img1" src="@/component/pages/ikons/icons8-yandex-67.png">
                </button>
             
            </div>
            <label class="label3">
                Нажимая кнопку “Зарегистрироваться” Вы соглашаететсь с политикой конфиденциальности
            </label>
        </div>
    </div>

    <div class="contentright">

        <div class="right">

            <p>
                Или ознакомьтесь с функционалом в сопровождении виртуального помощника
            </p>
            <img class="img2" src="@/component/pages/ikons/robot.png">
            <button class="btn button4">
                Поехали!
            </button>
        </div>

    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Отмена</button>
        <button type="button" class="btn btn-primary">Войти</button>
      </div>
</div>

</div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Отмена</button>
        <button type="button" class="btn btn-primary">Войти</button>
      </div>
    </div>
  </div> -->
   
   <!--  -->



   <!--  -->

                </div>
                
            </div>
        </div>
   
  
</template>

<script>

import auth from'./authModal.vue'
    export default{
        components:{
            auth,
        }
    }
</script>

<style scoped>
/*  */

/*  */
.qweasd{
    width: 300px;
    
}
 .body{
     height: 90px;
     /* position: absolute; */
     /* top: 0; */
     width: 100%;
     background-color: rgb(255, 255, 255);
     padding: 5px;
 }
    .heder{
        margin-top: 0.9%;
        font-family: "Montserrat-Regular";
        display: flex;
    }
    .heder a{
        text-decoration: none;
    }
    .logo{
   
        /* position: absolute; */
        margin-left: 12.5%;
        font-size: 36px;
        font-family: "Montserrat-SemiBold";
        color: rgb(0, 0, 0);
        width: 300px;
        text-align: left;
        /* display:flex; */
    }
    .logo img{
        width: 15%;

    }
    .panel{
        /* position: absolute; */
        margin-left: 10%;
        width: 50%;
        /* text-align: center; */
        /* justify-content: center; */
        margin-top: -0.8%;
    

    }
    .panel a{
        /* position: absolute; */
        /* margin-left: 1%; */
        width: 100%;
        color: black;
        font-size: 24px;
        text-align: center;
        /* justify-content: center; */

    }
    .buton{
        color: rgb(0, 0, 0);
        font-size: 24px;
        margin-left: 2%;
    }
    .rightbutton{
        /* position: absolute;
        right: 10%; */
        color: rgb(202, 202, 202);
        margin-left: 13.5%;
        /* text-align: right; */
        height: 50px;
    }
    .buton2{
        background-color:rgb(211, 211, 211);
        color: rgb(0, 0, 0);
        font-size: 25px;
        margin-left: 2%;
        border-radius: 40px;
    }
    /*  ============================ */
  

#menu-btn {
  position: absolute;
  top: -100%;
  left: -100%;
}
/* Hide hamburger for bigger screens */
.menu-icon {
  visibility: hidden;
}
.menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* max-width: 250px; */
  margin-right: 2rem;
}
.nav-item{
    width: auto;
}
/* Set width for mobile/smaller screen size. */
/* I set it big here so I don't have to shrink the screen so much */
/* for testing purposes */
@media screen and (max-width: 1440px) {
  header {
    display: grid;
    grid-template-areas:
      "title title hamburger"
      "nav nav nav";
  }
  h1 {
    grid-area: title;
  }
  .menu a {
    text-decoration: none;
    color: black;
    font-size: 18px;
  }
  .menu-btn {
    display: none;
  }
  .menu-icon {
    grid-area: hamburger;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    padding: 30px 20px 30px 0;
    position: relative;
    user-select: none;
    visibility: visible;
  }
  .navicon {
    background: #333;
    display: block;
    height: 2px;
    width: 18px;
    position: relative;
  }
  .navicon:before {
    top: 5px;
  }
  .navicon:after {
    top: -5px;
  }
  .navicon:before,
  .navicon:after {
    background: #333;
    display: block;
    width: 100%;
    height: 100%;
    content: "";
    position: absolute;
    transition: all 0.2s ease-out;
  }
  .menu {
    grid-area: nav;
    max-width: unset;
    max-height: 0;
    transition: max-height 0.2s ease-out;
    overflow: hidden;
    margin: 0;
    padding: 0;
    background-color: #fff;
    display: flex;
    flex-direction: column;
  }
  .menu a {
    padding: 20px 20px;
    border-right: 1px solid #f4f4f4;
    background-color: #ffffff;   
    
    opacity: 100%;
    text-align: center;
  }
  .menu-btn:checked ~ .menu {
    max-height: 240px;
  }
  .menu-btn:checked ~ .menu-icon .navicon {
    background: transparent;
  }
  .menu-btn:checked ~ .menu-icon .navicon:before {
    transform: rotate(-45deg);
  }
  .menu-btn:checked ~ .menu-icon .navicon:after {
    transform: rotate(45deg);
  }
  .menu-btn:checked ~ .menu-icon .navicon:before,
  .menu-btn:checked ~ .menu-icon .navicon:after {
    top: 0;
  }
}
</style>
