<template>
    <div class="body">
        <HederUno/>
        <tophead/>
            <div class="content">
                <P>
                    Наш проект еще находится в разработке
                </P>
                <label>
                    Мы - молодой стартап, и еще ведем работу над нашим проектам, поэтому пока не готовы Вам его представить. если Вам интересно, вы можете задать нам вопрос или оставить свои контакты и предложения. Как только мы выпустим продукт - Вы будете первым, кто получит к нему доступ
                </label>

            </div>
            <div class="advert">
                <br> <br> <br>
                <div class="adverthed">
                <p>
                    Сделайте Ваших клиентов счастливыми!
                </p>
                <label>
                    Работайте в удовольствие, творите и фантазируйте, а рутину оставьте искусственному интеллекту!
                </label>
                </div>
               <div class="table">
                    <div class="tablebody1">
                        <label>
                            Уникальная система определения предпочтений и потребностей пользователя
                        </label>
                        <img src="./assets/icons/free-icon-creative-brain-4385919.png" alt="img">
                    </div>
                    <div class="tablebody2">
                        <label>
                            Генерация фотореалистичных визуализаций по тегам и отобранным изображениям
                        </label>
                        <img src="./assets/icons/free-icon-insert-picture-icon-16410.png" alt="img">
                    </div>
                   
                </div>
                <div class="table">
                    
                    <div class="tablebody1">

                         <label>
                            Вся работа в одном онлайн-сервисе без тяжелых программ и конвертирования файлов
                        </label>
                        <img src="./assets/icons/free-icon-innovation-2452643.png" alt="img">
                    </div>
                    <div class="tablebody2">
                        <label>
                            Дополнительный заработок на заказах и доставке, удобный формат работы с поставщиками!
                    </label>
                    <img src="./assets/icons/free-icon-profit-154221.png" alt="img">
                    </div>
                </div>
                    <div class="anketa">
                        
                    </div>
            </div>
      
        <boost/>
        <Footer/>
    </div>
</template>

<script>
import HederUno from './hederUno.vue'
import Footer from './footerUno.vue'
import boost from './components/components/boostUno.vue'
import tophead from './components/components/topHead.vue'

export default{
    components:{
        HederUno,

        Footer,
        boost,
        tophead,
        
    },
}
</script>

<style scoped>
.table{
    display: flex;
    width: 100%;
    margin-top: 2%;
}
.tablebody1 img{
    width: 125px;
    margin-left: 2%;
}
.tablebody2 img{
    width: 125px;
    margin-left: 2%;
}
.tablebody{

}
.tablebody1 label{
    margin-top: 2%;
    text-align: left;
    font-size: 24px ;
   margin-left: 30%;
    width: 50%;
    font-family: 'Montserrat-Bold';
    float: left;
}
.tablebody2 label{
    margin-top: 2%;
    text-align: left;
    font-size: 24px ;
   margin-left: 5%;
    width: 50%;
    font-family: 'Montserrat-Bold';
    float: left;
}
.adverthed label{
    margin-left: 35%;
    font-size: 24px ;
    width: 30%;
    text-align: center;
}
.adverthed p{
    /* margin-left: 33%; */
    font-family: 'Montserrat-Bold';
    font-size: 36px ;
    text-align: center;
}
.adverthed{
    
}
.advert{
    margin-top: 4%;
    width: 100%;
    height: 700px;
    background-color: #a0b19a;
}
.content label{
    font-size: 24px ;
}
.content p{
    font-family: 'Montserrat-Bold';
    font-size: 36px ;
}
.content{
    width: 60%;
    margin-left: 20%;
    text-align: left;
    margin-top: 4%;
}

.body{
    font-family: 'Montserrat-Regular';
}

</style>
