<template>
    <div class="body">
        <div class="left">

               <div class="conthed">

                 <img class="hedimgl" src="./photos/1633908674_126-p-zagorodnie-doma-i-kottedzhi-foto-132.jpg" alt="">
              
               </div><br>

               <div class="tipimg">
                    <table>
                        <tr class="tr">
                            <td class="td">
                                <p>
                                    <strong>
                                        Тип изображения
                                    </strong>
                                </p>
                                <label>
                                        Стиль 
                                </label>    
                            
                            </td>
                            <td class="td">
                                <button class="btn btgetr">
                                    Добавить разметку
                                </button>
                            </td>
                        </tr>
                    </table>
               </div><br>

               <div class="style">
                    <div class="lstyle">
                         <p>
                            <strong>
                                Стиль:
                            </strong>

                        </p>
                        <ul>
                            <a>
                                <li>
                                    английский 50%
                                </li>  
                            </a>
                            <a>
                                <li>
                                    голландский 20%
                                </li>  
                            </a>
                            <a>
                                <li>
                                    романтический 30%
                                </li>  
                            </a>
                        </ul>
                    </div>
                    <div class="rstyle">
                        <a href="#onePage">
                            <button class="btn constructor">
                                Конструктор нейросетей
                            </button> 
                        </a>       
                    </div>
                   
               </div><br>

               <div class="objects">
                    <div class="lobjects">
                        <p>
                            <strong>
                                Объекты:
                            </strong>
                            автоматически
                        </p>
                        <ul>
                            <li>
                                клумба 
                              
                            </li>
                            <li>
                                миксбордер
                            </li>
                            <li>
                                  бордюр
                            </li>
                        </ul>
                    </div>
                    <div class="robjects">
                        <button class="btn gotelements">
                            Добавить элемент
                        </button><br>
                    </div>
               </div>

               <div class="elements">
                    <div class="lelements">
                        <p>
                            <strong>
                                Элементы:
                            </strong>
                            автоматически
                        </p>
                        <ul>
                            <a>
                                <li>
                                    дом
                                </li>  
                            </a>
                            <a>
                                <li>
                                    подпорная стенка
                                </li>  
                            </a>
                            <a>
                                <li>
                                    дорожка
                                </li>  
                            </a>
                            <a>
                                <li>
                                    газон
                                </li>  
                            </a>
                            <a>
                                <li>
                                    клен гиннала
                                </li>  
                            </a>
                            <a>
                                <li>
                                    рудбекия
                                </li>  
                            </a>
                            <a>
                                <li>
                                    роза
                                </li>  
                            </a>
                        </ul>
                    </div>

                    <div class="relements">
                        <button class="btn gotelements">
                            Добавить элемент
                        </button><br>

                        <button class="btn parametrimg">
                            Параметры изображения
                        </button>
                    </div>


               </div><br><br>
               <div class="lfooter">
                    <p>
                        <strong>
                            Параметры изображений
                        </strong>
                    </p>
               </div>
        </div>
        <!--  =================  -->
        <div class="right">
            
              <div class="rconthed">
                   <div class="imgbox">
                        <img class="hedimgr" src="./photos/1633908674_126-p-zagorodnie-doma-i-kottedzhi-foto-132.jpg" alt="">
                   </div> 
                   <div class="rcontbox">
                        <div class="razmerbox">
                            <p>
                                Высота
                            </p>
                            <label>
                               0,5 м
                            </label>
                            <p>
                               Ширина
                            </p>
                            <label>
                                0,5 м
                            </label>
                            <p>
                                 Глубина
                            </p>
                            <label>
                                0,5 м
                            </label>

                        <button class="btn getrazmer">
                            Задать размер
                        </button><br>
                        </div>
                   </div>
                 
               </div><br>

               <div class="wthobject">
                    <div class="lwthobject"> 
                      <p>
                            <strong>
                                Что за обЪект?
                            </strong>
                           
                        </p>
                        <label>
                            Группа
                        </label>
                    </div> 
                    <div class="rwhtobject">
                         <p>
                            <strong>
                                Определённые элементы:
                            </strong>
                         </p>   
                         <label class="greytext">
                            Растение 1 вид
                         </label>
                         <ul class="nobullet">
                            <li>
                                 Гелиопсис 20%  
                            </li>
                            <li>
                                Рудбекия 80%
                            </li>
                         </ul>
                         
                    </div><br>
                    
               </div><br>
               <div class="opredelit">

                        <div class="lobj">
                            <p>
                                <strong>
                                       Определить элементы
                                </strong>                             
                            </p>
                            <input type="text" placeholder="Найти по ключевым словам"><br>
                             <input type="text" placeholder="Выбрать из онтологии">
                        </div>
                        <div class="robj">
                            <p>
                                .
                            </p>
                            <button class="btn getbtn">
                                Добавить
                            </button><br>
                            <button class="btn getbtn">
                                Добавить
                            </button>
                        </div>
                    
                 </div><br>
                 <div class="forms">
                    <div class="lforms">
                        <p>
                            <strong>
                                Форма группы
                            </strong>
                           
                        </p>
                        <label>
                            автоматически
                        </label>
                        <p>
                            <strong>
                                    Форма соцветий  
                            </strong>
                          
                        </p>
                        <label>
                            автоматически
                        </label>
                        <p>
                            <strong>
                                 Цвет зелени
                            </strong>
                          
                        </p>
                        <label>
                            автоматически
                        </label>
                        <p>
                            <strong>
                                 Цвет цветков
                            </strong>
                           
                        </p>
                        <label>
                            автоматически
                        </label>
                    </div>
                    <div class="rforms">
                        <div class="btnbox">
                            <button class="btn change1">
                            Выбрать
                        </button><br>
                        <button class="btn change">
                            Выбрать
                        </button><br>
                        <button class="btn change">
                            Выбрать
                        </button><br>
                        <button class="btn change">
                            Выбрать
                        </button><br>
                        </div>
                        
                    </div>

                   

                 </div><br><br>
                 <div class="rfooter">
                        <button class="btn segmentirovati">
                            Сегментировать
                        </button>
                        <button class="btn strelka">
                            <img src="./ikons/strelka1.png" >
                        </button>
                        <button class="btn strelka">
                           <img src="./ikons/strelka2.png" >
                        </button>
                        <button class="btn save">
                            Сохранить
                        </button>
                    </div>   


        </div>
    </div>
</template>

<script>

export default{

}

</script>

<style scoped>
/* L FOOTER */
.lfooter{
    margin-left: 2%;
}
/* OBJECTS */
.objects{
    margin-left: 2%;
    display: flex;
}
.lobjects{
    width: 50%;
}

.robjects{
    text-align: right;
    width: 35%;
    margin-left: 10%;
    
}
/* ELEMENTS */
.elements{
    margin-left: 2%;
    display: flex;
}

.lelements{
    width: 50%;
}

.relements{
    text-align: right;
    width: 35%;
    margin-left: 10%;
    
}
.gotelements{
    border-radius: 50px;
    width: 185px;
    height: 40px;
    background-color: #D9D9D9;
   

}
.parametrimg{
    width: 250px;
    height: 50px;
    background-color: #B6B6B6;
    border-radius: 10px;
    margin-top: 20%;
}
/* STYLE */
.style{
    margin-left: 2%;
    display: flex;
}
.lstyle{
    float: left;
    width: 50%;
}
.rstyle{
    width: 35%;
    margin-left: 10%;
}

.constructor{
    width: 241px;
    height: 44.07px;
    border: 3px solid #D9D9D9;
    border-radius: 10px;
}
/* content Hed */
.conthed{
    text-align: center;
    margin-top: 0%;
    width: 100%;
    height: 400px;
    background-color: #9b9b9b;
}
.rconthed{
    /* text-align: center; */
    margin-top: 0%;
    width: 100%;
    height: 400px;
    background-color: #9b9b9b;
    display: flex;
}
.hedimgl{
    width: 90%;
    margin-top: 1%;
}
.hedimgr{
    width: 80%;
    margin-top: 1%;
    margin-left: 2%;
}
.rconthed{
    margin-right: 2%;
   
}

.razmerbox{
    text-align: center;
}
/* TIP IMG */
.btgetr{
    width: 198px;
    height: 39px;
    background-color: #D9D9D9;
    border-radius: 50px;
    margin-right:5% ;
}
.td{
    width: 68%;
}
.td p{
    margin-left: 2%;
}
.td label{
    margin-left: 2%;
}
/*  */
.left{
    width: 669px;
    height: 100%;
    /* float: left; */
    border: 10px solid #6B6B6B;
    padding: 5px;
}
/* WTH OBJECT */
.wthobject{
    display: flex;
}
.lwthobject{
    margin-left: 2%;
    width: 50%;
}


.getrazmer{
    border-radius: 50px;
    width: 185px;
    height: 40px;
    background-color: #D9D9D9; 
    margin-left: 0%;
}
/* RIGHT BLOCK */
.right{
    width: 669px;
    height: 100%;
    /* float: left; */
    border: 10px solid #6B6B6B;
    padding: 5px;
}
.rwthobject{
    text-align: right;
    width: 50%;

}
.nobullet{
   text-decoration: none;
   list-style-type:none;
}
.greytext{
    color: #9b9b9b;
}
.opredelit{
    margin-left: 2%;
    display: flex;
}
.lobj{
    width: 50%;
}
.lobj input{
    width: 251px;
    height: 50px;
    background-color: #FFFFFF;
    border: 1px solid #A09F9F;
    border-radius: 5px;
    margin-top: 1%;
}
.robj{
    width: 50%;
}
.robj p{
    color:white
}
.getbtn{
    width: 185px;
    height: 52px;
    background-color: #B6B6B6;
    border-radius: 10px;
    margin-top: 1%;

}   
/* Forms */
.forms{
    margin-left: 2%;
    display: flex;
}
.lforms{
    width: 50%;
}
.rforms{
    width: 50%;
}
.btnbox{
    margin-top: 5%;
}
.change1{
    margin-top: 0%;
    width: 185px;
    height: 39px;
    background-color: #D9D9D9;
    border-radius: 50px;
}
.change{
    margin-top: 25px;
    width: 185px;
    height: 39px;
    background-color: #D9D9D9;
    border-radius: 50px;
}
/* R FOOTER */
.rfooter{
    margin-left: 2%;
    text-align: center;
    margin-bottom: 0.3%;
}
.segmentirovati{
    width: 172px;
    height: 50px;
    background: #B6B6B6;
    border-radius: 10px;
}
.strelka{
    width: 52px;
    height: 50px;    
    background: #D9D9D9;
    border-radius: 5px;
    margin-left: 1%;
    
}
.save{
    width: 172px;
    height: 50px;
    margin-left: 1%;
    background: #B6B6B6;
    border-radius: 10px;
}
/* -------------- */
/* BODY */
.body{
    margin-left: 2%;
    font-family: "Montserrat-Regular";
    /* text-align: center; */
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 5px;
}
</style>
