<template>
 <div id='app' >
 <!-- <LoginUno v-model="files" /> -->

  <!-- <interER />
  <HeaderUno/>
  <leftMenu/>
  <contentUno/> -->

 </div>
   <router-view></router-view>

</template>

<script  >
//  import { defineComponent, ref } from 'vue';
//  import LoginUno from './components/LoginUno.vue';
// import interER from './components/interER.vue'
// import HeaderUno from './components/HeaderUno.vue'
// import leftMenu from './components/leftMenu.vue'
//  import contentUno from './components/contentUno.vue'
export default ({
  // components:{
  //   LoginUno,
  // },
  // setup() {
  //   const files = ref<Array<File>>([]);
  //   return { files };
  // },
  name: 'App',
  data(){
    return{
      isActive: true
    }
  },
  //  components:{
  //    interER,
  //     HeaderUno,
  //    leftMenu,
  //    contentUno
  // }

 });


</script>

<style>
@font-face {
  font-family: "Montserrat-SemiBold";
  src: local("Montserrat-SemiBold"),
   url(./fonts/Montserrat-SemiBold.woff) format("truetype");
}
@font-face {
  font-family: "Montserrat-Bold";
  src: local("Montserrat-Bold"),
   url(./fonts/Montserrat-Bold.woff) format("truetype");
}
@font-face {
  font-family: "Montserrat-Regular";
  src: local("Montserrat-Regular"),
   url(./fonts/Montserrat-Regular.woff) format("truetype");
}
@font-face {
  font-family: "Montserrat-ThinItalic";
  src: local("Montserrat-ThinItalic"),
   url(./fonts/Montserrat-ThinItalic.ttf) format("truetype");
}
@font-face {
  font-family: "OlgaCTT";
  src: local("OlgaCTT"),
   url(./fonts/ofont.ru_OlgaCTT.ttf) format("truetype");
}
@font-face {
  font-family: "OlgaCTTw";
  src: local("OlgaCTTw"),
   url(./fonts/ofont.ru_OlgaCTT.woff) format("truetype");
}
</style>
