<template>
    <div class="body">   
       <HederUno/>
       <tophead/>
       <div class="parthed">
            <p>Приветствуем наших потенциальных партнеров!</p>
            <label>
                Приветствуем наших потенциальных партнеров!
Мы знаем, что в одиночку мы можем сделать очень мало, но все вместе мы можем изменить мир! Поэтому мы приглашаем к сотрудничеству всех, кого заинтересовал наш проект. Будь Вы инвестор, ищущий, куда выгодно вложит свой капитал, заинтересованный пользователь, мечтающий применить свою творческую энергию и профессиональные компетенции, или команда, работающая над похожим проектом - мы с удовольствием обсудим с Вами взаимовыгодные условия сотрудничества. Уверены, вместе нас ждут великие свершения!
            </label>
       </div>
        <div class="forpart">
            <input type="text" placeholder="Ваше имя"><br>
            <input type="text"  placeholder="Ваш род деятельности"><br>
            <textarea>

            </textarea><br>
            <input type="text"  placeholder="Ваш email или телефон"><br>
            <button class="btn send">
                    Отправить
               </button>
        </div>
        <boost/>
       <Footer/>

    </div>
</template>

<script>
import HederUno from './hederUno.vue'
import Footer from './footerUno.vue'
import boost from './components/components/boostUno.vue'
import tophead from './components/components/topHead.vue'
//import quest from './components/components/questsUno.vue'
export default{
    components:{
        HederUno,

        Footer,
        boost,
        tophead,
      //  quest,
    },

}
</script>

<style scoped>
.parthed p{
    font-family: 'Montserrat-Bold';
    font-size: 36px;
}
.parthed label{
    font-family: 'Montserrat-Regular';
    font-size: 24px;
}
.parthed{
    margin-left: 20%;
    margin-top: 5%;
    text-align: left;
    width: 65%;

}
.send{
    margin-top: 3%;
    font-size: 24px;
    width: 20%;
    height: 60px;
    border-radius: 30px;
    margin-left: 25%;
    background-color: #a0b19a;
}
.forpart input{
    width: 70%;
    height: 50px;
    padding: 15px;
    margin-top: 2%;
}
.forpart textarea{
    width: 70%;
    height: 200px;
    padding: 5px;
    margin-top: 2%;
    padding: 15px;
}
.forpart{
    width: 60%;
    margin-top: 5%;
    margin-left: 29%;
}
.body{
    font-family: 'Montserrat-Regular';
}
</style>
