<template>
    <div class="body">
       
        <div class="quest">
            
            <div class="opros">
               <div class="vopros">

                    <label>
                        Когда у вас возникает потребность спроектировать свой сад, выбрать оптимальное расположение для клумб и других композиций, применяете ли Вы для этого инструменты моделирования? Какие? (можно выбрать несколько ответов)
                    </label><br><br>
                    <div class="inputbox">
                        <input class="input" type="radio" 
                        value="a. Профессиональные программы для проектирования
                        a. Профессиональные программы для 
                        проектирования">
                        <label class="voprostext">
                            a. Профессиональные программы для проектирования
                        </label>
                    </div>
                    <div class="inputbox">
                        <input class="input" type="radio" 
                        value="a. Профессиональные программы для проектирования
                        a. Профессиональные программы для 
                        проектирования">
                        <label class="voprostext">
                            b. Адаптированные программы и сервисы под задачи ландшафтного дизайна           
                        
                        </label>
                    </div>
                    <div class="inputbox">
                        <input class="input" type="radio" 
                        value="a. Профессиональные программы для проектирования
                        a. Профессиональные программы для 
                        проектирования">
                        <label class="voprostext">
                            c. Онлайн-конструкторы
                                              </label>
                    </div>
                    <div class="inputbox">
                        <input class="input" type="radio" 
                        value="a. Профессиональные программы для проектирования
                        a. Профессиональные программы для 
                        проектирования">
                        <label class="voprostext">
                            d. Использую комбинацию программных продуктов                        
                        </label>
                    </div>
                    <div class="inputbox">
                        <input class="input" type="radio" 
                        value="a. Профессиональные программы для проектирования
                        a. Профессиональные программы для 
                        проектирования">
                        <label class="voprostext">
                            e. Не применяю. Визуализирую на бумаге (делаю наброски)                        
                        </label>
                    </div>
                    <div class="inputbox">
                        <input class="input" type="radio" 
                        value="a. Профессиональные программы для проектирования
                        a. Профессиональные программы для 
                        проектирования">
                        <label class="voprostext">
                            f. Действую по наитию, без предварительной проработки.                        </label>
                    </div>
                    <div class="inputbox">
                        <input class="input" type="radio" 
                        value="a. Профессиональные программы для проектирования
                        a. Профессиональные программы для 
                        проектирования">
                        <label class="voprostext">
                            g. Иное                        </label>
                    </div>

               </div>
               <!--  --><br>
               <div class="vopros">
                    <label>
                        Когда у вас возникает потребность спроектировать свой сад, выбрать оптимальное расположение для клумб и других композиций, применяете ли Вы для этого инструменты моделирования? Какие? (можно выбрать несколько ответов)
                    </label><br><br>
                    <textarea class="txt">

                    </textarea>
               </div><br>
               <!--  -->
               <div class="vopros">
                <label>
                    Какими из них Вы пользуетесь?
                
                </label><br><br>
                    <textarea class="txt">

                    </textarea>
               </div><br>
               <!--  -->
               <div class="vopros">
                <label>
                    Какой функционал в них кажется Вам наиболее полезным?
                
                </label><br><br>
                    <textarea class="txt">

                    </textarea>
               </div><br>
               <!--  -->
               <div class="vopros">
                <label>
                    Чего Вам не хватает в этих программах? Что хотелось бы улучшить?                
                </label><br><br>
                    <textarea class="txt">

                    </textarea>
               </div><br>
               <!--  -->
               <div class="vopros">
                <label>
                    Какие Ваши проблемы этот продукт должен решать?
                </label><br><br>
                    <textarea class="txt">

                    </textarea>
               </div><br>
               <!--  -->
               <div class="vopros">
                <label>
                    На каких условиях Вы готовы использовать продукт (возможно несколько ответов)?                
                </label><br><br>
                <div class="inputbox">
                        <input class="input" type="checkbox">
                      
                        <label class="voprostext">
                            a. Профессиональные программы для проектирования
                        </label>
                    </div>
                    <div class="inputbox">
                        <input class="input" type="checkbox">
                     
                        <label class="voprostext">
                            b. Адаптированные программы и сервисы под задачи ландшафтного дизайна                        </label>
                    </div>
                    <div class="inputbox">
                        <input class="input" type="checkbox">
                     
                        <label class="voprostext">
                            c. Онлайн-конструкторы                        
                        </label>
                    </div>
                    <div class="inputbox">
                        <input class="input" type="checkbox">
                     
                        <label class="voprostext">
                            d. Использую комбинацию программных продуктов                        
                        </label>
                    </div>
                    <div class="inputbox">
                        <input class="input" type="checkbox">
                     
                        <label class="voprostext">
                            e. Не применяю. Визуализирую на бумаге (делаю наброски)                        
                        </label>
                    </div>
                    <div class="inputbox">
                        <input class="input" type="checkbox">
                     
                        <label class="voprostext">
                            f. Действую по наитию, без предварительной проработки.                        
                        </label>
                    </div>
                    <div class="inputbox">
                        <input class="input" type="checkbox">
                     
                        <label class="voprostext">
                            g. Иное                        
                        </label>
                    </div>
               </div><br>
               <!--  -->
               <div class="vopros">
                <label>
                    Укажите приемлемую для вас стоимость месячной подписки, если применимо                
                </label><br><br>
                    <textarea class="txt">

                    </textarea>
               </div><br>
               <!--  -->
               <div class="voprosbox">
                <a >
               <button class="btn send">
                    Отправить
               </button>
              </a>
              <label class="sogl">
                *Нажимая "Отправить" Вы соглашаетесь с политикой обработки персональных данных
              </label>
               </div> <br>
               <!--  -->
            </div>
        </div>


    </div>
</template>

<script>
export default{

}
</script>

<style scoped>
.sogl{
    margin-top: 1%;
    font-size: 15px;
    /* margin-left: 20%; */
    margin-bottom: 5%;
}
.send{
    font-size: 24px;
    width: 20%;
    height: 60px;
    border-radius: 30px;
    /* margin-left: 40%; */
    background-color: #a0b19a;
    margin-top: 6.6%;
}
.txt{
    width: 100%;
    height: 110px;
    border: none;
    border-radius: 20px;
}
.voprostext{
    font-size: 24px;
}
.input{
    width: 35px;
    padding: 5px;
    border-radius: 5px;
    margin-left: -10px;
}

.vopros{
    text-align: left;
    justify-content: center;
}
.quest{
 width: 60%; 
  
   /* margin: 0; */
    margin-left: 20%;
    margin-top: 7%;
    font-size: 24px;
    text-align: center;
    background-color: #f1f1f1;
    border-radius: 20px;
    padding: 25px; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
}
.body{
    font-family: 'Montserrat-Regular';
    text-align: center;

}

.srp{
    width: 100%;
    height: 50px;
    background-color: rgba(202, 202, 202, 0.788);
    margin-top: 7%;
}
@media (max-width:1280px) {
    .srp {
    width: 100%;
    height: 100px;
    background-color: rgba(202, 202, 202, 0.788);
    margin-top: 7%;
}
.txt{
    width: 100%;
    height: 100px;
}
/* .srp p{
    font-family: 'Montserrat-Bold';
    margin-top: 1%;
    font-size:40px ;
    position: absolute;
    margin-left: 20%;
} */

}
.opros{
    width: 100%;
    background-color:#eeeeee ;
    padding: 25px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    
}
.srp p{
    font-family: 'Montserrat-Bold';
    margin-top: 1%;
    font-size:30px ;
    /* position: absolute;
    margin-left: 20%; */
}
.voprosbox{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
}

</style>
