<template>
    <div class="header">
         <heder/> 
         
    </div>
    
    <hederd/>
     <div class="heder">
         <hederD/>
          <!--   <div class="up">
                <a href="#alias">
                <p><img src="./ikons/strelkanazad1.png">   назад к списку проектов</p>
                </a>
                <p class="upp">
                  <strong>
                     Профиль проекта
                  </strong> 

                </p>
                <div class="krug2">
                        
                        <p>4</p> 
                 </div>
            </div>
            <div class="bottom">
                <div class="blockp1">
                    <div class="p1"
                  
                    >
                        <strong>
                          Без названия
                        </strong>
                    </div>
                </div> 
              
               

            </div> -->
        </div>       <br><br><br>
       
    <div class="body">
     
<div class="content">
        <div class="leftmenu">
            <!-- Button trigger modal -->
            <p class="ppp">Где находится Ваш участок?</p>
            <div class="karta">
                <input class="inp" type="text">
            <div class="modal1">
                <button type="button" class="btn kartbtn " data-bs-toggle="modal" data-bs-target="#exampleModal">
                    <img class="kartimg" src="./ikons/map.png">
                </button>
            </div>
            


                <!-- Modal -->
                <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Карта</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                                    <iframe width="425" height="350" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://www.openstreetmap.org/export/embed.html?bbox=34.45861816406251%2C49.990083926193954%2C41.44592285156251%2C53.07422751310222&amp;layer=mapnik" style="border: 1px solid black"></iframe><br/><small><a href="https://www.openstreetmap.org/#map=8/51.557/37.952">Посмотреть более крупную карту</a></small>

                        
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Закрыть</button>
                        <button type="button" class="btn btn-primary">Сохранить</button>
                    </div>
                    </div>
                </div>
                </div>
            </div><br>
            <p>Примерный размер цветника</p>
            <div class="wb">
                <div class="wb1">
                    <p>длина</p>
                    <input class="inp2" type="text">
                </div>
                <div class="wb2">
                    <p>ширина</p>
                    <input class="inp2" type="text">
                </div>
                <!-- <div class="wb1">
                    <p>глубина</p>
                    <input class="inp2" type="text">
                </div> -->
              
             

            </div><br>
              <p>Примерный бюджет</p>
            <div class="wb">
                <div class="wb1">
                    <p>от</p>
                    <input class="inp4" type="text">
                </div>
                <div class="wb2">
                    <p>до</p>
                    <input class="inp4" type="text">
                </div>
             </div>
             <div class="form"><br>
                <p>
                    Освещенность композиции/зоны
                </p>

                 <button class="btn bt1">
                   <img class="btnimg" src="./ikons/free-icon-sunrise-2939886.png">
                </button> 
                 
                <button class="btn bt1">
                    <img class="btnimg" src="./ikons/free-icon-partly-cloudy-7849059.png">
                </button> 
               <button class="btn bt1">
                    
                    <img class="btnimg" src="./ikons/free-icon-cloud-7164563.png">
                </button>
                
             </div>
             <div class="next"><br>
                <a href="#shagdva">
                <button class="btn bt2">
                    Далее
                </button>
                </a>
             </div>
    </div>
        <div class="holst">

            <button class="upload">
                Загрузите изображение
            </button>

        </div>
    </div>
    </div>
</template>
<script>
//import hederd from "./components/hederDuo.vue";
import heder from "./components/hederTree.vue";
import hederD from "./components/hederDuo.vue";
import api from  "../../JS/apiint.js"  

export default{
    components:{
        heder,
       hederD
    },

    name: "proJect",
        data(){
       return{
           
            project1: null,
           
        }
    },

        mounted(){
           
            console.log(this,'1') 
  console.log(this)
    this.projects(this)  //использовать для прорисовки по умолчанию
},
        methods:{
        // api.request(options2).then(function (response)

            projects(this2){
                // let result = this.$route.query.project
                // console.log('Hello world','3');
                const options = {
                    method: 'GET',
                    url: 'http://185.221.153.253:5700/api/project/2' ,
                                   
                    };
                  
                    api.request(options).then(function (response) {
                    localStorage.setItem('id', response.data.id)      
                            
                    this2.project1 = response.data
                    console.log(this2.project1);
                    let qwerty = response.data
                    console.log("========");
                    console.log(qwerty);
                    }).catch(function (error) {
                    console.error(error);
                 
                    })
                
            },

            
        }

}
</script>

<style scoped>
.upload{
    width: 329px;
height: 61px;
margin-right: 35%;
margin-top: 25%;

background: #A4A4A4;
border: 3px solid #A4A4A4;
border-radius: 10px;

}
.btnimg{
    width: 100%;
}
.upp{
    margin-left: 85%;
    float: left;
    margin-top: -1.4%;
}
.krug2{
    margin-top: -1.4%;
    margin-left: 1%;
    text-align: center;
    width: 30px;
    height: 30px;
    background-color: #707070;
    border-radius: 15px;
    float: left;
}
.krug2 p{
    margin-top: 10%;
    color: white;
}
.header{
    margin-top: -0.5%;
}
.kartbtn{
    margin-left: 20%;
    background-color: #A4A4A4;
    text-align: center;
}
.kartimg{
    text-align: center;
    margin-left: 15%;
}
.karta{
    display: flex;
}
.next a{
    text-decoration: none;
    color: black;

}
.modal1{
    margin-top: 1%;
}
.inp{
    width: 251px;
height: 50px;
}
.leftmenu{
    width: 20%;
    margin-left: 2%;
    
}
/* .ppp{
     font-size: 24px;
} */
.leftmenu p{
    font-size: 24px;
}
.bt1{
    width: 90px;
    height: 80px;
    background-color: #A4A4A4;
    margin-left: 2%;
}
.bt2{
    width: 132px;
    height: 60px;
    background-color: #D9D9D9;
    font-size: 24px;
}
.inp2{
    width: 90px;
    height: 50px;
    border-radius: 5px;
}
.inp4{
    width: 140px;
    height: 50px;
    border-radius: 5px;
}
.wb{
    display: flex;
}
.wb p{
    font-size: 18px;
}


.wb1{
    margin-left: 1%;
    font-size: 19px;
}
.wb2{
    margin-left: 4%;
}
.holst{

    background-color: rgb(214, 214, 214);
    width: 90%;
height: 800px;
    margin-right: 5%;
    margin-top: -1%;
    margin-left: 10%;
    text-align: right;
}
.content{
    display: flex;
}
.body{
    margin-top: 10px;
    font-family: "Montserrat-Regular";
    /* height: 100vh; */
   
   
}
/* heder */
.heder{
    margin-left: 2%;
    margin-top: 8px;
    font-family: "Montserrat-Regular";
}
.p1{
    float: left;
    margin-left: 0%;
    font-size: 24px;
    text-align: center;
    width: 177px;
    height: 29px;
    font-weight: 400;
    line-height: 29px;
}
.blockp2{
    margin-left: 22%;
}
.blockp2 a{
    text-decoration: none;
    color: black;
}
.p2{
    float: left;
    margin-left: 1%;
    font-size: 16px;
    margin-top: 0.5%;
    text-align: center;
    width: 240px;
    height: 19px;
}
.up a{
    text-decoration: none;
    color: black;
}

</style>