<template>
    <div>
        <div class="header">
            <heder/>
            
        </div>
        <div class="content">

            <div class="contentbox">
               
                    <p>
                        Создайте сами<br> участок мечты
                    </p><br><br>
                    <label>
                        C помощью искусственного<br> интеллекта
                    </label><br>
                    <a href="#auth">
                    <button class="btn button1">
                        <strong>
                              Попробовать
                        </strong>
                      
                    </button>
                    </a>

            </div>

        </div>
    </div>
</template>

<script>
import heder from "./components/hederUno.vue";
export default{
    components:{
        heder
        }
}
</script>

<style scoped>
   
    .contentbox{
        /* margin-top: 9%; */
        height: 50%;
        /* width: 30%; */
       text-align: center;
        /* margin-left: 35%; */
        z-index: 4;
    }
    .contentbox a{
        text-decoration: none;
        color: black;
    }
    .contentbox p{
        margin-top: 20%;
        font-size: 80px ;
        font-family: "Montserrat-Thin";
    }
    .contentbox label{
        margin-top: 0%;
        font-size: 36px ;
        font-family: "Montserrat-Regular";
        margin-top: 2%;
    }

     .button1{
        width: 40%;
        height: 15%;
        font-size: 20px ;
        font-family: "Montserrat-Regular";
        border: 10px;
        background-color: rgb(114, 114, 114);
        margin-top: 6%;
    }
    .content{
        position: fixed;
        width: 100%;
        margin-top: 0px ;
        background-color: rgb(211, 211, 211);
        height: 100%;
        z-index: 0;
        display: flex;
        justify-content: center;
        /* align-items: center; */
    }

    

</style>
