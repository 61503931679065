<template>
   
        <div class="tabs">

            <ul class="tab_header">
                <li
                 v-for="title in tabContentTitles" 
                :key="title"
                :class="{selected: title == selectedTitle}"
                @click="selectedTitle = title"
                
                >
                    {{ title}}
                </li>
            </ul>

        </div>
        
        <slot />
   
</template>

<script>

    import {ref, provide} from 'vue'

export default{

    setup(props, {slots}){
        const tabContentTitles = ref(slots.default().map((tabContent) => tabContent.props.title))
        const selectedTitle = ref(tabContentTitles.value[0])

        provide('selectedTitle', selectedTitle)

        return{
            selectedTitle,
            tabContentTitles,
        }
 
    },

}

</script>

<style scoped>

    .tabs{
        
        max-width: 600px;
        margin-left: 5%;

    }

    .tab_header {
                
        margin-bottom: 0%;
        list-style: none;
        padding: 0;
        display: flex;
        margin-left: 30%;


    }
    .tab_header li{
        margin-top: 5%;
        /* min-width: 83px; */
        text-align: center;
        padding: 10px 20px;
        /* margin-right: 10px; */
        background-color: rgb(255, 255, 255);
        border-radius: 5px 5px 0px 0px ;
        /* border: 1px solid #020202; */
        cursor: pointer;
        transition: 0.4s all ease-out;
        margin-left: 5%;
       
        


    }

    .tab_header li.selected{
        background-color: #ffffff;;
        color: rgba(0, 0, 0, 0.667);
        border-width: 2px;
   border-bottom: 2px rgb(0, 0, 0) solid;
    }
</style>