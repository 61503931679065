<template>
    <heder/> 
    <!-- <hederd/> -->
    <div class="heder">
        <br>
            <div class="up">
                <a href="#constructor">
                <p><img src="./ikons/strelkanazad1.png">  Назад</p>
                </a>
                <div class="versions">
                   
                    <a>
                       <p>
                    Версия 1
                     </p> 
                    </a>
                    <a>
                       <p>
                    Версия 2
                     </p> 
                    </a>
                    <a>
                       <p>
                    Версия 3
                     </p> 
                    </a>
                   
                
             
                </div>
               
            </div>
            <div class="bottom">
                <!-- <div class="blockp1">
                    <div class="p1">
                        <strong>
                            Без названия
                        </strong>
                    </div>
                </div>  -->
               <div class="close">
                    <!-- <save/> -->
                    <button class="btn closebtn">
                        Закрыть проект
                    </button>
                </div>
                <div class="blockp2"> 
                    
                    <a href="#shablon">
                         <div class="p2">
                        Результат генерации
                    </div>  
                    </a>         
                    <a href="#info">
                       <div class="p2">
                        Основные данные
                    </div> 
                    </a>
                    
                    <a href="#visual">
                       <div class="p2">
                        Визуальные предпочтения
                    </div>  
                    </a>
                   
                    <a href="#docs">
                    <div class="p2">
                        Документация
                    </div>
                     
                    </a>
                     <a href="#orderplant">
                    <div class="p2">
                        Подборки растений
                    </div>
                    </a>
   
                </div> <br>

            </div>
            <br>
        </div> 
        <div class="body">
        <div class="heder">
            <!-- <div class="up">
                <input 
                class="inp1"
                 type="text"
                  placeholder="search"
                  v-model="valuesearch"
                  >
                  <button class="btn bttn " @click="search">
                    <img class="imgsrc"  src="./ikons/srcbtn.png">
                  </button>
                <div class="bbtn">

              
               <button v-on:click="openmodal" id="open-modal-btn" class="btn filtbtn">
                   <div class="filt">
                    <strong> Все фильтры </strong>
                   </div>
                   
                    <div class="krug">
                        
                           <p>4</p> 
                    </div>
                </button>
      
              <button class="bt1">
                    Стиль
                </button>
                <button class="bt1">
                    Растения
                </button>
                <button class="bt1">
                    Детали
                </button >
                <button class="bt1">
                    Оттенки
                </button>
                <button class="bt1">
                    Освещенность
                </button>  -->
                <!-- <img class="output" src="./ikons/output-onlinepngtools.png">
                Загрузить свое изображение -->

        <!-- <button >Открыть модальное окно</button> -->
    <!-- </div>  -->

    <div class="modal" id="my-modal1">
        <div class="modal__box1">
            <button class="modal__close-btn" id="close-my-modal-btn">
                <svg width="23" height="25" viewBox="0 0 23 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.09082 0.03125L22.9999 22.0294L20.909 24.2292L-8.73579e-05 2.23106L2.09082 0.03125Z"
                        fill="#333333" />
                    <path d="M0 22.0295L20.9091 0.0314368L23 2.23125L2.09091 24.2294L0 22.0295Z" fill="#333333" />
                </svg>
            </button>
            <h2>Мое модальное окно</h2>

            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quasi, obcaecati, vero, illum ipsa libero labore facilis quo iste numquam deserunt totam reprehenderit quod.</p>

            <p>
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Repudiandae impedit at, beatae consectetur asperiores maiores? Veniam impedit doloribus hic, dolorum odio totam rerum excepturi dolor! Veniam molestiae voluptas obcaecati non natus quae corporis,
                cum deleniti quo, cumque culpa exercitationem ex at numquam. Illo, voluptas itaque corporis amet consequuntur eius molestiae repudiandae rerum, sit id modi inventore. Ut, reiciendis laudantium neque nesciunt ex labore autem dolorum consequuntur
                accusantium molestias perspiciatis corporis illo sit, architecto fuga! Quo neque odio esse sapiente sit reprehenderit pariatur perspiciatis quisquam nulla, voluptas saepe itaque quia provident perferendis ducimus, alias atque aliquid cumque
                nihil laudantium recusandae suscipit!
            </p>
        </div>


                <!--  -->
               
               
             </div>
              
            </div>
            <div class="bott">
               
            
            <div class="tegs">
            <label class="checkbox-btn">
            <input type="checkbox" checked >
            <span>Прованс</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" checked >
            <span>Скульптуры</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" checked >
            <span>Уютное</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" >
            <span>Прованс</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" >
            <span>Скульптуры</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" >
            <span>Уютное</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" checked >
            <span>Прованс</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" >
            <span>Скульптуры</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" >
            <span>Уютное</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" checked >
            <span>Прованс</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" checked>
            <span>Скульптуры</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" >
            <span>Уютное</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" >
            <span>Прованс</span>
            </label>
        </div>
            
         
            <label>
                {{response}}
            </label>
            
            </div>
        </div>
        <div class="content">
           
      
       
            <div id="columns">
  
  <figure>

<!-- <img class="kvadrat" src="./photos/0552645884cfc3ae683ed06958a13fe4.jpeg"> -->
<div class="kvadrat">

</div>
<div class="laik">
    <label class="checkbox-btn2">

    <input type="checkbox"  >
    <span>
        <img class="luk" src="./ikons/ser3.png" >   Нравится

    </span>
</label>

<label class="checkbox-btn2">

<input type="checkbox"  >
<span>
<img class="dizluk" src="./ikons/ser4.png" >   Не нравится

</span>

</label>

<!-- <p>
<img class="luk" src="./ikons/ser3.png" >   Нравится

</p>

<p>
<img class="dizluk" src="./ikons/ser4.png" >   Не нравится

</p> -->

</div> 
<!-- src="./photos/1583600444_35-p-vostochnii-stil-v-landshaftnom-dizaine-87.jpg" -->
</figure>
<figure>
<div class="prugol">
<!-- <img class="prugol" > -->
</div>


<div class="laik">
    <label class="checkbox-btn2">

    <input type="checkbox"  >
    <span>
        <img class="luk" src="./ikons/ser3.png" >   Нравится

    </span>
      </label>

     <label class="checkbox-btn2">

<input type="checkbox"  >
<span>
<img class="dizluk" src="./ikons/ser4.png" >   Не нравится

</span>

     </label>

</div> 


</figure>
<figure>
<div class="drugol"></div>
<!-- <img src="./photos/1625491701_31-almode_ru-p-sovremennii-landshaftnii-dizain-34.jpg"> -->
<div class="laik">
    <label class="checkbox-btn2">

    <input type="checkbox"  >
    <span>
        <img class="luk" src="./ikons/ser3.png" >   Нравится

    </span>
      </label>

     <label class="checkbox-btn2">

<input type="checkbox"  >
<span>
<img class="dizluk" src="./ikons/ser4.png" >   Не нравится

</span>

     </label>

</div> 

</figure>
<figure>
<!-- <img src="./photos/1618688100_67-p-stil-kantri-v-landshaftnom-dizaine-70.jpg"> -->
<div class="prugol"></div>
<div class="laik">
    <label class="checkbox-btn2">

    <input type="checkbox"  >
    <span>
        <img class="luk" src="./ikons/ser3.png" >   Нравится

    </span>
      </label>

     <label class="checkbox-btn2">

<input type="checkbox"  >
<span>
<img class="dizluk" src="./ikons/ser4.png" >   Не нравится

</span>

     </label>

</div> 

</figure>
<figure>
<!-- <img src="./photos/0daeac68c1788181be4c80c30c59098f.jpg"> -->
<div class="kvadrat"></div>
<div class="laik">
    <label class="checkbox-btn2">

    <input type="checkbox"  >
    <span>
        <img class="luk" src="./ikons/ser3.png" >   Нравится

    </span>
      </label>

     <label class="checkbox-btn2">

<input type="checkbox"  >
<span>
<img class="dizluk" src="./ikons/ser4.png" >   Не нравится

</span>

     </label>

</div> 

</figure>
<figure>
<!-- <img src="./photos/1583687789_51-p-stili-landshaftnogo-dizaina-89.jpg"> -->
<div class="kvadrat">

</div>
<div class="laik">
    <label class="checkbox-btn2">

    <input type="checkbox"  >
    <span>
        <img class="luk" src="./ikons/ser3.png" >   Нравится

    </span>
      </label>

     <label class="checkbox-btn2">

<input type="checkbox"  >
<span>
<img class="dizluk" src="./ikons/ser4.png" >   Не нравится

</span>

     </label>

</div> 

</figure>
<figure>
<!-- <img src="./photos/1581025276_17-p-landshaftnie-dizaini-46.jpg"> -->
<div class="drugol"></div>
<div class="laik">
    <label class="checkbox-btn2">

    <input type="checkbox"  >
    <span>
        <img class="luk" src="./ikons/ser3.png" >   Нравится

    </span>
      </label>

     <label class="checkbox-btn2">

<input type="checkbox"  >
<span>
<img class="dizluk" src="./ikons/ser4.png" >   Не нравится

</span>

     </label>

</div>  

</figure>
<figure>
<!-- <img src="./photos/1582214716_10-p-minimalistichnii-landshaftnii-dizain-17.jpg"> -->
<div class="prugol"></div>
<div class="laik">
    <label class="checkbox-btn2">

    <input type="checkbox"  >
    <span>
        <img class="luk" src="./ikons/ser3.png" >   Нравится

    </span>
      </label>

     <label class="checkbox-btn2">

<input type="checkbox"  >
<span>
<img class="dizluk" src="./ikons/ser4.png" >   Не нравится

</span>

     </label>

</div> 

</figure>
<figure>
<!-- <img src="./photos/1584364627_26-p-landshaftnii-dizain-v-skandinavskom-stile-90.jpg"> -->
<div class="drugol"></div>
<div class="laik">
    <label class="checkbox-btn2">

    <input type="checkbox"  >
    <span>
        <img class="luk" src="./ikons/ser3.png" >   Нравится

    </span>
      </label>

     <label class="checkbox-btn2">

<input type="checkbox"  >
<span>
<img class="dizluk" src="./ikons/ser4.png" >   Не нравится

</span>

     </label>

</div>  
<!-- <figcaption>Cinderella wearing European fashion of the mid-1860’s</figcaption> -->

</figure>
<figure>
<!-- <img src="./photos/1584617479_27-p-dizain-uchastkov-s-prudom-84.jpg"> -->
<div class="kvadrat"></div>
<div class="laik">
    <label class="checkbox-btn2">

    <input type="checkbox"  >
    <span>
        <img class="luk" src="./ikons/ser3.png" >   Нравится

    </span>
      </label>

     <label class="checkbox-btn2">

<input type="checkbox"  >
<span>
<img class="dizluk" src="./ikons/ser4.png" >   Не нравится

</span>

     </label>

</div> 
<!-- <figcaption>Cinderella wearing European fashion of the mid-1860’s</figcaption> -->

</figure>
<figure>
<!-- <img src="./photos/0fa57f896fe863b4600cda97910edcce.jpg"> -->
<div class="kvadrat"></div>
<div class="laik">
    <label class="checkbox-btn2">

    <input type="checkbox"  >
    <span>
        <img class="luk" src="./ikons/ser3.png" >   Нравится

    </span>
      </label>

     <label class="checkbox-btn2">

<input type="checkbox"  >
<span>
<img class="dizluk" src="./ikons/ser4.png" >   Не нравится

</span>

     </label>

</div> 

</figure>
<figure>
<!-- <img src="./photos/1625491757_37-almode_ru-p-sovremennii-landshaftnii-dizain-40.jpg"> -->
<div class="prugol"></div>
<div class="laik">
    <label class="checkbox-btn2">

    <input type="checkbox"  >
    <span>
        <img class="luk" src="./ikons/ser3.png" >   Нравится

    </span>
      </label>

     <label class="checkbox-btn2">

<input type="checkbox"  >
<span>
<img class="dizluk" src="./ikons/ser4.png" >   Не нравится

</span>

     </label>

</div> 

</figure>
<figure>
<!-- <img src="./photos/1627177551_43-p-geometriya-v-landshaftnom-dizaine-45.jpg"> -->
<div class="prugol"></div>
<div class="laik">
    <label class="checkbox-btn2">

    <input type="checkbox"  >
    <span>
        <img class="luk" src="./ikons/ser3.png" >   Нравится

    </span>
      </label>

     <label class="checkbox-btn2">

<input type="checkbox"  >
<span>
<img class="dizluk" src="./ikons/ser4.png" >   Не нравится

</span>

     </label>

</div> 

</figure>
<figure>
<!-- <img src="./photos/103.png"> -->
<div class="drugol"></div>
<div class="laik">
    <label class="checkbox-btn2">

    <input type="checkbox"  >
    <span>
        <img class="luk" src="./ikons/ser3.png" >   Нравится

    </span>
      </label>

     <label class="checkbox-btn2">

<input type="checkbox"  >
<span>
<img class="dizluk" src="./ikons/ser4.png" >   Не нравится

</span>

     </label>

</div>  

</figure>
<figure>
<!-- <img src="./photos/1511719296_45-modern.jpg"> -->
<div class="kvadrat"></div>
<div class="laik">
    <label class="checkbox-btn2">

    <input type="checkbox"  >
    <span>
        <img class="luk" src="./ikons/ser3.png" >   Нравится

    </span>
      </label>

     <label class="checkbox-btn2">

<input type="checkbox"  >
<span>
<img class="dizluk" src="./ikons/ser4.png" >   Не нравится

</span>

     </label>

</div> 

</figure>
<figure>
<div class="prugol"></div>
<!-- <img src="./photos/1581025158_41-p-landshaftnie-dizaini-zadnikh-dvorov-80.jpg"> -->
<div class="laik">
    <label class="checkbox-btn2">

    <input type="checkbox"  >
    <span>
        <img class="luk" src="./ikons/ser3.png" >   Нравится

    </span>
      </label>

     <label class="checkbox-btn2">

<input type="checkbox"  >
<span>
<img class="dizluk" src="./ikons/ser4.png" >   Не нравится

</span>

     </label>

</div> 

</figure>
<figure>
<!-- <img src="./photos/1581025276_17-p-landshaftnie-dizaini-46.jpg"> -->
<div class="prugol"></div>
<div class="laik">
    <label class="checkbox-btn2">

    <input type="checkbox"  >
    <span>
        <img class="luk" src="./ikons/ser3.png" >   Нравится

    </span>
      </label>

     <label class="checkbox-btn2">

<input type="checkbox"  >
<span>
<img class="dizluk" src="./ikons/ser4.png" >   Не нравится

</span>

     </label>

</div> 

</figure>

</div>
    <div class="next">
              <a href="#shagtri">
              <button class="btn bn2">
                <p class="gen1">
                  Сгенерировать
                </p>
                <p class="gen2">
                  до доступа к функции<br> осталось выбрать 5 изображений
                </p>
              </button>
              </a>
          </div>
          </div>
          
     
  </template>
  
  
  
  <script>
  import heder from "./components/hederTree.vue";
  import axios from "axios";
//   import upload from "./components/uploadModal.vue";
//   import save from "./components/SaveModal.vue"
  // import filter1 from "./components/filtrModal.vue";
  //import hederd from "./components/hederDuo.vue";
  
  export default{
      components:{
       // hederd,
       
          heder,
        //   upload,
        //   save,
          
      },
  
      methods :{
  
          openmodal(){
               // Открыть модальное окно
               document.getElementById("open-modal-btn").addEventListener("click", function() {
                  document.getElementById("my-modal").classList.add("open")
              })
  
              // Закрыть модальное окно
              document.getElementById("close-my-modal-btn").addEventListener("click", function() {
                  document.getElementById("my-modal").classList.remove("open")
              })
  
              // Закрыть модальное окно при нажатии на Esc
              window.addEventListener('keydown', (e) => {
                  if (e.key === "Escape") {
                      document.getElementById("my-modal").classList.remove("open")
                  }
              });
  
              // Закрыть модальное окно при клике вне его
              document.querySelector("#my-modal .modal__box").addEventListener('click', event => {
                  event._isClickWithInModal = true;
              });
              document.getElementById("my-modal").addEventListener('click', event => {
                  if (event._isClickWithInModal) return;
                  event.currentTarget.classList.remove('open');
              });
                          },
  
          search(){
              const options = {
              method: 'GET',
              url: 'http://185.221.153.253:5700/api/search',
              headers: {Authorization: 'Bearer 0ded13aa-624b-40cf-bef7-dc7a2bf97c2c'},
              data: {
                  f_compos_type: this.valuesearch,
                  f_template: this.valuesearch,
                  f_temp_char: this.valuesearch,
                  f_place: this.valuesearch,
                  f_ground_sost: this.valuesearch,
                  f_ground_kisl: this.valuesearch,
                  f_ct_size: this.valuesearch,
                  f_ct_form: this.valuesearch,
                  f_ct_light: this.valuesearch,
                  f_ct_color: this.valuesearch,
                  f_ct_maf: this.valuesearch,
                  f_ct_vl: this.valuesearch,
                  f_ct_res: this.valuesearch,
                  f_ct_aroma: this.valuesearch,
                  f_ct_plants: this.valuesearch
              }
              };
  
              axios.request(options).then(function (response) {
              console.log(response.data);
              }).catch(function (error) {
              console.error(error);
              });
          }
  
          }
  }
  </script>
  
  <style scoped>


.close{
     /* width: 0%;  */
    text-align: right;
    margin-top: -80px;
}
  .prugol{
    width: 300px;
    height: 400px;
    background-color: #8d8d8d;
  }
  .kvadrat{
    width: 300px;
    height: 300px;
    background-color: #8d8d8d;
  }
  .drugol{
    width: 400px;
    height: 300px;
    background-color: #8d8d8d;
  }
  .versions{
    display: flex;
    text-align: center;
    margin-top: 0.2%;
    margin-left: 37%;
}
.versions p{
    margin-left: 3%;
    width: 85px;
}
  .close{
      width: 90%;
      text-align: right;
  }
  .bottom{
      margin-top: 2%;
  }
  
  .tegs{
      margin-left: 2%;
  }
  .laik{
      color: white;
  /* width: 17%; */
  height: 36px;
  padding: 5px;
  margin-top: -50px;
  margin-left: 5px;
  /* background: #707070; */
  text-align: center;
  border-radius: 10px;
     z-index: 999;
     position: absolute;
     display: flex;
  }
  
  
  .luk{
      /* width: 26px; */
  }
  .laik img{
      width: 26px;
  }
  
  
  .content{
      z-index:4;
      display: block;
      margin-left: 2%;
  }
  .gen1{
      margin-top: 1%;
      font-family: "Montserrat-SemiBold";
      color: black;
      font-size: 24px;
  }
  .gen2{
      margin-top: -3%;
  }
  .next a{
      text-decoration: none;
      color: black;
     opacity: 60%;
  
  }
  .next{
      /* z-index: 999999999999999999999995; */
      margin-top: 350%;
      position: fixed;
      left: 38%;
      /* text-align: center; */
  }
  .bn2{
      width: 400px;
      height: 110px;
      background-color: #cecece;
      border-radius: 60px;
  }
  .img1{
      /* width: 300px; */
    
          /* height: 400pxrelative; */
      background-color: rgb(255, 255, 255);
      margin-left: 2%;
      float: left;
      /* margin-top: 1%; */
      padding: 5px;
      z-index: -444444444444;
    
  }
  .img{
      width: 300px;
      z-index: 1;
      border-radius: 10px;
      /* position: relative; */
  }
  .img2{
     
      /* width: 300px; */
      /* height: 250px; */
      background-color: rgb(255, 255, 255);
      margin-left: 2%;
      float: left;
      /* margin-top: 1%; */
      padding: 5px;
      
  }
  .checkbox-btn {
      display: inline-block;
      margin-top: 1%;
      margin-left: 2%;
      user-select: none;
      position: relative;
      /* border-radius: 25px; */
  
  }
  .checkbox-btn input[type=checkbox] {
      z-index: -1;
      opacity: 0;
      display: block;
      width: 0;
      height: 0;
      background: #8d8d8d;
  }
  .checkbox-btn span {
      display: inline-block;
      cursor: pointer;
      padding: 0px 10px;
      background: #8d8d8d;
      line-height: 30px;
      border: 1px solid #999;
      border-radius: 24px;
      transition: background 0.2s ease;
  }
   
  /* Checked */
  .checkbox-btn input[type=checkbox]:checked + span {
      background: #97c004;
      color: white;
  }
  /* ================= */
  .checkbox-btn1 {
      display: inline-block;
      margin-top: 1%;
      margin-left: 2%;
      user-select: none;
      position: relative;
      /* border-radius: 25px; */
  
  }
  .checkbox-btn1 input[type=checkbox] {
      z-index: -1;
      opacity: 0;
      display: block;
      width: 0;
     
      height: 0;
      background: #8d8d8d;
  }
  .checkbox-btn1 span {
      display: inline-block;
      cursor: pointer;
      padding: 0px 15px;
      background: #ffffff;
      line-height: 35px;
      border: 1px solid #747474;
      border-radius: 5px;
      transition: background 0.2s ease;
  }
   
  /* Checked */
  .checkbox-btn2 input[type=checkbox]:checked + span {
      background: #858585;
      color: white;
      opacity: 90%;
  }
  /* ================= */
  /* ================= */
  .checkbox-btn2 {
      display: inline-block;
      /* margin-top: 1%;
      margin-left: 0%; */
      user-select: none;
      position: relative;
      /* border-radius: 25px; */
  
  }
  .checkbox-btn2 input[type=checkbox] {
      z-index: -1;
      opacity: 0;
      display: block;
      width: 0;
      height: 0;
      background: #8d8d8d;
  }
  .checkbox-btn2 span {
      display: flex;
      /* width: auto; */
      justify-content: center;
      cursor: pointer;
      padding: 0px 15px;
      background: #8d8d8d;
      line-height: 35px;
      border: 1px solid #747474;
      border-radius: 5px;
      transition: background 0.2s ease;
  }
   
  /* Checked */
  .checkbox-btn2 input[type=checkbox]:checked + span {
      background: #e70303;
      color: white;
      opacity: 90%;
  }
  /* ================= */
  .up input{
      float: left;
     
  }
  .up{
    display: flex;
    margin-top: -0.2%;
    
    /* width: 90%; */
  }
 
  @media screen and (max-width: 1880px) {
      .inp1{
      margin-left: 2%;
      width:45%;
      text-align: left;
  }
  }
  @media screen and (max-width: 1680px) {
      .inp1{
      margin-left: 2%;
      width:30%;
      text-align: left;
  }
  }
  .bttn{
      width: 100px;
  }
  .imgsrc{
      width: 100%;
      height: 35px;
  }
  .heder{
      width: 98%;
      margin-top: 0%;
  }
  .body{
      height: 120%;
      margin-top: 0px;
      font-family: "Montserrat-Regular";
  }
  /* Heder */
  
  .heder{
      margin-left: 2%;
      margin-top: -2px;
      font-family: "Montserrat-Regular";
  }
  .p1{
      float: left;
      margin-left: 0%;
      font-size: 24px;
      text-align: center;
      width: 177px;
      height: 29px;
      font-weight: 400;
      line-height: 29px;
  }
  .blockp2{
      margin-left: 12%;
      text-align: center;
  }
  .blockp2 a{
      text-decoration: none;
      color: black;
      text-align: center;
  }
  .p2{
      float: left;
      margin-left: 1%;
      font-size: 16px;
      margin-top: 0.5%;
      text-align: center;
      width: 240px;
      height: 19px;
  }
  .up a{
      text-decoration: none;
      color: black;
  }
  
  /* модальное окно */
  /* ============== */
  /* ============== */
  /* ============== */
  /* ============== */
  /* ============== */
  /* ============== */
  /* ============== */
  
  html {
      box-sizing: border-box;
  }
  
  *,
  *::before,
  *::after {
      box-sizing: inherit;
  }
  
  a {
      color: inherit;
      text-decoration: none;
  }
  
  img {
      max-width: 100%;
  }
  
  body {
      font-family: Arial, Verdana, sans-serif;
  }
  
  .container {
      max-width: 1200px;
      margin: 0 auto;
      padding: 0 15px;
  }
  
  .modal {
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100vh;
      z-index: 99998;
      background-color: rgba(0, 0, 0, .3);
      display: grid;
      align-items: center;
      justify-content: center;
      overflow-y: auto;
      visibility: hidden;
      opacity: 0;
      transition: opacity .4s, visibility .4s;
  }
  
  .modal__box1 {
      position: relative;
      max-width: 500px;
      padding: 45px;
      z-index: 1;
      margin: 30px 15px;
      background-color: white;
      box-shadow: 0px 0px 17px -7px rgba(34, 60, 80, 0.2);
      transform: scale(0);
      transition: transform .8s;
  }
  
  .modal__close-btn {
      position: absolute;
      top: 8px;
      right: 8px;
      border: none;
      background-color: transparent;
      padding: 5px;
      cursor: pointer;
  }
  
  .modal__close-btn svg path {
      transition: fill .4s;
  }
  
  .modal__close-btn:hover svg path {
      fill: blue;
  }
  
  .modal__close-btn:active svg path {
      fill: rgb(186, 53, 0);
  }
  
  .modal.open {
      visibility: visible;
      opacity: 1;
  }
  
  .modal.open .modal__box {
      transform: scale(1);
  }
  
  /* ================ */
  /* body {
    background: url(//subtlepatterns.com/patterns/scribble_light.png);
    font-family: Calluna, Arial, sans-serif;
    min-height: 1000px;
  } */
  #columns {
      /* z-index: 2111; */
    column-width: 320px;
    column-gap: 15px;
    width: 100%;
    max-width: 100%;
    margin: 50px auto;
  }
  
  div#columns figure {
    background: #fefefe;
    /* border: 2px solid #fcfcfc; */
    /* box-shadow: 0 1px 2px rgba(34, 25, 25, 0.4); */
    margin: 0 2px 15px;
    padding: 15px;
    padding-bottom: 10px;
    transition: opacity .4s ease-in-out;
    display: inline-block;
    column-break-inside: avoid;
  }
  
  div#columns figure img {
    /* width: 100%;  */
    height: auto;
    /* border-bottom: 1px solid #ccc;
    padding-bottom: 15px; */
    margin-bottom: 5px;
    
  }
  .luk{
      width: 26px;
  }
  .dizluk{
      width: 26px;
      
  }
  .laik img{
      width: 26px;
  }
  div#columns figure figcaption {
    font-size: .9rem;
    color: #444;
    line-height: 1.5;
  }
  
  div#columns small { 
    font-size: 1rem;
    float: right; 
    text-transform: uppercase;
    color: #aaa;
  } 
  
  div#columns small a { 
    color: #666; 
    text-decoration: none; 
    transition: .4s color;
   
  }
  
  div#columns:hover figure:not(:hover) {
    /* opacity: 0.4; */
  }
  
  @media screen and () { 
    #columns { column-gap: 0px; }
    #columns figure { width: 100%; }
  }
  </style>
  
