<template>
    <div class="body">
        <div class="heder">
            <a href="#alias">
            <div class="logo">
               <!-- <img  class="img5" src="@/component/pages/ikons/logo1.png">  -->
                A I . G A R D E N 
            
            </div>
            </a>
            <div class="panel">
                <!-- <a  href="">
                <button v-bind="!accesstoken" class="btn buton">
                   Мои проекты
                </button>
                </a> -->
                <!-- <a  href="">
                <button class="btn buton">
                    Тарифы
                </button>
                </a>
                <a  href="">
                <button class="btn buton">
                   Галерея
                </button>
                    </a>
                    <a  href="">
                <button class="btn buton">
                   Как работать
                </button>
                    </a>
                    <a  href="">
                <button class="btn buton">
                  Сообщество
                </button>
                    </a> -->
                    <header>
                    <input class="menu-btn" type="checkbox" id="menu-btn" name="menu-btn" />
                    <label class="menu-icon" for="menu-btn">
                        <span class="navicon" aria-label="Hamburger menu 'icon'" />
                    </label>
                    <nav class="menu">
                        <a class="nav-item" href="#">                            
                          Мои проекты                                                 
                        </a>
                       
                        <a class="nav-item" href="#">                           
                          О нас                
                        </a>
                        <a class="nav-item qweasd" href="#">                             
                          Тарифы и цены                      
                         </a>
                        <a class="nav-item" href="#">                          
                          Как работать                         
                        </a>
                        <a class="nav-item qweasd" href="#">                             
                          Галерея                       
                         </a>
                       
                    </nav>
                
                     <div class="rightbutton" >       
           
                  <p class="test" id="test">
                    
                  </p>
                  
                <!-- fasd
 qwer
                {{profile1 }}
                {{(prifile)? profile1 : ''}}
                {{(optionspr)? prifile.profile1 : ''}}
                        {{optionspr.profile1}}
                        {{prifile.response.data.profile.email}}
              -->
               
                </div> 
                
                    </header>
                  
            </div>
           
                
            </div>
        </div>
   
  
</template>

<script>
 import apiint from  "../../../JS/apiint.js"  

//import api from  "../../../JS/api.js"  
//import auth from'./authModal.vue'
    export default{
        components:{
          //  auth,
        },
        mounted(){

           
        console.log(this)
   this.prifile()
        this.getus(this) 

        },
       

        methods:{

          prifile(){
                const optionspr = {
                method: 'GET',
                url: 'http://185.221.153.253:8000/api/aigarden/profile/me',
                // headers: {
                //     Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2ODcwOTk2NzYsInVpZCI6MTR9.4Qwjrlh1wIoBj2OwrEABUI30pMiyypnw4_yXNTfK0AI'
                // }
                };

                apiint.request(optionspr).then(function (response) {
                    console.log("hed");   
                    console.log(response.data.profile.email);
                    console.log(response.data.profile.kind);
                   
                    let profile1 = response.data.profile.email
                    console.log("hed222");   
                    console.log(profile1);
                    let user = profile1.replace('email=','')
                    console.log(user)
                    document.getElementById('test').textContent = user
                }).catch(function (error) {
                console.error(error);
                });
             },

            // getus(this2){

            // const options9 = {
            // method: 'GET',
            // url: 'http://185.221.153.253:5700/api/user/current',
            // headers: {
            //    // Authorization: 'Bearer d886afc0-ba19-4b36-b768-f6b8db94a09d'
            
            
            // },
            // data: {

            //   //  pin: 'user4'
            // }
            // };

            // api.request(options9).then(function (response) {
            // console.log(response.data);
            // this2.project11 = response.data
            // let qwerty = response.data
            //     console.log("========");
            //     console.log(qwerty);
            // }).catch(function (error) {
            // console.error(error);
            // });
            // }

            


            }


    }
    
    
</script>

<style scoped>
/*  */

/*  */

.test{
  float: left;
}

.qweasd{
    width: 300px;
    
}
 .body{
     height: 90px;
     /* position: absolute; */
     /* top: 0; */
     width: 100%;
     background-color: rgb(255, 255, 255);
     padding: 5px;
 }
    .heder{
        margin-top: 0.9%;
        font-family: "Montserrat-Regular";
        display: flex;
    }
    .heder a{
        text-decoration: none;
    }
    .logo{
   
        /* position: absolute; */
        margin-left: 12.5%;
        font-size: 36px;
        font-family: "Montserrat-SemiBold";
        color: rgb(0, 0, 0);
        width: 300px;
        text-align: left;
        /* display:flex; */
    }
    .logo img{
        width: 15%;

    }
    .panel{
        /* position: absolute; */
        margin-left: 5%;
        width: 70%;
        /* text-align: center; */
        /* justify-content: center; */
        margin-top: -0.8%;
      

    }
    .panel a{
        /* position: absolute; */
        /* margin-left: 1%; */
        width: 80%;
        color: black;
        font-size: 24px;
        text-align: center;
        /* justify-content: center; */

    }
    .buton{
        color: rgb(0, 0, 0);
        font-size: 24px;
        margin-left: 2%;
    }
    .rightbutton{
      position: absolute;
      top:40px;
      right: 50px;
      /* float: left; */
        /* position: absolute;
        right: 10%; */
        color: rgb(202, 202, 202);
        /* margin-left: 0%; */
        z-index: 2222;
        /* text-align: right; */
        /* height: 50px; */
    }
    .buton2{
        background-color:rgb(211, 211, 211);
        color: rgb(0, 0, 0);
        font-size: 25px;
        margin-left: 2%;
        border-radius: 40px;
    }
    /*  ============================ */
  

#menu-btn {
  position: absolute;
  top: -100%;
  left: -100%;
}
/* Hide hamburger for bigger screens */
.menu-icon {
  visibility: hidden;
}
.menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 111;
  /* max-width: 250px; */
  margin-right: 2rem;
}
.nav-item{
  width: auto;
}
/* Set width for mobile/smaller screen size. */
/* I set it big here so I don't have to shrink the screen so much */
/* for testing purposes */
@media screen and (max-width: 1455px) {
  header {
    display: grid;
    grid-template-areas:
      "title title hamburger"
      "nav nav nav";
  }
  h1 {
    grid-area: title;
  }
  .menu a {
    text-decoration: none;
    color: black;
    font-size: 18px;
  }
  .menu-btn {
    display: none;
  }
  .menu-icon {
    grid-area: hamburger;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    padding: 30px 20px 30px 0;
    position: relative;
    user-select: none;
    visibility: visible;
  }
  .navicon {
    background: #333;
    display: block;
    height: 2px;
    width: 18px;
    position: relative;
  }
  .navicon:before {
    top: 5px;
  }
  .navicon:after {
    top: -5px;
  }
  .navicon:before,
  .navicon:after {
    background: #333;
    display: block;
    width: 100%;
    height: 100%;
    content: "";
    position: absolute;
    transition: all 0.2s ease-out;
  }
  .menu {
    grid-area: nav;
    max-width: unset;
    max-height: 0;
    transition: max-height 0.2s ease-out;
    overflow: hidden;
    margin: 0;
    padding: 0;
    background-color: #fff;
    display: flex;
    flex-direction: column;
  }
  .menu a {
    padding: 20px 20px;
    border-right: 1px solid #f4f4f4;
    background-color: #ffffff;   
    
    opacity: 100%;
    text-align: center;
  }
  .menu-btn:checked ~ .menu {
    max-height: 240px;
  }
  .menu-btn:checked ~ .menu-icon .navicon {
    background: transparent;
  }
  .menu-btn:checked ~ .menu-icon .navicon:before {
    transform: rotate(-45deg);
  }
  .menu-btn:checked ~ .menu-icon .navicon:after {
    transform: rotate(45deg);
  }
  .menu-btn:checked ~ .menu-icon .navicon:before,
  .menu-btn:checked ~ .menu-icon .navicon:after {
    top: 0;
  }
}
</style>
