<template>
    <heder/> 
    <!-- <hederd/> -->
        <div class="heder">
        <br>
            <div class="up">
                <a href="#alias">
                <p><img src="./ikons/strelkanazad1.png">  Назад</p>
                </a>
               
               
            </div>
            <div class="bottom">
               <div class="blockp1">
                    <div class="p1">
                        <strong>
                            Без названия
                        </strong>
                    </div>
                </div> 
               

            </div>
            <br>
        </div> 
    <div class="body">

        <div class="content">
           
            
            <table>
                <tr class="str1">
                    <td>
                        Результат генерации
                    </td>
                    <td>

                    </td>
                    <td>
                        Экологические условия
                    </td>
                    <td>
                        Бюджет
                    </td>
                    <td>
                        Ваши предпочтения
                    </td>
                    <td>
                        Теги
                    </td>
                    <td>
                        Подборка
                    </td>
                    <td>
                        Документы
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="img">
                            <img class="img" src="./ikons/flowers.jpeg">
                        </div>
                    </td>
                    <td >
                          Версия 1
                        <div class="imgbox">
                            
                        <div >
                            <img class="img2" src="./ikons/flowers.jpeg">
                        </div>
                        <div >
                            <img class="img2" src="./ikons/flowers.jpeg">
                        </div>
                        <div >
                            <img class="img2" src="./ikons/flowers.jpeg">
                        </div>
                                </div>
                    </td>
                    <td>
                       Зона зимостойкости 4<br>
                            Полутень
                    </td>
                    <td>
                        2421p-3060p
                    </td>
                    <td>
                        Беседки, цветы, очаг, зона, <br>
                    посмотреть все
                    </td>
                    <td>
                        Розы, оранжевый, английский,<br>
посмотреть все
                    </td>
                    <td>
                        3060p
                    </td>
                    <td>
                        Ведомость растений<br>
Генплан
                    </td>
                </tr>
                <tr>
                   <td>
                    <hr>
                   </td>
                   <td>
                    <hr>
                   </td>
                   <td>
                    <hr>
                   </td>
                   <td>
                    <hr>
                   </td>
                   <td>
                    <hr>
                   </td>
                   <td>
                    <hr>
                   </td>
                   <td>
                    <hr>
                   </td>
                   <td>
                    <hr>
                   </td>
                </tr>
                <tr>
                    <td>
                        <div class="img">
                            <img class="img" src="./ikons/flowers.jpeg">
                        </div>
                    </td>
                    <td >
                          Версия 1
                        <div class="imgbox">
                            
                        <div >
                            <img class="img2" src="./ikons/flowers.jpeg">
                        </div>
                        <div >
                            <img class="img2" src="./ikons/flowers.jpeg">
                        </div>
                        <div >
                            <img class="img2" src="./ikons/flowers.jpeg">
                        </div>
                                </div>
                    </td>
                    <td>
                       Зона зимостойкости 4<br>
                            Полутень
                    </td>
                    <td>
                        2421p-3060p
                    </td>
                    <td>
                        Беседки, цветы, очаг, зона, <br>
                    посмотреть все
                    </td>
                    <td>
                        Розы, оранжевый, английский,<br>
посмотреть все
                    </td>
                    <td>
                        3060p
                    </td>
                    <td>
                        Ведомость растений<br>
Генплан
                    </td>
                </tr>
                <tr>
                   <td>
                    <hr>
                   </td>
                   <td>
                    <hr>
                   </td>
                   <td>
                    <hr>
                   </td>
                   <td>
                    <hr>
                   </td>
                   <td>
                    <hr>
                   </td>
                   <td>
                    <hr>
                   </td>
                   <td>
                    <hr>
                   </td>
                   <td>
                    <hr>
                   </td>
                </tr>
                <tr>
                    <td>
                        <div class="img">
                            <img class="img" src="./ikons/flowers.jpeg">
                        </div>
                    </td>
                    <td >
                          Версия 1
                        <div class="imgbox">
                            
                        <div >
                            <img class="img2" src="./ikons/flowers.jpeg">
                        </div>
                        <div >
                            <img class="img2" src="./ikons/flowers.jpeg">
                        </div>
                        <div >
                            <img class="img2" src="./ikons/flowers.jpeg">
                        </div>
                                </div>
                    </td>
                    <td>
                       Зона зимостойкости 4<br>
                            Полутень
                    </td>
                    <td>
                        2421p-3060p
                    </td>
                    <td>
                        Беседки, цветы, очаг, зона, <br>
                    посмотреть все
                    </td>
                    <td>
                        Розы, оранжевый, английский,<br>
посмотреть все
                    </td>
                    <td>
                        3060p
                    </td>
                    <td>
                        Ведомость растений<br>
Генплан
                    </td>
                </tr>
            </table>


        </div>
           
       
    </div>
    
</template>



<script>
import heder from "./components/hederTree.vue";
import axios from "axios";
// import filter1 from "./components/filtrModal.vue";
//import hederd from "./components/hederDuo.vue";

export default{
    components:{
     // hederd,
     
        heder,

       
        
    },

    methods :{

        openmodal1(){
            // Открыть модальное окно
                document.getElementById("open-modal-btn").addEventListener("click", function() {
                    document.getElementById("my-modal1").classList.add("open")
                })

                // Закрыть модальное окно
                document.getElementById("close-my-modal-btn").addEventListener("click", function() {
                    document.getElementById("my-modal1").classList.remove("open")
                })

                // Закрыть модальное окно при нажатии на Esc
                window.addEventListener('keydown', (e) => {
                    if (e.key === "Escape") {
                        document.getElementById("my-modal1").classList.remove("open")
                    }
                });

                // Закрыть модальное окно при клике вне его
                document.querySelector("#my-modal1 .modal__box").addEventListener('click', event => {
                    event._isClickWithInModal = true;
                });
                document.getElementById("my-modal1").addEventListener('click', event => {
                    if (event._isClickWithInModal) return;
                    event.currentTarget.classList.remove('open');
                });
                        },

        search(){
            const options = {
            method: 'GET',
            url: 'http://185.221.153.253:5700/api/search',
            headers: {Authorization: 'Bearer 0ded13aa-624b-40cf-bef7-dc7a2bf97c2c'},
            data: {
                f_compos_type: this.valuesearch,
                f_template: this.valuesearch,
                f_temp_char: this.valuesearch,
                f_place: this.valuesearch,
                f_ground_sost: this.valuesearch,
                f_ground_kisl: this.valuesearch,
                f_ct_size: this.valuesearch,
                f_ct_form: this.valuesearch,
                f_ct_light: this.valuesearch,
                f_ct_color: this.valuesearch,
                f_ct_maf: this.valuesearch,
                f_ct_vl: this.valuesearch,
                f_ct_res: this.valuesearch,
                f_ct_aroma: this.valuesearch,
                f_ct_plants: this.valuesearch
            }
            };

            axios.request(options).then(function (response) {
            console.log(response.data);
            }).catch(function (error) {
            console.error(error);
            });
        }

        }
}
</script>

<style scoped>

.img{
    width: 112px;
    height: 112px;
    /* background-color: rgb(218, 218, 218); */
    margin-left: 25%;
}
.imgbox{
    display: flex;
}
.imgbox div{
    margin-left: 2%;
}
.img2{
    width: 56px;
    height: 56px;
    background-color: rgb(218, 218, 218);
    margin-left: 2%;
    margin-top: 5%;
}
.str1{
    font-size: 24px;
    margin-left: 1%;
}
.str1 td{
    margin-left: 10%;
}
.content table{
    text-align: center;
 
    width: 100%;
}
.content{
    width: 98%;
}
.content table{
    margin-left: 1.5%;
}

.bottom{
    text-align: left;
    font-size: 24px;
    margin-left: 2%;
}
.up input{
    float: left;
   
}
.up{
    display: flex;
    margin-top: -0.2%;
}

.heder{
    width: 98%;
    margin-top: 0%;
    text-align: center;
}
.heder a{
    text-align: center;
    justify-content: center;
    align-content: center;
}
.body{
    height: 120%;
    margin-top: 0px;
    font-family: "Montserrat-Regular";
}
/* Heder */

.heder{
    margin-left: 2%;
    margin-top: -2px;
    font-family: "Montserrat-Regular";
}


.up a{
    text-decoration: none;
    color: black;
}





</style>
