<template>
    <div class="body">
        <!-- <div class="hedpoloska"></div> -->
        <div class="bcontent">
            <div class="searchbox">
                <input class="search" typy="search" placeholder=" Искать файл" >
                <button class="btn buttonsrc">
                    <img src="./ik/srcbtn.png">
                </button>
            </div>
<!-- ----------------------------------------- -->
            <div class="papki">
                <div class="create">
                  
                    <button @click="createfolder" class="btn buttoncrt">
                          <div class="krujok">
                    <img class="image2" src="@/component/pages/ikons/23.png" alt="">
                    </div>
                  <p>Создать папку</p>

                    </button>
                </div><br>
                <!-- <button @click="getfolder">
                qwe
                </button> -->
                <div class="akordeon"
              
                >
                 
                        <details class="style3"   
                          v-for:="options5 in project1"
                          >                 
                        

                            <summary>                   
                      
                                <img 
                                class="image1" 
                                src="@/component/pages/ikons/21.png" > 
                                  {{options5.id }}
                          {{project1.title}}
                          {{(options5)? project1.title : ''}}
                          {{(options5)? project1.id : ''}}
                            </summary>
                            <div class="content">
                                <!-- <p>
                                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Modi unde, ex rem voluptates autem aliquid veniam quis temporibus repudiandae illo, nostrum, pariatur quae! At animi modi dignissimos corrupti placeat voluptatum!
                                </p>
                            
                                <p>
                                Facilis ducimus iure officia quos possimus quaerat iusto, quas, laboriosam sapiente autem ab assumenda eligendi voluptatum nisi eius cumque, tempore reprehenderit optio placeat praesentium non sint repellendus consequuntur? Nihil, soluta.
                                </p> -->
                            </div>
                        </details>   <br>
                        <details class="style3">
                            <summary><img class="image1" src="@/component/pages/ikons/21.png" >Мои композиции </summary>
                            <div class="content">
                                <div class="contleft">

                                 <img class="image1" src="@/component/pages/ikons/fail.png" >Новый файл<br>
   
                                </div>

                            </div>
                        </details>   <br>  
                        <details class="style3">
                            <summary><img class="image1" src="@/component/pages/ikons/21.png" >Мои зоны</summary>
                            <div class="content">
                                <!-- <p>
                                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Modi unde, ex rem voluptates autem aliquid veniam quis temporibus repudiandae illo, nostrum, pariatur quae! At animi modi dignissimos corrupti placeat voluptatum!
                                </p>
                            
                                <p>
                                Facilis ducimus iure officia quos possimus quaerat iusto, quas, laboriosam sapiente autem ab assumenda eligendi voluptatum nisi eius cumque, tempore reprehenderit optio placeat praesentium non sint repellendus consequuntur? Nihil, soluta.
                                </p> -->
                            </div>
                        </details>   <br>
                        <details class="style3">
                            <summary><img class="image1" src="@/component/pages/ikons/21.png" >Мои участки</summary>
                            <div class="content">
                                <!-- <p>
                                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Modi unde, ex rem voluptates autem aliquid veniam quis temporibus repudiandae illo, nostrum, pariatur quae! At animi modi dignissimos corrupti placeat voluptatum!
                                </p>
                            
                                <p>
                                Facilis ducimus iure officia quos possimus quaerat iusto, quas, laboriosam sapiente autem ab assumenda eligendi voluptatum nisi eius cumque, tempore reprehenderit optio placeat praesentium non sint repellendus consequuntur? Nihil, soluta.
                                </p> -->
                            </div>
                        </details>      <br>     
                </div>
               
            </div>
             <!-- <div class="fail">
         
                   <div class="contleft">
                    <img class="image1" src="@/component/pages/ikons/fail.png" >Кусты мусты<br>
                    </div> <br>
                    <div class="contleft">
                    <img class="image1" src="@/component/pages/ikons/fail.png" >Красота и точка<br>
                    </div>
              </div><br> -->
              <div class="links">
                <a href="#orders">
                    <strong @click="getfolder">
                         Мои заказы
                    </strong>
                   
                </a><br><br>
                <a>
                    <strong>
                          Мне понравилось   
                    </strong>
                   
                </a>
              </div>
        </div>
        <!-- <div class="poloska">

        </div> -->
    </div>
</template>

<script>
      // import axios from "axios"
// import api from  "../../../JS/api.js"  
import apiint from  "../../../JS/api.js"  
 //  import contentA from "./components/contentAlias.vue"
export default{

    
    
    data(){
       return{
        project1: null,  
        folder: null,
           
        }
    },
    


        mounted(){

           
  console.log(this)
     //использовать для прорисовки по умолчанию
     this.folder(this)
    this.getfolder(this) 
   this.getfolder

},
        methods:{
       // api.request(options2).then(function (response)

           
            createfolder(){
                const options = {
                method: 'POST',
                url: 'http://185.221.153.253:8000/api/aigarden/catalog',
               
                data: {title: 'string1', parent_id: 0}
                };

                apiint.request(options).then(function (response) {
                console.log(response.data);
                }).catch(function (error) {
                console.error(error);
                });
            },


            getfolder(this2){
            
            const options5 = {
                method: 'GET',
                url: 'http://185.221.153.253:8000/api/aigarden/catalog',
                               
                };
              
                apiint.request(options5).then(function (response) {
              //  localStorage.setItem('id', response.data.id)      
              console.log(response.data);  
                this2.project1 = response.data
                // console.log(this2.project1);
                let qwerty = response.data
                console.log("========");
                console.log(qwerty, "qwe");
                console.log("Hello")
                }).catch(function (error) {
                console.error(error);
             
                })
                
            
        },

            // getfolder(){
               
            //     // console.log(folder)
              
            //     console.log(this)
            //     const options2 = {
            // method: 'GET',
            // url: 'http://185.221.153.253:5700/api/picfolders',
            //     headers: {
                 
            //     },
            
            // };
            // let fol = null
            // api.request(options2).then(function (response) {
               
            // console.log(response.data);
            // let folder = null
            // fol=response.data
            // folder=response.data
            // console.log(fol, "qwer");
            // console.log(folder, "qwer");
            // }).catch(function (error) {
            // console.error(error);
            // });
            // }

                    
            
         }
}
</script>

<style scoped>

.links{
    margin-left: 8%;
    margin-top: 4%;
}
.links a{
    margin-top: 10%;
    text-decoration: none;
    color: black;
}
.style3{
    margin-top: 30%;
}
.buttoncrt{
    display: flex;
    width: 80%;
    text-align: center;
}
.buttoncrt p{
    margin-top: 6%;
}
.contleft{
    margin-top: 4%;
    margin-left: 10%;
}
.fail{
    
    margin-left: -3%;
}
.searchbox{
    margin-top: 18%;
    margin-left: 9%;
    display: flex;
}
.search{
    border-radius: 5px;
    width: 90%;
}
.akordeon{
    margin-top: 15%;
    margin-left: 5%;
    
}
.akordeon details{
    margin-top: 5%;
}
.papki{
    margin-left: 4%;
    width: 100%;
}
.image2{
    margin-top: 14%;
    /* border-radius: 5%; */
    /* width: 100%; */
}
.krujok{
    margin-left: 2%;
    background-color: #D9D9D9 ;
    width: 50px;
    height: 50px;
    text-align: center;
    border-radius: 105px;

}
.create{
    margin-top: 14%;
    display: flex;
   
}
.create p{
     font-size: 24px;
     font-family: "Montserrat-Semibold";
     margin-top: 3%;
     margin-left: 4%;
}
.buttonsrc{
    margin-left: 2%;
    /* background-color: #D9D9D9 ; */
}
.details.style3{
    margin-top: 43%;
}
details.style3 summary::before {
  /* content: "›"; */
  font-size: 2.5rem;
  
  line-height: 2rem;
  top: 1.3rem;
  left: 0rem;
  /* margin: -0.5rem -0.5rem 0 0.5rem; */
  
  transform-origin: bottom center;
  transition: margin linear 0.05s;
  
}
details.style3:hover > summary:before {
  color: #FFF;
}
details[open].style3 > summary:before {
  left: 0rem;
  color: #CCC;
  transform: rotate(90deg);
  margin-left: 0.4rem;
  transition: color ease 2s, transform ease 1s, margin ease 1s;
}
@supports (-webkit-touch-callout: none) {
  details.style3 summary::before { top: 1.6rem; }
  details[open].style3 > summary:before { top: 1.3rem; transition: all 0.8s; }
}
.bcontent{
  /* width: 20%; */
  margin-left: 4%;
    float: left;
    margin-left: 2%;
}
.poloska{
    height: 100vh;
    width: 2.5px;
    background-color:#D9D9D9;
    margin-left: 99%;
    margin-top: -2%;
}
.hedpoloska{
    height: 5px;
    width: 100%;
    background-color:#D9D9D9;
    /* margin-left: 99%; */
}
.body{
    margin-top: 3px;
    font-family: "Montserrat-Regular";
    font-size: 24px;
    background-color: rgb(255, 255, 255);
    height: 100vh;
    width: 20%;
    z-index: 5;
    float: left;
    border-right:  4px solid #D9D9D9 ;
}
</style>
