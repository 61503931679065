<template>
    <div class="body">
        
        <!-- <div class="hed">
            <div class="lefthed">

                <h1>
                    <p class="hedtext1">
                        AI.Garden
                    </p>

                </h1>
                <p class="hedtext2">
                    Создай сад мечты
                </p>
                <a>
                <button class="btn hedbtn">
                     Легко!
                </button>
                </a>
                
            </div>
            <div class="righthed">
                <img class="img1" src="./assets/tild6432.jpg">
            </div> -->

        </div>
        <br><br><br><br><br>
        <cont2 class="cont2">
            <a>
            <button class="btn contacts">
                Написать нам в WhatsApp
            </button><br>
            </a>
            <!--  -->
            <a>
            <button class="btn contacts">
                Подписаться на Boosty
            </button><br>
            </a>
            <!--  -->
            <a>
            <button class="btn contacts">
                Отправить предложение на e-mail
            </button>
            </a>
        </cont2>


        <!-- <div class="support">
            <button class="btn sap">
                Поддержать проект 
            </button>
            <button class="btn sap">
                Подписаться на новости
            </button>
            <button class="btn sap">
                Стать партнёром
            </button>
        </div> -->
        <!-- <div class="srp" ></div> -->
      
    
</template>

<script>


export default{
    components:{
        
        
    },


}
</script>

<style scoped>
.cont2{
  /* margin-top: 55%; */
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
}
.contacts{
    background-color: black;
    color: white;
    width: 550px;
    height: 55px;
    border-radius: 30px;
    padding: 5px;
    font-size: 24px;
    margin-top: 0.5%;
    text-align: center;
    /* margin-left: 3%; */
    /* min-width: 480px; */
}
@media screen and (max-width: 600) {
    .contacts{
        width: 400px;
        font-size: 18px;
    }
}
.srp{
    width: 100%;
    height: 100px;
    background-color: rgba(202, 202, 202, 0.788);
    margin-top: 7%;
}
.support{
    width: 60%;
    margin-top: 6%;
    margin-left: 30%;
}
.sap{
    width: 65%;
    height: 70px;
    border-radius: 50px;
    color: white;
    background-color: #a0b19a;
    text-align: left;
    font-size: 20px;
    margin-top: 1%;
}
.polosocka{
    width: 200px;
    height: 2px;
    background-color: black;
    margin-bottom: 5%;
}
.content{
    width: 70%;
    margin-top: 3%;
    margin-left: 25%;
}

.hedcontent{
    float: left;
    text-align: left;
    width: 35%;
    font-size: 25px;
    z-index: 5;

}
.bodycontent{
    text-align: left;
    width: 70%;
    font-size: 25px;
    margin-top: 9%;
    z-index: 4;
}
.hedcontentG{
    font-size: 50px;
    width: 34%;
    margin-left: 36%;
   
}
/* .body{
    font-family: "Montserrat-Regular";
}
.hed{
    margin-top: 8%;
    margin-left: 20%;
    width: 80%;
    

} */
.lefthed{
   
    float: left;
    text-align: left;
}
.hedtext1{
    font-size: 90px;
     font-family: "Montserrat-Regular";
}
.hedtext2{
    color: rgb(172, 172, 172);
    font-size: 70px;
}
.righthed{

}
.img1{
    width: 40%;
}

.hedbtn{
    width: 200px;
    height: 60px;
    background-color: black;
    color: white;
    border-radius: 50px;
    font-size: 30px;
    margin-left: 2%;

}


</style>
