<template>
    <div class="preferenc">
               <div v-show="step===1" class="step">
           <!--  Начало работы
            <button @click='nextStep'>Далее</button>
            </div>
            <div v-show="step===2" class="step">
            Введите уравнение
            <div class="buttons">

            <button @click='nextStep'>Далее</button>
          
            </div>-->
            
                <div class="prefhed">
                   <h3> <p class="pprefhed">  Complete the quiz and 
                        find out the best destination for your 
                        vacation.
                    </p>
                    </h3>
                </div>
                <div class="preferbody">
                    <p class="preferbodyhed">Выберите, какие изображения Вам нравятся (одно или несколько)</p>

                    <div class="imagecont">

                        <table  @click="neuroclick">
                            <tr>
                                <td><img ref="2" src="./assets/Kviz/Style/stule1.jpg" class="img3" alt="img"></td>
                                <td><img src="./assets/Kviz/Style/stule2.jpg" class="img3" alt="img"></td>
                                <td><img src="./assets/Kviz/Style/stule3.jpg" class="img3" alt="img"></td>
                                <td><img src="./assets/Kviz/Style/stule4.jpg" class="img3" alt="img"></td>
                                <td><img src="./assets/Kviz/Style/stule5.jpg" class="img3" alt="img"></td>

                            </tr>
                            <tr>
                                <td><img src="./assets/Kviz/Style/stule6.jpg" class="img3" alt="img"></td>
                                <td><img src="./assets/Kviz/Style/stule7.jpg" class="img3" alt="img"></td>
                                <td><img src="./assets/Kviz/Style/stule8.jpeg" class="img3" alt="img"></td>
                                <td><img src="./assets/Kviz/Style/stule9.jpg" class="img3" alt="img"></td>
                                <td><img src="./assets/Kviz/Style/stule10.jpg" class="img3" alt="img"></td>

                            </tr>
                            
                        </table>
                        <!-- <div class="table" @click="neuroclick">
                            <div class="tr">
                                <div class="td">
                                    <img ref="2" src="./assets/Kviz/Style/stule1.jpg" class="img3" alt="img">
                                </div>
                                <div class="td">
                                    <img src="./assets/Kviz/Style/stule2.jpg" class="img3" alt="img">
                                </div>
                                <div class="td">
                                    <img src="./assets/Kviz/Style/stule3.jpg" class="img3" alt="img">
                                </div>
                                <div class="td">
                                    <img src="./assets/Kviz/Style/stule4.jpg" class="img3" alt="img">
                                </div>
                                <div class="td">
                                    <img src="./assets/Kviz/Style/stule5.jpg" class="img3" alt="img">
                                </div>
                            </div>

                            <div class="tr">
                                <div class="td">
                                     <img src="./assets/Kviz/Style/stule6.jpg" class="img3" alt="img">
                                </div>
                                <div class="td">

                                </div>
                                <div class="td"></div>
                                <div class="td"></div>
                                <div class="td"></div>
                            </div>
                        </div> -->
                        <div class="btnpanel">

                            <!-- <div class="buton1">
                                <button class="btn" @click="backStep">Prev</button>
                            </div> -->
                            <div class="buton2">
                                <button class="btn" @click="nextStep">Next</button>
                            </div>
                        </div>

                    </div>

                    </div>
            </div>
           
               <div v-show="step===2" class="step">
                    
                <div class="prefhed">
                   <h3> <p class="pprefhed">  Complete the quiz and 
                        find out the best destination for your 
                        vacation.
                    </p>
                    </h3>
                </div>
                <div class="preferbody">
                    <p class="preferbodyhed">Выберите, какие изображения Вам нравятся (одно или несколько)</p>

                    <div class="imagecont">

                        <table>
                            <tr>
                                <td><img src="./assets/Kviz/Flowers/flower1.webp" class="img3" alt="img"></td>
                                <td><img src="./assets/Kviz/Flowers/flower2.jpg" class="img3" alt="img"></td>
                                <td><img src="./assets/Kviz/Flowers/flower3.jpg" class="img3" alt="img"></td>
                                <td><img src="./assets/Kviz/Flowers/flower4.jpg" class="img3" alt="img"></td>
                                <td><img src="./assets/Kviz/Flowers/flower5.jpg" class="img3" alt="img"></td>

                            </tr>
                            <tr>
                                <td><img src="./assets/Kviz/Flowers/flower6.jpg" class="img3" alt="img"></td>
                                <td><img src="./assets/Kviz/Flowers/flower7.jpg" class="img3" alt="img"></td>
                                <td><img src="./assets/Kviz/Flowers/flower8.jpeg" class="img3" alt="img"></td>
                                <td><img src="./assets/Kviz/Flowers/flower9.jpeg" class="img3" alt="img"></td>
                                <td><img src="./assets/Kviz/Flowers/flower10.jpg" class="img3" alt="img"></td>

                            </tr>
                         
                            
                        </table>
                        <div class="btnpanel">

                            <div class="buton1">
                                <button class="btn" @click="backStep">Prev</button>
                            </div>
                            <div class="buton2">
                                <button class="btn" @click="nextStep">Next</button>
                            </div>
                        </div>

                    </div>

                   
            </div>
            </div>
            <div v-show="step===3" class="step">
                    
                    <div class="prefhed">
                       <h3> <p class="pprefhed">  Complete the quiz and 
                            find out the best destination for your 
                            vacation.
                        </p>
                        </h3>
                    </div>
                    <div class="preferbody">
                        <p class="preferbodyhed">Выберите, какие изображения Вам нравятся (одно или несколько)</p>
    
                        <div class="imagecont">
    
                            <table>
                                <tr>
                                    <td><img src="./assets/Kviz/Objects/141508735_w640_h640_malye-arhitekturnye-formy.webp" class="img3" alt="img"></td>
                                    <td><img src="./assets/Kviz/Objects/1627061360_5-p-dekorativnii-landshaftnii-mostik-5.jpg" class="img3" alt="img"></td>
                                    <td><img src="./assets/Kviz/Objects/arki-dlya-roz-31.webp" class="img3" alt="img"></td>
                                    <td><img src="./assets/Kviz/Objects/figurka-18.jpg" class="img3" alt="img"></td>
                                    <td><img src="./assets/Kviz/Objects/kitajskij_stil_v_landshaftnom_dizajne_2.jpg" class="img3" alt="img"></td>
    
                                </tr>
                                <tr>
                                    <td><img src="./assets/Kviz/Objects/141508735_w640_h640_malye-arhitekturnye-formy.webp" class="img3" alt="img"></td>
                                    <td><img src="./assets/Kviz/Objects/1627061360_5-p-dekorativnii-landshaftnii-mostik-5.jpg" class="img3" alt="img"></td>
                                    <td><img src="./assets/Kviz/Objects/arki-dlya-roz-31.webp" class="img3" alt="img"></td>
                                    <td><img src="./assets/Kviz/Objects/figurka-18.jpg" class="img3" alt="img"></td>
                                    <td><img src="./assets/Kviz/Objects/kitajskij_stil_v_landshaftnom_dizajne_2.jpg" class="img3" alt="img"></td>
    
                                </tr>
                             
                                
                            </table>
                            <div class="btnpanel">
    
                                <div class="buton1">
                                    <button class="btn" @click="backStep">Prev</button>
                                </div>
                                <div class="buton2">
                                    <button class="btn" @click="nextStep">Next</button>
                                </div>
                            </div>
    
                        </div>
    
                       
                </div>
                </div>
                <div v-show="step===4" class="step">
                    
                    <div class="prefhed">
                       <h3> <p class="pprefhed">  Complete the quiz and 
                            find out the best destination for your 
                            vacation.
                        </p>
                        </h3>
                    </div>
                    <div class="preferbody">
                        <p class="preferbodyhed">Выберите, какие изображения Вам нравятся (одно или несколько)</p>
    
                        <div class="imagecont">
    
                            <table>
                                <tr>
                                    <td><img src="./assets/Kviz/Colors/1635246851_59-mykaleidoscope-ru-p-rozovie-tsveti-tsveti-66.jpg" class="img3" alt="img"></td>
                                    <td><img src="./assets/Kviz/Colors/1649658951_72-vsegda-pomnim-com-p-nezhnie-golubie-tsveti-foto-78.jpg" class="img3" alt="img"></td>
                                    <td><img src="./assets/Kviz/Colors/1649817239_124-vsegda-pomnim-com-p-zheltie-tsveti-foto-135.jpg" class="img3" alt="img"></td>
                                    <td><img src="./assets/Kviz/Colors/1650534414_12-vsegda-pomnim-com-p-kak-viglyadyat-zelenie-tsveti-foto-12.jpg" class="img3" alt="img"></td>
                                    <td><img src="./assets/Kviz/Colors/59f714337d4b9af7217b8bbe144bbf0a.jpg" class="img3" alt="img"></td>
    
                                </tr>
                                <tr>
                                    <td><img src="./assets/Kviz/Colors/5bed3756a007416716a02a7f.jpg" class="img3" alt="img"></td>
                                    <!-- <td><img src="./assets/Kviz/Colors/a5520888fdc94b4008b99825e7ffce29.jpg" class="img3" alt="img"></td> -->
                                    <td><img src="./assets/Kviz/Colors/gortenziia_tsvety_sinij_145574_3840x2400.jpg" class="img3" alt="img"></td>
                                    <td><img src="./assets/Kviz/Colors/tsvety_fioletovyj_rastenie_107518_3840x2400.jpg" class="img3" alt="img"></td>
    
                                </tr>
                             
                                
                            </table>
                            <div class="btnpanel">
    
                                <div class="buton1">
                                    <button class="btn" @click="backStep">Prev</button>
                                </div>
                                <div class="buton2">
                                    <button class="btn" @click="nextStep">Next</button>
                                </div>
                            </div>
    
                        </div>
    
                       
                </div>
                </div>
                <div v-show="step===5" class="step">
                    
                    <div class="prefhed">
                       <h3> <p class="pprefhed">  Complete the quiz and 
                            find out the best destination for your 
                            vacation.
                        </p>
                        </h3>
                    </div>
                    <div class="preferbody5">
                        <p class="preferbodyhed">Выберите, какие изображения Вам нравятся (одно или несколько)</p>
    
                        <div class="inputbox">
    
                            <input class="input"><br>
                             
                            <input class="input">
                            
                            <input class="input"><br>
                            
                            <div class="btnpanel">
    
                                <div class="buton1">
                                    <button class="btn" @click="backStep">Prev</button>
                                </div>
                                <div class="buton2">
                                    <button class="btn" @click="nextStep">Next</button>
                                </div>
                            </div>
    
                        </div>
    
                       
                </div>
                </div>
            <!--  -->

            </div> 
</template>

<script>
import axios from 'axios'

export default{
    
    data(){
            return{
                step:1,
                id:null
            }
        },
        methods:{
            nextStep(){
                this.step++
            },
            backStep(){
                this.step--
            },
            
            OneStep(){
                this.step=1
            },
            ErrorStep(){
                this.step=7
            },
            tableid(){
                var  id=1
                console.log(id)
            },
            neuroclick( id){
        //  console.log('qwerty')
          console.log(id);
          //this.$router.push({path: this.$route.fullPath + '/'+'neuroNet' + '/' +id })
         // this.$router.push( {path: 'neuroLayer', query: { 'layer': id }})
         },
         kviz(){
            const options = {
                method: 'POST',
                url: 'https://api.gbif.org/v1/dataset',
                headers: {
                    'Content-Type': 'application/json',
                
                },
                data: {
                    img1: 'img',
                    img2: 'img',
                    img3: 'img',
                    img4: 'img',
                    email: 'email',
                    number: 'number',
                    name: 'name'
                }
                };

                axios.request(options).then(function (response) {
                console.log(response.data);
                }).catch(function (error) {
                console.error(error);
                });
         }
            
        }
}
</script>

<style scoped>
.preferenc{
 
 width: 60%;
 margin-left: 20%;
 height: 600px;
 background-color: beige;
}
.prefhed{
 width: 98%;
 height: 100px;
 background-color: #a0b19a;
}
.prefhed p{
 margin-top: 5%;
}
.pprefhed{  
  
 margin-left: 27%;
 
}
.preferbody{
 position: absolute; 

}
.imagecont{
 
margin-left: 1.3%;
}
@media(max-width:1200px){
 .imagecont{
     max-width: 100px;
     float: left;
 }
}

.imagecont td, tr{
 padding: 5px;
}
.btnpanel{
    position: absolute;
    width: 99%;
    padding: 5px;
    display: flex;
    margin-top: 5%;
   }

   .preferbodyhed{
   
    margin-left: 27%;
   }
</style>
