<template>
    <div class="slide11">
        <div class="info">
            <div class="blk1">
                <img src="./assets/S600xU.webp"><br>
                <p>
                Проект реализован при поддержке Фонда содействия 
                развитию малых форм предприятий в научно-технической сфере
                в рамках договора №144ГС1ИИС12-D7/79660 от 08.09.2022. </p>
            </div>
            <div class="blk2">
                <img src="./assets/Sk__Участник-block-mono-contur-ru_black-10.png">

            </div>

        </div>
        <div class="footer">
                <a href="/#/aboutUs#hed" id="hed">
                    О нас
                </a>
                <a href="/#/forPartners">
                    Партнёрам
                </a>
                <a href="#designers#hed">
                    Дизайнерам
                </a>
                <a href="https://boosty.to/ai.garden">
                    Стать Vip-пользователем
                </a>
                <a href="#enterTeamDuo#hed">
                    Войти в команду
                </a>
                <a href="https://boosty.to/ai.garden">
                    Поддержать
                </a>
                <a @click="push" id="hed">
                    Задать вопрос
                </a>
               
            </div>
            <div class="footfooter">
                <div class="urtext">
                    © Все права защищены. ООО "ДизАИнер".
desaigner@yandex.ru
                </div>
                <div class="dootpoloska">

                </div>
            </div>
        </div>
        
   
</template>

<script>
export default{


    methods:{
        push(){
            this.$router.push({path:  '/#/'+'getQuestion'  })
           
        }
    }

    
}
</script>

<style scoped>


.blk2{

}

.slide11{
        
        width: 100%;

    }

    .info{
    display: flex;
    align-items: center;
  
  
    
}

@media screen and  (max-width: 1280px){

    .info{
        flex-direction: column;
    }
    .blk1 p{
    width: 100%;
    }
}

.blk1{
    width: 55%;
    display: flex;
    flex-direction: column;
  
    align-items: center;
   
}

.blk1 p{
    width: 50%;
    font-family: "Montserrat-Bold"; 
    text-align:  center;
    font-size: 18px;
    white-space: wrap;
 
}
    .footer{
        margin-top: 6.6%;
        /* margin-left: 15%; */
        text-align: center;
        font-size: 18px;
    }
    .footer a{
        text-decoration: none;
        margin-left: 1.5%;
        padding: 15px;
        color: #000000;
        font-family: "Montserrat-Bold"; 
    }
    .footfooter{
        width: 99%;

    }
    .urtext{
        width: 20%;
        height: 150px;
        margin-left: 40%;
        margin-top: 2%;
        text-align: center;
        font-size: 18px;
        font-family: "Montserrat-Regular"; 
        color: #a2a2a2;
    }


</style>
