<template>
    <div class="body">
        <HederUno/>
       <tophead/>
      
        <br>
        <div class="content">
            <div class="hedcont">
            <div class="hedcontent">
                
                Мы - стартап, созданный людьми, мечтавшими объединить природу и технологии для пользы всех существ на земле. Наш продукт пока еще находится а разработке, и мы не можем Вам его показать. <br>
              
                <!-- Но мы активно над ним работаем и совершенствуем наши технологии. Поэтому мы будем невероятно признательны, если Вы уделите нам немного внимания и погуляете по нашему сайту. Мы также с радостью ответим на все вопросы, которые у Вас возникнут. Ну и конечно, мы будем особенно благодарны, если Вы поддержите нас любым удобным для Вас способом: пройдете опрос, подпишитесь на наши новости, или поддержите проект любой суммой, которой можете. Мы же, в свою очередь, не останемся в долгу, и отблагодарим Вас интересными подарками и эксклюзивными условиями пользования сервисом в дальнейшем!         -->
<div class="hedcontentG">
              <div class="polosocka">    </div>         
          
                <h1>
                    <p>
                    Счастье состоит из мелочей
                    </p>
                </h1>
            </div>
            </div>
           
            
           
            <div class="bodycontent">
                Но мы активно над ним работаем и совершенствуем наши технологии. Поэтому мы будем невероятно признательны, если Вы уделите нам немного внимания и погуляете по нашему сайту. Мы также с радостью ответим на все вопросы, которые у Вас возникнут. Ну и конечно, мы будем особенно благодарны, если Вы поддержите нас любым удобным для Вас способом: пройдете опрос, подпишитесь на наши новости, или поддержите проект любой суммой, которой можете. Мы же, в свою очередь, не останемся в долгу, и отблагодарим Вас интересными подарками и эксклюзивными условиями пользования сервисом в дальнейшем!        
            </div>
           </div>
        </div>
        <div class="support">
            <button class="btn sap">
                <label>
                    Поддержать проект  
                </label>
               

                <div class="strela">
                    <div class="belka"></div>
                    <div class="strelka"></div>
                </div>
            </button>
            <button class="btn sap">
                <label>
                     Подписаться на новости
                </label>
               
                <div class="strela">
                    <div class="belka"></div>
                    <div class="strelka"></div>
                </div>

            </button>
            <button class="btn sap">
                <label>
                     Стать партнёром  
                </label>
               
                <div class="strela">
                    <div class="belka"></div>
                    <div class="strelka"></div>
                </div>
               
            </button>
        </div>

         <div class="srp" ><br>
                <p class="pp">Ответьте на несколько вопросов и получите подарок!</p>
            </div>  
   
          <quest/>
        <boost/>
       
        <Footer/>
    </div>
</template>

<script>
import HederUno from './hederUno.vue'
import Footer from './footerUno.vue'
import boost from './components/components/boostUno.vue'
import tophead from './components/components/topHead.vue'
import quest from './components/components/questsUno.vue'
export default{
    components:{
        HederUno,

     Footer,
        boost,
        tophead,
        quest,
    },


}
</script>

<style scoped>
.strela{
    text-align: right;
    float: left;
    margin-left: 95%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: left;
    /* margin-bottom: 45%; */
    margin-top: -2.6%;
}
.belka{
    width: 20px;
    height: 2px;
    background-color: rgb(255, 255, 255);
    transform: rotate(55deg);
    margin-top:-5px;

}
.strelka{
    width: 20px;
    height: 2px;
    background-color: rgb(255, 255, 255);
    transform:  rotate(-55deg);
    margin-top: 65%;
}
.srp{
    width: 100%;
    height: 200px;
    background-color: rgba(202, 202, 202, 0.788);
    margin-top: 7%;
    display: flex;
    justify-content: center;
    align-content: center;
}
@media (max-width:1280px) {
    .srp {
    width: 100%;
    height: 100px;
    background-color: rgba(202, 202, 202, 0.788);
    margin-top: 7%;
}
.txt{
    width: 100%;
    height: 100px;
}
/* .srp p{
    font-family: 'Montserrat-Bold';
    margin-top: 1%;
    font-size:40px ;
    position: absolute;
    margin-left: 20%;
} */

}

.srp p{
    font-family: 'Montserrat-Bold';
    margin-top: 3%;
    font-size:30px ;

    /* position: absolute;*/
    /* margin-left: 25%;  */
}
.pp{
    margin-top: 105%;
}
.support{
    width: 60%;
    margin-top: 6%;
    margin-left: 30%;
}
.sap{
    width: 65%;
    height: 70px;
    border-radius: 50px;
    color: white;
    background-color: #a0b19a;
    /* text-align: left; */
    /* display: flex;
    flex-direction: row;
    justify-content:center; */
    font-size: 20px;
    /* margin: 1; */
    margin-top: 1%;
}
.sap label{
    display: flex;
    flex-direction: row;
    margin-left: 2%;
}
.polosocka{
    width: 400px;
    height: 2px;
    background-color: black;
    margin-bottom: 5%;
    /* margin-left: 20%; */
}
.content{
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    width: 100%;
    margin-top: 3%;
    margin-left: 0%;
    font-size: 30px;
}
.hedcont{
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* align-content: center; */
    /* align-items: center; */
   /* text-align:justify; */
   /* margin-left: 25%; */
    width: 50%;
}

.hedcontent{
    float: left;
    display: flex;
    /* text-align: left; */
    /* width: 46%; */
    z-index: 5;
    font-size: 25px;

}
.bodycontent{
    font-size: 25px;
}
@media (max-width:1280px)  {
    .bodycontent{
        margin-top: 1%;
    }
}
.hedcontentG{
    font-size: 50px;
    /* width: 34%; */
    /* margin-left: 36%; */
    float: right;
    margin: 20px;
    /* height: 150px; */
}
.body{
    font-family: "Montserrat-Regular";
    z-index: 199999999;
    /* margin-top:100px ; */
}
.hed{
    margin-top: 8%;
    margin-left: 20%;
    width: 80%;
    

}
.lefthed{
   
    float: left;
    text-align: left;
}
.hedtext1{
    font-size: 90px;
     font-family: "Montserrat-Bold";
     
}
.hedtext2{
    color: rgb(172, 172, 172);
    font-size: 70px;
}
.righthed{

}
.img1{
    width: 40%;
}

.hedbtn{
    width: 200px;
    height: 60px;
    background-color: black;
    color: white;
    border-radius: 50px;
    font-size: 30px;
    margin-left: 2%;

}


</style>
