<template>
    <div class="body">
        <div class="lcontent">
                   <div class="hederLM">
                        <img src="./ikons/fail2.png">
                         <label>
                            <strong>
                                Кабинет аналитики
                            </strong>
                         </label>   
                    </div><br>
                    <div class="src">
                        <input class="serch" type="search">
                        <button class="btn but1">
                           <img src="./ikons/search.png" alt="">
                        </button>
                    </div>
                    <div class="butpanel">
                        <button class="btn but2">
                            Загрузить изображение
                        </button><br>
                        <button class="btn but2">
                            Открыть изображение
                        </button>
                    </div>
                    <div class="hrefpanel">
                        <div class="href">
                            <a href="">
                                <img src="./ikons/fail2.png">
                                <label>
                                    Кабинет онтологии
                                </label>   
                            </a>
                        </div>
                        <div class="href">
                            <a href="#onePage">
                                <img src="./ikons/fail2.png">
                                <label>
                                   Конструктор нейросетей
                                </label>   
                            </a>
                        </div>
                        <!-- <div class="href">
                            <a href="">
                                <img src="./ikons/fail2.png">
                                <label>
                                    База изображений
                                </label>   
                            </a>
                        </div> -->
                    </div>
        </div>
    </div>
</template>

<script>

export default{

}

</script>

<style scoped>
.hrefpanel{
    margin-top: 2%;
    
}
.hrefpanel a{
    color: black;
}
.href{
    width: 100%;
    margin-top: 2%;
}
.butpanel{

}

.but2{  
    width: 241px;
    height: 54px;
    margin-top: 2%;
    background-color: #D9D9D9;
    border-radius: 10px;
}

.lcontent{
    margin-left: 0%;
    width: 100%;
}

.hederLM label{
    margin-left: 5px;
}


.serch{
    border-radius: 10px;
    height: 40px;
}
.leftmenu{
    width: 20%;
    height: 100%;
    margin-left: 2%;
}
.body{
    margin-left: 2%;
    font-family: "Montserrat-Regular";
    
}
</style>
