<template>
    <heder/>
    
    <div class="body">
        <div class="content1">
            <div class="contenthed">
                <p>
                    Выберите один из шаблонов или создайте свой проект с нуля
                </p>
            </div>
            <div class="content2">
               <contentall/> 
            </div>
        </div>
    </div>
</template>

<script>
import heder from "./components/hederTree.vue";
    import contentall from './components/Content/contentDuo.vue'

export default{
    components:{
        heder,
        contentall,
    
    }


} 
</script>

<style scoped>
.content2{
    margin-left: 0%;
}
.contenthed{
    margin-top: 3%;
    text-align: center;
}
.contenthed p{
    width: 28%;
    font-size: 30px;
    margin-left: 35%;


}
.body{
    font-family: "Montserrat-Regular";
    background-color: rgb(255, 255, 255);
    z-index: 5;
    text-align: center;
   
}
</style>
