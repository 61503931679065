import { createRouter, createWebHashHistory } from "vue-router";


import LoginUno from "./components/LoginUno.vue";

import onePage from "./pages/serve/onePage.vue";
import neuroIN from "./pages/serve/neuroIN.vue";
import picFolder from "./pages/serve/picFolder.vue";
import neuroLayer from "./pages/serve/neuroLayer.vue";
import twoPage from "./pages/serve/twoPage.vue";
import threePage from "./pages/serve/threePage.vue";
import picDescr from "./pages/serve/picDescr.vue";
import picsDescr from "./pages/serve/picsDescr.vue";
import constructorUno from "./const/constructorUno.vue";
//
import conferencePage from "./land/conferencePage.vue";
import designers from "./land/forDesigners.vue"
import contactsUno from "./land/contactsUno.vue"
import enterTeam from "./land/enterTeam.vue"
import aboutUs from "./land/aboutUs.vue"
import forLendlords from "./land/forLendlords.vue"
import forPartners from "./land/forPartners.vue"
import forPitomniks from "./land/forPitomniks.vue"
import getQuestion from "./land/getQuestion.vue"
import oprPredpocteniq from "./land/oprPredpocteniq.vue"
import resIntervue from "./land/resIntervue.vue"
import tarifsCosts from "./land/tarifsCosts.vue"
import zaglushkaUno from "./land/zaglushkaUno.vue"
import game from "./land/gameUno.vue"
import enterUsTeam from "./land/enterTeamDuo.vue"
//
import landingUno from "./component/pages/landingUno.vue";
import auth from "./component/pages/authUno.vue";
import authd from "./component/pages/authDuo.vue";
import tips from "./component/pages/tipPolzov.vue";
import alias from "./component/pages/aliasPage.vue";
import whatcreate from "./component/pages/willCreate.vue";
import project from "./component/pages/projectUno.vue";
import shablon from "./component/pages/shablonUno.vue";
import teg from "./component/pages/tegUno.vue";
import visual from "./component/pages/visualPreferens.vue";
import chshablon from "./component/pages/shablonChange.vue";
import constructor from "./component/pages/constructorUno.vue";
import shagdva from "./component/pages/shagDva.vue";
import shagtri from "./component/pages/shagTri.vue";
import shagfoor from "./component/pages/shagFoor.vue";
//import shagfoor "./component/pages/shagFoor.vue";
import authmodal from "./component/pages/components/authModal.vue";
import info from "./component/pages/osnovDan.vue";
import order from "./component/pages/myOrders.vue";
import plants from "./component/pages/changePlant.vue";
import docs from "./component/pages/documentsUno.vue";
import doc from "./component/pages/docsFrame.vue";
import plant from "./component/pages/plantPod.vue";
import versions from "./component/pages/versionsProject.vue";
import orderplant from "./component/pages/orderUno.vue";
import plantchange from "./component/pages/orderChange.vue";
import galery from "./component/pages/galeryPage.vue"
import testpr from "./component/pages/testProject.vue"
import testpr2 from "./component/pages/testDou.vue"
//src\construct\constructor.vue
//
import ontpage from "./ontologia/pageUno.vue"
const route = createRouter({
    
    history: createWebHashHistory(),
    routes: [

      
        {path: '/login', component: LoginUno },       
        {path: '/onePage', component: onePage },
        {path: '/neuroIN', component: neuroIN },
        {path: '/picFolder', component: picFolder},
        {path: '/neuroLayer', component: neuroLayer},
        {path: '/picdescr', component: picDescr},
        {path: '/picsdescr', component: picsDescr},
        {path: '/twoPage', component: twoPage },
        {path: '/threePage', component: threePage },
        {path: '/constructorUno', component: constructorUno },
        //-----------------------------------------------------
        {path: '/landing', component: conferencePage, alias:'/'  },  
        {path: '/designers', component: designers },
        {path: '/contactsUno', component: contactsUno},
        {path: '/enterTeam', component: enterTeam },
        {path: '/enterTeamDuo', component: enterUsTeam },
        {path: '/aboutUs', component: aboutUs },
        {path: '/forLendlords', component: forLendlords },
        {path: '/forPartners', component: forPartners},
        {path: '/forPitomniks', component: forPitomniks },
        {path: '/getQuestion', component: getQuestion},
        {path: '/oprPredpocteniq', component: oprPredpocteniq },
        {path: '/resIntervue', component: resIntervue},
        {path: '/tarifsCosts', component: tarifsCosts },
        {path: '/zaglushkaUno', component: zaglushkaUno },
        {path: '/game', component: game },

        // ----------------------------------------------------
        {path: '/landing2', component: landingUno },
        {path: '/auth', component: auth },
        {path: '/authd', component: authd },
        {path: '/tips', component: tips },
        {path: '/alias', component: alias },
        {path: '/create', component: whatcreate },
        {path: '/project', component: project },
        {path: '/shablon', component: shablon },
        {path: '/tegs', component: teg },
        {path: '/visual', component: visual }, 
        {path: '/info', component: info }, 
        {path: '/chshablon', component: chshablon },
        {path: '/constructor', component: constructor },
        {path: '/plants', component: plants },
        {path: '/shagdva', component: shagdva },
        {path: '/shagtri', component: shagtri },
        {path: '/shagfoor', component: shagfoor },
        {path: '/authmodal', component: authmodal },
        {path: '/orders', component: order },
        {path: '/docs', component: docs },
        {path: '/plant', component: plant },
        {path: '/versions', component: versions },
        {path: '/orderplant', component: orderplant },
        {path: '/doc', component: doc },
        {path: '/plantchange', component: plantchange },
        {path: '/galery', component: galery },
        {path: '/testpr', component: testpr },
        {path: '/testpr2', component: testpr2 },
        // -------------------------------------------------
        {path: '/ontpage', component: ontpage },

    ]



})



route.beforeEach((to,) =>{
    if (to.meta.requiresAuth && !localStorage.getItem('access_token')){
        return { name: 'onePage'}
    }
    if (to.meta.requiresAuth == false && localStorage.getItem('access_token')){
        return { name: 'auth'}
    }
    if (to.meta.requiresAuth && !localStorage.getItem('access_token')){
        return { name: 'ontpage'}
    }
    if (to.meta.requiresAuth == false && localStorage.getItem('access_token')){
        return { name: 'auth'}
    }
  })

export default route 

