 <template>


  <div class="login">

    <label>
          Email<br>
          <input
            type="text"
            class="form-control"
            placeholder="Ваша эл. почта"
            v-model="email"
          >
        </label><br>
        <label>
          Пароль<br>
          <input
            type="password"
            class="form-control"
            placeholder="Ваш пароль"
            v-model="password"
          >
            </label><br>
            <input @click.prevent="signIn" type="submit" class="btn btn-ptimary">
     

  </div>
 
 </template>
 
 <script>
 
  import axios from 'axios';
  //import router from '/src/router.js';
    export default{
      data(){
       return{
            email: null,
            password: null,
          
           
        }
    },
   
    mounted(){
    
    },

    methods: {
        signIn() {
          let self = this
        const options = {
          method: 'POST',
          url: 'http://185.221.152.242:5500/api/user/login',
          headers: {'Content-Type': 'application/json'},
          data: {username: this.email, password: this.password}
          
        };

        axios.request(options).then(function (response) {
          console.log(response.data);
          localStorage.setItem('access_token', response.data.access_token),
          //this.router.push(name {'onePage'})  
          self.$router.push( {path: 'onePage'}) 

        }).catch(function (error) {
          console.error(error);
          localStorage.setItem('access_token', JSON.stringify(options))
          localStorage.setItem('user', JSON.stringify(options))
            
           // console.log(router)     
        });
                console.log(options)
              },



    }

  }
 </script>
 
 <style lang="scss">
  .login{
    width: 300px;
    height: 500px;
    position: relative;
    margin-left: 40%;
    margin-top: 10%;
  }


 </style> 