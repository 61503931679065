
  import "bootstrap/dist/css/bootstrap.css"
    import { createApp } from 'vue'
    import App from './App.vue'
    import router from './router';
    
    global.jQuery = require('jquery');
    var $ = global.jQuery;
    window.$ = $;
    const app = createApp(App);
    app.use(createApp);
    app.use(router);

    app.mount('#app');
app.config.compilerOptions.isCustomElement = (tag) => {
      return tag.startsWith('ion-') // (return true)
    }
  import "bootstrap/dist/js/bootstrap.js"

    
 
