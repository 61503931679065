<template>

  <div class="link">
    <div class="a">
    <a href="#twoPage">К работе с изображениями</a>
    </div>
    <div class="a">
    <a href="#threePage">К работе с построенной нейросетью</a>

    </div>
</div>


<div class="div">
  

       
    <div class="content">
                        <!-- <button @click="neurinetCreate">Создать </button> -->
                        
                       
                        
                     
                      
                        
                   
           <div class="div">

                        <input
            width="200px"           
            type="text"
            class="form-control"
            placeholder="descr"
            v-model="descr"
          >
          <input
          width="200px"
            type="text"
            class="form-control"
            placeholder="m_loss"
            v-model="m_loss"
          >
          <input
          width="200px"
            type="text"
            class="form-control"
            placeholder="m_metrics"
            v-model="m_metrics"
          >
          <input
          width="200px"
            type="text"
            class="form-control"
            placeholder="m_optimizer"
            v-model="m_optimizer"
          >
        
          <input
          width="200px"
            type="text"
            class="form-control"
            placeholder="name"
            v-model="name"
          >
        
        </div>
        <button @click="neurinetCreate">Создать </button>
          <br>
        
          

           <table class="table">
              <thead>
                <tr>
                    <th></th>
                    <th scope="col">descr</th>
                    <th scope="col">id</th>
                    <th scope="col">m_loss</th>
                    <th scope="col">m_metrics</th>
                    <th scope="col">m_optimizer</th>
                    <th scope="col">model_created</th>
                    <th scope="col">model_file</th>
                    <th scope="col">name</th>
                    <th scope="col">model_file</th>
                </tr>
              </thead>
              <tbody>
                <tr
                 v-for:="options2 in neuronet" 
                 
                >
                  <input
                  class="form-check-input" 
                  type="radio" 
                  name="flexRadioDefault" 
                  id="flexRadioDefault1"
                  
                  />
                    <th scope="row" @click="neuroclick(options2.id)" >{{options2.descr}}</th>
                    <td>{{options2.id}}</td>
                    <td>{{options2.m_loss}}</td>
                    <td>{{options2.m_metrics}}</td>
                    <td>{{options2.m_optimizer}}</td>
                    <td>{{options2.model_created}}</td>
                    <td>{{options2.model_file}}</td>
                    <td>{{options2.name}}</td>
                    <td>{{options2.pic_folder_id}}</td>
                </tr>
              </tbody>
           </table>
           
          
          
    </div>

</div>

</template>

<script>



//import api from "@/api";
//import axios from "axios";
import api from "../../JS/api.js"
//import consoleHeadVue from "./consoleHead.vue"



 export default{
  components:{
    
  },
  
    name: "neuronetIN",

  

    data(){
       return{
           
            neuronet: null,
            
           
        }
    },
    
    mounted(){
      console.log(this)
        this.neuronetIN(this)  //использовать для прорисовки по умолчанию
    },

    methods: {
      
        
         neuronetIN(this2){

                console.log('Hello world');

          const options2 = {
            method: 'GET',
            url: 'http://185.221.152.242:5500/api/neuronet',
            
          };
          
          api.request(options2).then(function (response) {
           
         
            localStorage.setItem('id', response.data.id)
           console.log(this2)
            console.log(response.data);
              this2.neuronet = response.data;
             
              
          }) 
                  
          .catch(function (error) {
            console.error(error);
            console.log(options2)
          });
          
           },

           neurinetCreate(){
             
            console.log('Hello world');

            const options3 = {
            method: 'POST',
            url: 'http://185.221.152.242:5500/api/neuronet' ,
            headers: {'Content-Type': 'application/json'},
          data: {

            descr: this.descr,

            m_loss: this.m_loss,

            m_metrics: this.m_metrics,

            m_optimizer: this.m_optimizer,

            model_created: this.model_created,

            model_file: this.model_file,

            name: this.name
          
            }
            
          };
           api.request(options3).then(function (response) {
          
            
            console.log(response);
             console.log(options3);
             window.location.reload();
                            
           }) 
             
          },     
          
          
          
        
          neuroclick( id){
          console.log('qwerty')
          console.log(id);
          //this.$router.push({path: this.$route.fullPath + '/'+'neuroNet' + '/' +id })
          this.$router.push( {path: 'neuroIN', query: { 'neuronet': id }})
      
      },
   

}
    
}
 

</script>


<style scoped>

.a{
  
      margin-top: 1%;
      margin-left: 3%;
      
    
}
.link{
  padding: 5px;
  display: flex;
}

    .div{
        padding: 5px;
        display: flex;
    }

    .content{
        margin-top: 5%;
        margin-left: 5%;

    }
    .table{
    
        margin-top: 5%;
    }


</style>