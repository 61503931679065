<template>
  <div class="body1" >

      <div class="header">


        <button class="btn">Сохранить</button>
        <button class="btn">Загрузить</button>
        

      </div>
    <!-- qwerty -->
    <div class="menu">
       <!-- <button class="btn" @click="Cub">прямоугольник</button>
        <button class="btn" @click="treugol">треугольник</button>
        <button class="btn" @click="qwerty">qwerty</button> -->
    </div>
    <div class="canvas">
        <canvas  id="canvas" ref="can" width="1250" height="700" ></canvas>
       <div class="canvasfooter">

        <div class="canfootcontent">

        <div class="dropdown">
          <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
            Кнопка  1
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li><button class="btn" @click="qwerty">qwerty</button></li>
            <li>Другое действие</li>
            <li>Что-то еще здесь</li>
          </ul>
        </div>
         <div class="dropdown">
          <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
            Кнопка  2
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>Действие</li>
            <li>Другое действие</li>
            <li>Что-то еще здесь</li>
          </ul>
        </div>
        <div class="dropdown">
          <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
            Кнопка  3
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>Действие</li>
            <li>Другое действие</li>
            <li>Что-то еще здесь</li>
          </ul>
        </div>
        <div class="dropdown">
          <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
            Кнопка 4
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>Действие</li>
            <li>Другое действие</li>
            <li>Что-то еще здесь</li>
          </ul>
        </div>
    </div> 
    </div>
   </div>
    <!-- <div class="rightmenu0"> 
          <div class="dropdown">
          <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
            Кнопка  1
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li><button class="btn" @click="qwerty">qwerty</button></li>
            <li>Другое действие</li>
            <li>Что-то еще здесь</li>
          </ul>
        </div>
         <div class="dropdown">
          <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
            Кнопка  2
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>Действие</li>
            <li>Другое действие</li>
            <li>Что-то еще здесь</li>
          </ul>
        </div>
        <div class="dropdown">
          <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
            Кнопка  3
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>Действие</li>
            <li>Другое действие</li>
            <li>Что-то еще здесь</li>
          </ul>
        </div>
        <div class="dropdown">
          <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
            Кнопка 4
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>Действие</li>
            <li>Другое действие</li>
            <li>Что-то еще здесь</li>
          </ul>
        </div>
      
    </div>  -->
        <div class="footer">

          <div class="fotermenu">

            <p>Кнопка</p>
            <p>Кнопка</p>
            <p>Кнопка</p>
            <p>Кнопка</p>
            <p>Кнопка</p>

          </div>
        </div>
  </div>
</template>

<script>
//import { moduleExpression } from '@babel/types';
import { fabric } from 'fabric';
export default{

    mounted() {

      

      

    //const ref = this.$refs.can;
   // const canvas = new fabric.Canvas(ref);
    // const rect = new fabric.Rect({
    //   fill: 'red',
    //   width: 20,
    //   height: 20
    // });
    // canvas.add(rect);
        
  },
  
  methods: {
    Cub(){
      var ref = this.$refs.can;
      var canvas = new fabric.Canvas(ref);
      var rect = new fabric.Rect({
        fill: 'red',
        width: 80,
        height: 80
    });
    canvas.add(rect);
    },
    treugol(){
      // var ref = this.$refs.can;
      // var canvas = new fabric.Canvas(ref);
      // var triangle = new fabric.triangle({
      //   fill: 'green',
      //   width: 30,
      //   height: 20
      var canvas = new fabric.Canvas("canvas"); 
  
        // Initiate a triangle instance 
        var triangle = new fabric.Triangle({
            width: 100,
            height: 50
        });
   
    canvas.add(triangle);
    // canvas.centerObject(triangle);
    },
    
    qwerty(){
      
     
      var ref = this.$refs.can;
      var canvas = new fabric.Canvas(ref);
      var rect = new fabric.Rect({
        fill: 'red',
        width: 80,
        height: 80
    });
  
    var rect2 = new fabric.Rect({
        fill: 'brown',
        width: 80,
        height: 80
    });
    var rect3 = new fabric.Rect({
        fill: 'blue',
        width: 80,
        height: 80
    });
    var rect4 = new fabric.Rect({
        fill: 'white',
        width: 80,
        height: 80
    });
    var rect5 = new fabric.Rect({
        fill: 'white',
        width: 80,
        height: 80
    });
    // var canvas = new fabric.Canvas("canvas"); 
  
        // Initiate a triangle instance 
        var triangle = new fabric.Triangle({
            width: 100,
            height: 50
        });
   
    canvas.add(triangle, rect, rect2, rect3, rect4, rect5);

    }
    // draw(){
    //   const canvas = initCanvas('canvas')
    //   canvas.on('mouse:move', (event) => {
    //     if (mousePressed && currentMode === moduleExpression.pan) {
    //       canvas.ssetCursor('grab')
    //       canvas.renderAll()
    //       const mEvent = event.e
    //       const delta =new fabric.Point(mEvent.movemenX, movementY)
    //       canvas.relativePan(delta)
    //     }
    //   })
    // }
  }
}
</script>

<style scoped>

  .header{
    position: absolute;
    width: 98%;
    height: 40px;
    left: 1%;
    top: 1%;

  }
  .canfootcontent{
    margin-left: 30%;
    display: flex;
  }
  .canfootcontent ul{
    margin-top: 1%;
    margin-left: 1%;
  }
  .fotermenu{
    margin-left: 40%;
    display: flex;
  }
  .footer{
    position: absolute;
    bottom: 1%;
    width: 98%;
    background-color: rgb(250, 250, 250);
    display: flex;
    margin-left: 1%;
  }

  .footer p {
    margin-left: 2%;
  }
  .body1{
    background-color: rgb(151, 151, 151);    
  }
  .rightmenu0{
    position: absolute;
    right: 1%;
    top: 30%;
  }

  .rightmenu0 button{
    margin-top: 2%;
  }

  .canvasfooter ul{
    margin-left: 4%;
  }
  .canvasfooter{
    position: absolute;
    background-color: rgba(231, 231, 231, 0.63);
    width: 100%;
    height: 100px;
    display: flex;
   
  }
  .canvas{
    background-color: rgba(231, 231, 231, 0.63);
    position: absolute;
    top: 10%;
    left: 15%;

  }
  .menu{
    position: absolute;
    left: 1%;
    width: 50px;
  }
</style>