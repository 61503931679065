<template>
    <div class="body">
        12 {{ qwerty }}
        <div class="kard"
                v-for:="options in project1" 
                @click="projectclick(options.id)"
                >
                {{options[4].id}}
                Hello World
                    <!-- <a href="#shablon" > -->
                     <div class="kardimg"></div>
        1
                     <div class="kardb"
                    >
                        <img class="image1" src="@/component/pages/ikons/fail.png" >
                       
                        {{(options)? project1 : ''}}
                      {{console.log('134')+ options}}
                  {{window.pr}}
                      <div id="test">
                        
                      </div >
                      {{ qwerty }}
                        <br>
                     </div>
                     <!-- </a> -->

                </div> 
    </div>
</template>

<script>
import apiint from  "../../JS/apiint.js"  
export default{
    data(){
       return{
           
            project1: null,
            proect: null,
           
        }
    },
    // created(){      
                  
    //               this.projects()
    //               this.getprojects()
          
    //   },

          mounted(){
    
            this.projects()          
  
        //использовать для прорисовки по умолчанию
  },
      
          methods:{
      
            projects(){
            
            var options8 = {
                method: 'GET',
                url: 'http://185.221.153.253:8000/api/aigarden/project',
                               
                };
              
                apiint.request(options8).then(function (response) {
                  console.log(response.data, "1");
              //  localStorage.setItem('id', response.data.id)      
                        
                var project1 = response.data

                console.log(project1,"2");
                console.log(project1[0].id,"3");
                  window.pr= response.data

                // let qwerty = response.data.id
                console.log("========","4");
                console.log( options8,"5");
                let prod = project1//
                    console.log(prod,"6")
                 //   document.getElementById('test').textContent = prod
                // console.log(qwerty);
              //  document.getElementById('test1').textContent = project1
              //return {options8}
              // window.refresh()
            })
            .catch(function (error) {
                console.error("name error", error);
             
               })
            
        },
            getprojects(){
               

               const getpr = {
               method: 'GET',
               url: 'http://185.221.153.253:8000/api/aigarden/project',
               
               };
   
               apiint.request(getpr).then(function (response) {
                   console.log("hello worlf");   
               console.log(response.data);
                 let proect = response.data
                 console.log(proect)
                 let  element = document.querySelector('.test1');
               let getprodj= response.data
               element.innerHTML= getprodj
               }).catch(function (error) {
               console.error(error);
               });
               },
}
}
</script>

<style scoped>

</style>
