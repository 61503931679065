<template>
    <div class="body">
          
            <div class="content">

                <div class="contentbox">

                    <div class="contentleft">

                        <div class="left">
                            <p>
                                Зарегистрируйтесь чтобы начать работу 
                            </p>
                            <label class="label0" for="">
                                Ваш телефон или E-mail
                            </label><br>
                            <input type="text"><br>
                            <a href="#authd">
                            <button class="btn button1">
                                Отправить код
                            </button>
                                </a>
                            <div class="pbox">
                                    <div class="poloska1"></div>
                                <label class="label1">
                                    или
                                </label>                                
                                    <div class="poloska2"></div>
                            </div>
                            <label class="label2">
                                Упрощенная регистрация через:
                            </label>
                            <div class="rotbox">
                                <button class="bnt buttonR">
                                    <img class="img1" src="./ikons/Vk.png">
                                </button>
                                 <button class="bnt buttonR">
                                    <img class="img1" src="./ikons/icons8-google-30.png">
                                </button>
                                 <button class="bnt buttonR">
                                    <img class="img1" src="./ikons/icons8-yandex-67.png">
                                </button>
                                
                            </div>
                            <label class="label3">
                                Нажимая кнопку “Зарегистрироваться” Вы соглашаететсь с политикой конфиденциальности
                            </label>
                        </div>
                    </div>

                    <div class="contentright">

                        <div class="right">

                            <p>
                                Или ознакомьтесь с функционалом в сопровождении виртуального помощника
                            </p>
                            <img class="img2" src="./ikons/robot1.png">
                            <button class="btn button4">
                                Поехали!
                            </button>
                        </div>

                    </div>

                </div>

            </div>
    </div>
</template>

<script>

// import heder from "./components/hederUno.vue";
export default{
    components:{
      //  heder
        }
}

</script>

<style scoped>
.button4{
    width: 80%;
    height: 50px;
    background-color: #D9D9D9;
    margin-top: 5%;
}
.img2{
    width: 40%;
    margin-left: 8%;
}
.right{
    width: 85%;
    margin-left: 7.5%;
    text-align: center;
    /* margin-top: 20%; */
}

.contentright p{
    margin-top: 20%;
    font-size: 30px;
    color: white;
}
.label3{
    margin-top: 4%;
    width: 80%;
    font-size: 12px;
}
.img1{
    width: 140%;
    margin-left: -18%;
}

.buttonR{
    border-radius: 40%;
    background-color: white;
    width: 40px;
    margin-left: 2%;
    margin-top: 4%;
}
.rotand{
    border: 1px solid #000000;
    width: 20%;
    margin-left: 2%;
    margin-top: 2%;
    border-radius: 50px;
    rotate: 50%;
}
.label2{
    margin-top: 4%;
    margin-left: 5%;
    font-size: 20px;
}
.pbox{
    display: flex;
    width: 80%;
    margin-left: 10%;
    margin-top: 4%;
}
.label1{
    margin-left: 5%;
    font-size: 20px;
}

.poloska1{
    border: 1px solid #000000;
    width: 40%;
    margin-top: 4%;
    height: 1px;
    
}
.poloska2{
    border: 1px solid #000000;
    width: 40%;
    margin-top: 4%;
    height: 1px;
    margin-left: 5%;
}
.left{
    width: 85%;
    margin-left: 10%;
}
.contentleft{
    width: 50%;
    height: 100%;
    background-color: white;
    float: left;
    
}
.contentleft p{
    margin-top: 20%;
    font-size: 30px;
}
.label0{
    margin-top: 4%;
    font-size: 12px;
}
.contentleft input{
    margin-top: 2%;
    width: 80%;
    height: 50px;
}
.button1{
    margin-top: 4%;
    width: 80%;
    height: 50px;
    background-color: #D9D9D9;
}
.contentright{
    width: 50%;
    height: 100%;
    float: left;
    background-color: #404040;
} 
.contentbox{
    width: 70%;
    margin-left: 15%;
    margin-top: 5%;
    height: 700px;
    border: 1px;
    border-color: black;
    position: fixed;
    border: 1px solid #000000;
    text-align: center;
}
.body{
    font-family: "Montserrat-Regular";
    background-color: rgb(211, 211, 211);
    height: 1000px;
}

</style>
