<template>
    <heder/>
      <hederd/>
    <div class="body">
      
        <!-- <div class="heder"> 
            <div class="up">
                <a href="#alias">
                <p>&lt;------ назад к списку проектов</p>
                </a>
            </div>
            <div class="bottom">
                <div class="blockp1">
                    <div class="p1">
                        <strong>
                            Без названия
                        </strong>
                    </div>
                </div> 
                <div class="blockp2">          
                    <div class="p2">
                        Результат генерации
                    </div>
                    <div class="p2">
                        Основные данные
                    </div>
                    <div class="p2">
                        Визуальные предпочтения
                    </div>
                    <div class="p2">
                        Облако тегов
                    </div>
                </div> 
               

            </div>

        </div>-->
        <div class="bblock">

        <div class="left">
            <div class="lcont">
            <div class="imgblock">

            </div>
            <div class="imglist">
                <div class="img">
                    qaeg
                </div>

                <div class="img">
                    qaeg
                </div>

                <div class="img">
                    qaeg
                </div>
                <div class="img">
                    qaeg
                </div>
            </div>
            </div><br>
            <!-- <div class="footer">
                <div class="lfoot">
                    <p>
                        Попробуйте необыковенную функцию!
                    </p>
                </div>
                <div class="rfoot">
                    <p>
                         3D - осмотр
                    </p>
                   
                </div>
            </div> -->
        </div>
        <div class="right">
                <p>
                    <strong>
                        Растения
                    </strong>
                </p>
                <div class="chex">
                     <label class="checkbox-btn">
            <input type="checkbox" checked >
            <span>Скульптуры</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" checked >
            <span>Вазоны</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" checked>
            <span>Фонтан</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" checked>
            <span>Пальмы</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" checked>
            <span>Зеркала</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" checked>
            <span>Суккуленты</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" checked>
            <span>Папоротник</span>
            </label>
            <br><br>
            
            <!--  -->
            <!-- <div class="bot-box">
               <input type="searh" class="search" placeholder="Найти растение">
               <button class="btn">
                    <img src="./ikons/srcbtn.png">
                
               </button>
           <a class="plants">
            <strong>
                <u>
                       Посмотреть все растения
                </u>
              
            </strong>
           
           </a>  
            </div> -->
           
            
                </div><br>
               

            <p>
                    <strong>
                        Оттенки
                    </strong>
                </p>
                <div class="chex">
                    <label class="checkbox-btn">
            <input type="checkbox" checked >
            <span>Суккуленты</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" checked>
            <span>Папоротник</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" checked>
            <span>Много красного</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" checked>
            <span>Мелкие цветы</span>
            </label>
            
                </div>
                <br><br>
                <p>
                    <strong>
                        Стили
                    </strong>
                </p>
                <div class="chex">
                    <label class="checkbox-btn">
            <input type="checkbox" checked >
            <span>Японский</span>
            </label>
            <label class="checkbox-btn">
            <input type="checkbox" checked>
            <span>Английский</span>
            </label>
            
            
                </div>
                <button class="redact">
                    Редактировать
                </button>
            </div>
        <!-- <div class="right">

            <div class="rhead">

                <button class="btn dwnld">
                    Скачать в JPEG
                </button>

                <button class="btn rpst">
                    Поделиться
                </button>

            </div><br>

            <div class="rbody">

                <div class="bhead">
                    <p>
                        Доступно на аккаунте PRO
                    </p>
                </div>
                <div class="bcont">
                    <label>
                        Приобретите проффесиональный аккаунт и получите доступ к подробной документации проекта. 
                    </label>
                    <button class="btn pro">
                        PRO Открыть документацию
                    </button>
                </div>
            </div>

            <div class="bfooter">
                <div class="fk1">
                    <button class="btn save">
                        Опубликовать в галерею
                    </button>
                    <div class="quesr">
                        <p>?</p>
                    </div>
                </div>
                <br>
                 <div class="fk1">
                    <button class="btn save">
                        Сохранить как авторский шаблон
                    </button>
                    <div class="quesr">
                      <p>?</p>
                    </div>
                    </div>
            </div>

        </div> -->
        </div>
    </div>
</template>

<script>
 import api from  "../../JS/apiint.js"  
 import heder from "./components/hederTree.vue";
import hederd from "./components/hederDuo.vue";

export default{
    components:{
        hederd,
        heder,
    },
    name: "proJect",
        data(){
       return{
           
            project1: null,
           
        }
    },

        mounted(){
           
            console.log(this,'1') 
  console.log(this)
    this.projects(this)  //использовать для прорисовки по умолчанию
},
        methods:{
        // api.request(options2).then(function (response)

            projects(this2){
                let result = this.$route.query.project
                console.log('Hello world','3');
                const options = {
                    method: 'GET',
                    url: 'http://185.221.153.253:5700/api/project/' + result,
                                   
                    };
                  
                    api.request(options).then(function (response) {
                    localStorage.setItem('id', response.data.id)      
                            
                    this2.project1 = response.data
                    console.log(this2.project1);
                    let qwerty = response.data
                    console.log("========");
                    console.log(qwerty);
                    }).catch(function (error) {
                    console.error(error);
                 
                    })
                
            },

            pr_rename(){
                let result = this.$route.query.project
                console.log('Hello world','3');
                const options2 = {
                method: 'POST',
                url: 'http://185.221.153.253:5700/api/project/' + result,
                headers: {
                    'Content-Type': 'application/json',
                },
                data: {
                    pr_descr: 'новый проект', 
                    }
                };

                api.request(options2).then(function (response) {
                console.log(response.data);
                }).catch(function (error) {
                console.error(error);
                });
            }
            
        }

}
</script>

<style scoped>
.redact{
    display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 16px 20px;
gap: 10px;
margin-top: 10%;
border: none;
/* position: absolute; */
width: 315px;
height: 55px;
margin-left: 25%;
/* top: 919px;  */

background: #B6B6B6;
border-radius: 10px;
}
.right{
    float: left;
    width: 50%;
    font-size: 18px;
}
.right p{
    font-size: 24px;
}
.imgtr{
   
}
.checkbox-btn {
	display: inline-block;
	margin-top: 1%;
    margin-left: 2%;
	user-select: none;
	position: relative;
    /* border-radius: 25px; */

}
.checkbox-btn input[type=checkbox] {
	z-index: -1;
	opacity: 0;
	display: block;
	width: 0;
	height: 0;
    
}
.box-btn input[type=checkbox] {
	z-index: -1;
	opacity: 0;
	display: block;
	width: 0;
	height: 0;
    
}
.box-btn1 input[type=checkbox] {
	z-index: -1;
	opacity: 0;
	display: block;
	width: 0;
	height: 0;
    margin-left: 2%;
    
}

.checkbox-btn span {
	display: inline-block;
	cursor: pointer;
	padding: 0px 10px;
	line-height: 30px;
	border: 1px solid #999;
	border-radius: 24px;
	transition: background 0.2s ease;
}
.box-btn{

/* position: relative; */
color: white;
width: 126px;
height: 36px;
padding: 5px;
margin-top: 0px;
margin-left: 0px;
/* background: #707070; */
border-radius: 10px;
z-index: 999;
position: absolute;
}
.box-btn1{
 /* position: relative; */
 color: white;
width: 126px;
height: 36px;
padding: 5px;
margin-top: 0px;
margin-left: 458px;
/* background: #707070; */
border-radius: 10px;
z-index: 999;
position: absolute;
}
.box-btn span {
display: inline-block;
width: 400px;
cursor: pointer;
background: #707070;
padding: 0px 10px;
line-height: 30px;
border: 1px solid #999;
border-radius: 24px;
transition: background 0.2s ease;
}
.box-btn1 span {
display: inline-block;
width: 400px;
cursor: pointer;
padding: 0px 10px;
background: #707070;
line-height: 30px;
border: 1px solid #999;
border-radius: 24px;
transition: background 0.2s ease;
}
.checkbox-btn input[type=checkbox]:checked + span {
	background: #8d8d8d;
    color: white;
}

.rhead{
    width: 100%;
    margin-top: 0%;
}
.dwnld{
    width: 40%;
    background-color: #D9D9D9;
    height: 56px;
    border-radius: 5px;
    float: left;
    margin-left: 6%;
}
.rpst{
    width: 40%;
    background-color: #D9D9D9;
    height: 56px;
    border-radius: 5px;
    float: left;
    margin-left: 8%;
}

.rbody{
    margin-top: 10%;
    height: 385px;
    background: #D9D9D9;
    border-radius: 10px;
    text-align: center;
}

.bhead{
    width: 100%;
    margin-left: 2%;
    
}

.bhead p{
    
    width: 100%;
    font-size: 30px;
}

.bcont{
    margin-top: 15%;
   
}

.bcont label{
    text-align: left;
    font-size: 16px;
    width: 80%;
     margin-left: 2%;

}
.pro{
    width: 90%;
    margin-top: 4%;
    margin-left: 2%;
    height: 54px;
    justify-content: center;
    background-color: #949494;
}

.bfooter{
    margin-top: 2%;
    text-align: center;
   
}
.fk1{
    display: flex;
    text-align: center;
    
}
.save{
    width: 85%;
    height: 54px;
    background-color: #D9D9D9;
    border-radius: 10px;
}
.quesr{
    margin-left: 5%;
  text-align: center;
    margin-top: 1%;
    background-color: #949494;
    width: 35px;
    height: 35px;
    border-radius: 30px;
}
.quesr p{
    margin-top: 20%;
}
.imgblock{
    margin-top: 1%;
    margin-left: 4%;
    width: 884.2px;
    height: 578.1px;
    background-color: rgb(163, 163, 163);
    /* float: left; */
}
.lcont{
    margin-top: 2%;
    display: flex;
}
.imglist{
    width: 175px;
    /* float: left; */
    margin-top: 1%;
    margin-left: 2%;
}

.img{
    width: 174.86px;
    height: 138.51px;
    background-color: rgb(163, 163, 163);
    margin-top: 2%;
}
.footer{
    margin-top: 0%;
    width: 100%;
    height: 85px;
    margin-left: 4%;
    display: flex;
    
}
.footer p{
    text-align: center;
    margin-top: 5%;
}
.lfoot{
    width: 50%;
    height: 85px;
    background-color: #D9D9D9;
    border-radius: 10px 0 0 10px ;
}
.rfoot{
    width: 31%;
    background-color: #949494;
    border-radius: 0 10px 10px 0;
}

.rfoot :hover{
    width: 100%;
    text-align: center;
    height: 84px;
    background-color: #949494;
    border-radius: 0 10px 10px 0;
}
.bblock{
    margin-top: 3%;
}
.left{
    width: 62%;
    float: left;
    /* display: flex; */
}
.right{
    width: 36%;
    float: left;
    /* margin-left: 2%; */
    margin-top: 2%;
}
.heder{
    margin-left: 2%;
}
.p1{
    float: left;
    margin-left: 0%;
    font-size: 24px;
    text-align: center;
    width: 177px;
    height: 29px;
    font-weight: 400;
    line-height: 29px;
}
.blockp2{
    margin-left: 22%;
}
.p2{
    float: left;
    margin-left: 1%;
    font-size: 16px;
    margin-top: 0.5%;
    text-align: center;
    width: 240px;
    height: 19px;
}
.up a{
    text-decoration: none;
    color: black;
}
.body{
    margin-top: 0px;
    font-family: "Montserrat-Regular";
}

</style>
