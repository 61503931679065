<template>
    <heder/>

    <div class="body">
        <div class="hed">
           <p>
            <strong>
                Выберите один из шаблонов или создайте свой проект с нуля

            </strong>
            </p>
            <div class="podhed">
                <div class="btnpanel">
                    <button class="btn">
                        Классические шаблоны
                    </button>

                    <button  class="btn">
                        Арт-шаблоны
                    </button>

                    <button  class="btn">
                        Авторские шаблоны
                    </button>
                </div>
                <div class="tegpanel">
                    <button class="btn tegbtn">
                        Модульные 
                    </button>
                    <button class="btn tegbtn">
                        Арабески
                    </button>
                    <button class="btn tegbtn">
                        Арабески
                    </button>
                    <button class="btn tegbtn">
                        Модульные
                    </button>
                    <button class="btn tegbtn">
                        Арабески
                    </button>
                    <button class="btn tegbtn">
                        Модульные
                    </button>
                </div>
            </div>

        </div>
        <div class="content">
            
        </div>
    </div>
</template>

<script>
// import hederd from "./components/hederDuo.vue";
import heder from "./components/hederTree.vue";

export default{
    components:{
        heder,
        // hederd
    }

}
</script>

<style scoped>

.tegbtn{
    background-color: #D9D9D9;
    width: 117px;
    height: 39px;
    border-radius: 50px;
}
.podhed button{
    margin-left: 1%;
}
.hed p{
    font-size: 24px;
    width:25%;
}

.body{
    margin-top: 100px;
    font-family: "Montserrat-Regular";
    text-align: center;
}
</style>
