<template>
    <heder/> 
    <!-- <hederd/> -->
    <div class="heder">
        <br>
            <div class="up">
                <a href="#constructor">
                <p><img src="./ikons/strelkanazad1.png">  Назад</p>
                </a>
                <div class="versions">
                    <p>
                    Версия 1
                </p>
                <p>
                    Версия 2
                </p>
               
                <p>
                    Версия 2
                </p> 
                </div>
                <div class="close">
                    <!-- <save/> -->
                    <button class="btn closebtn">
                        Закрыть проект
                    </button>
</div>
               
            </div>
            <div class="bottom">
                <!-- <div class="blockp1">
                    <div class="p1">
                        <strong>
                            Без названия
                        </strong>
                    </div>
                </div>  -->
                <div class="blockp1">
                    <div class="p1">
                        <strong>
                            Без названия
                        </strong>
                        <button
                        @click="pr_rename"
                         class="btn rename">
                            <img class="imgren" src="./ikons/rename.png">
                        </button>
                    </div>
                    
                </div> 
                <div class="heder2">

<a href="#shablon">
         <div class="p2">
        Результат генерации
    </div>  
    </a>         
 <a href="#info">
    <div class="p2">
        Основные данные
    </div>
</a>
    <a href="#visual">
       <div class="p2">
        Визуальные предпочтения
    </div>  
    </a>
   
    <a href="#docs">
    <div class="p2">
        Документация
    </div>
     
    </a>
     <a href="#orderplant">
    <div class="p2">
        Подборки растений
    </div>
    </a>


</div>

            </div>
            <br>
        </div> 
        <div class="body">
       
            <div class="content"><br><br>
                <div class="blocks">


<div class="plant">
    <table class="table">
        <tr>
            <td>
                <strong>
                     Растение 1 
                </strong>
              
            </td>
            <td>

            </td>
            <td>

            </td>
            
            <td>

</td>
            <td>
                
                    Цена  
             
              
            </td>
            <td>
                Стоимость
            </td>
        </tr>
        <tr>
            <td>
                <input type="checkbox">
            </td>
            <td>
                <img class="imgtd" src="./ikons/ucastok.png">
            </td>
            <td>
               <p>
                    <strong>
                        Вариант 1 (название)
                    </strong>
              </p>
                <label>
                    Краткое описание растения и кнопка
                </label>
            </td>
            
            <td>
                <div class='count_box'>
                    <div class="minus">-</div>
                <input class='inp_price' type="text" value="1"/>
                <div class="plus">+</div>
                </div> 
            </td>
            <td>
                
                        
                        3060                                 

                       
                  
            </td>
            <td>
                <p class="p" >
                    <strong>
                        
                        3060                                 

                       
                    </strong>
                </p>
            </td>
        </tr>
    </table>
    <table class="table">
        <tr>
            <td>
                <strong>
                     Растение 2 
                </strong>
              
            </td>
            <td>

            </td>
            <td>

            </td>
            
            <td>

</td>
            <td>
                
                    Цена  
             
              
            </td>
            <td>
                Стоимость
            </td>
        </tr>
        <tr>
            <td>
                <input type="checkbox">
            </td>
            <td>
                <img class="imgtd" src="./ikons/ucastok.png">
            </td>
            <td>
               <p>
                    <strong>
                        Вариант 1 (название)
                    </strong>
              </p>
                <label>
                    Краткое описание растения и кнопка
                </label>
            </td>
            
            <td>
                <div class='count_box'>
                    <div class="minus">-</div>
                <input class='inp_price' type="text" value="1"/>
                <div class="plus">+</div>
                </div> 
            </td>
            <td>
                
                        
                        3060                                 

                       
                  
            </td>
            <td>
                <p class="p" >
                    <strong>
                        
                        3060                                 

                       
                    </strong>
                </p>
            </td>
        </tr>
    </table><br>
    <table class="table">
        <tr>
            <td>
                <h1>
                    <strong>

                        Итого
                    </strong>
                </h1>
                
              
            </td>
            <td>

            </td>
            <td>

            </td>
            
            <td>

</td>
            <td>
                
                    
             
              
            </td>
            <td>
                3060р
            </td>
        </tr>
        
    </table>
    <div class="footer">
        <p>
            {{qwerty}}
        </p>
    </div>
    
</div>

</div>

<!--  -->

<div class="rightblock">
 
    <div class="img-box">
        
    </div>
    <div class="crt">
        <div class="box">
                
         
                
        <button @click="calculator" class="btn redorder">
            Редактировать или удалить
        </button>
        <div class="ques">?</div>
         </div>
        <div class="box">
               
         
                
        <button @click="calculator" class="btn crtorder">
                    Создать заказ
        </button>
        <div class="ques">?</div>
         </div>
    </div>
            </div>
           
       
    </div>
    </div>
</template>



<script>
import heder from "./components/hederTree.vue";
import axios from "axios";
// import filter1 from "./components/filtrModal.vue";
//import hederd from "./components/hederDuo.vue";

export default{
    components:{
     // hederd,
     
        heder,

       
        
    },

    methods :{

        openmodal1(){
            // Открыть модальное окно
                document.getElementById("open-modal-btn").addEventListener("click", function() {
                    document.getElementById("my-modal1").classList.add("open")
                })

                // Закрыть модальное окно
                document.getElementById("close-my-modal-btn").addEventListener("click", function() {
                    document.getElementById("my-modal1").classList.remove("open")
                })

                // Закрыть модальное окно при нажатии на Esc
                window.addEventListener('keydown', (e) => {
                    if (e.key === "Escape") {
                        document.getElementById("my-modal1").classList.remove("open")
                    }
                });

                // Закрыть модальное окно при клике вне его
                document.querySelector("#my-modal1 .modal__box").addEventListener('click', event => {
                    event._isClickWithInModal = true;
                });
                document.getElementById("my-modal1").addEventListener('click', event => {
                    if (event._isClickWithInModal) return;
                    event.currentTarget.classList.remove('open');
                });
                        },

        search(){
            const options = {
            method: 'GET',
            url: 'http://185.221.153.253:5700/api/search',
            headers: {Authorization: 'Bearer 0ded13aa-624b-40cf-bef7-dc7a2bf97c2c'},
            data: {
                f_compos_type: this.valuesearch,
                f_template: this.valuesearch,
                f_temp_char: this.valuesearch,
                f_place: this.valuesearch,
                f_ground_sost: this.valuesearch,
                f_ground_kisl: this.valuesearch,
                f_ct_size: this.valuesearch,
                f_ct_form: this.valuesearch,
                f_ct_light: this.valuesearch,
                f_ct_color: this.valuesearch,
                f_ct_maf: this.valuesearch,
                f_ct_vl: this.valuesearch,
                f_ct_res: this.valuesearch,
                f_ct_aroma: this.valuesearch,
                f_ct_plants: this.valuesearch
            }
            };

            axios.request(options).then(function (response) {
            console.log(response.data);
            }).catch(function (error) {
            console.error(error);
            });
        }

        }
}
</script>


<style scoped>

.close{
     width: 40%; 
    text-align: right;
    margin-top: -2px;
}

.versions{
    display: flex;
    text-align: center;
    margin-top: 0.2%;
    margin-left: 37%;
}
.versions p{
    margin-left: 3%;
    width: 85px;
}
.bottom{
    text-align: left;
    font-size: 24px;
}

.box{
    margin-top: 2%;
    display: flex;
    width: 100%;
}
.ques{
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background-color: #D9D9D9;
    text-align: center;
    font-size: 24px;
    margin-left: 60px;
    margin-top: 1%;
}

.minus {
        cursor: pointer;
        width:25px;
        float:left;
        text-align:center;
        border-radius: 3px;
        border:1px solid gray;
    }
    .plus {
        cursor: pointer;
        width:25px;
        float:right;
        text-align:center;
        border:1px solid gray;
        /* margin-top: -25px; */
    }
    
    .inp_price {
        width:40px;
        text-align:center;
        border:1px solid gray;
        /* border-left:1px solid gray;
        border-right:1px solid gray; */
    }
    
    .count_box {
        width:90px;
        /* border:1px solid gray;   */
    }
    /* .body{
    display: flex;
    width: 100%;
    
} */
.header{
    width: 100%;
    }
/* left ========== */

.blocks{
    width: 58%;
    margin-top: 1%;
    margin-left: 2%;
}
.imgtd{
    width: 100;
height: 100px;
left: 62px;
top: 333px;


border-radius: 10px;
}

.table td{
    margin-left: 0.5%;
    border: 1px solid #0000;
    text-align: left;
}

/* right =========== */

.rightblock{
    width: 40%;
    margin-top: 2%;
}

.dwnlt{
    display: flex;
flex-direction: row;
    justify-content: center;
align-items: center;
padding: 16px 20px;
gap: 10px;
/* position: absolute; */
width: 355px;
height: 56px;
left: 1145px;
/* top: 270px; */
float: left;
background: #949494;
border-radius: 10px;
}

.rpst{
    display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 16px 20px;
gap: 10px;

/* position: absolute; */
width: 355px;
height: 56px;
left: 927px;
/* top: 270px; */
margin-left: 2%;
background: #949494;
float: left;
border-radius: 10px

}

.img-box{
    box-sizing: border-box;

background-color: #949494;
width: 98%;
height: 400.97px;
left: 927px;
top: 344px;


border: 1px solid #D9D9D9;
border-radius: 10px;
}
.crt{
    /* display: flex; */
    justify-content: center;
align-items: center;
}
.crtorder{

    display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 16px 20px;
gap: 10px;
width: 70%;
height: 54px;
left: 944px;
top: 796px;

background: #CD5E5E;
border-radius: 10px;
}
.redorder{
    display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 16px 20px;
gap: 10px;
width: 70%;
height: 54px;
left: 944px;
top: 796px;

background: #D9D9D9;
border-radius: 10px;
}
.content{
    display: flex;
    margin-top: 2%;
}


.up input{
    float: left;
   
}
.up{
    display: flex;
    margin-top: -0.2%;
}

.heder{
    width: 98%;
    margin-top: 0%;
    text-align: center;
}
.heder a{
    text-align: center;
    justify-content: center;
    align-content: center;
}
.body{
    height: 120%;
    margin-top: 0px;
    font-family: "Montserrat-Regular";
}
/* Heder */

.heder{
    margin-left: 2%;
    margin-top: -2px;
    font-family: "Montserrat-Regular";
}
.heder2{
    margin-top: -40px;
    /* align-content: center; */
    margin-left: 18%;
}
.heder2 a{
    text-decoration: none;
    color: black;
}
.p2{
    float: left;
    margin-left: 1%;
    font-size: 16px;
    margin-top: 0.5%;
    text-align: center;
    width: 240px;
    height: 19px;
}
.up a{
    text-decoration: none;
    color: black;
}





</style>
