<template>
    <div class="body">
        <HederUno/>
        <!--  -->
        <cont1 class="cont1">
            <div class="topcont">
                <img src="./assets/tild3064.png">
            </div> 
            <!--  --><br>
            <div class="subcont">
                <h2>
                    <p class="hed">
                        A I . G A R D E N
                    </p>
                </h2>
                <p class="bod">
                       Воплощай мечты легко
                </p>
            </div>
        </cont1>
<!--  -->
        <div class="cont2">
            <a>
            <button class="btn contacts">
                Написать нам в WhatsApp
            </button><br>
            </a>
            <!--  -->
            <a>
            <button class="btn contacts">
                Подписаться на Boosty
            </button><br>
            </a>
            <!--  -->
            <a>
            <button class="btn contacts">
                Отправить предложение на e-mail
            </button>
            </a>
        </div>
<!--  -->
        <cont3>

        </cont3>
<!--  -->
        <cont4>

        </cont4>
<!--  -->
        <Footer/>
    </div>
</template>

<script>

import HederUno from './hederUno.vue'
import Footer from './footerUno.vue'

export default{
    components:{
        HederUno,
        Footer,
        
    },


}
</script>

<style scoped>
.body{
    font-family: "Montserrat-Regular";
}
.cont1{
    /* position: absolute;
    top: 5%;     */
    margin-top: 10%;
}
.topcont{
    margin-left:42%;
    margin-top: 10%;
    
}
.subcont{
    margin-top: 5%;
    text-align: center;
}
.hed{
    font-size: 55px;
}
.bod{
    font-size: 30px;
}
.cont2{
  margin-top: 5%;
  padding: 5px;
  
}
.contacts{
    background-color: black;
    color: white;
    width: 30%;
    height: 55px;
    border-radius: 30px;
    padding: 5px;
    font-size: 25px;
    margin-top: 0.5%;
    text-align: center;
    margin-left: 35%;
}

</style>
